import { useContext, useEffect } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../containers/Layout';
import Button from '../components/Button';
import { default as Text } from '../components/AppText';
import CostList from '../features/costs/CostList';
import AddCostForm from '../features/costs/AddCostForm';
import { padding } from '../constants/theme';
import { clearSelectedCost, getSelectedCost, selectCosts, selectIsNew } from '../features/costs/costsSlice';
import OptionsContext from '../features/costs/OptionsContext';

function CostsScreen() {
  const dispatch = useDispatch();
  const costs = useSelector(selectCosts);
  const selectedCost = useSelector(getSelectedCost);
  const isNew = useSelector(selectIsNew);
  let selectedOptions = null;

  if (selectedCost) {
    selectedOptions = selectedCost.options.filter((option) => option.deleted === 0).length;
  }

  const { setShowAddCostsPanel, showAddCostsPanel, setSelectedCostItem } =
    useContext(OptionsContext);

  useEffect(() => {
    if (isNew) {
      setShowAddCostsPanel(true);
    }
  }, []);

  const handleAddCost = () => {
    dispatch(clearSelectedCost());
    setSelectedCostItem(null);
    setShowAddCostsPanel((prevState) => !prevState);
  };

  return (
    <Layout title='Fire Stopping Costs'>
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        <View style={styles.header}>
          <Text variant='heading' size='xl'>PLEASE SPECIFY FIRE STOPPING COSTS</Text>
          <Button title='Add Costs' variant='secondary' onPress={handleAddCost} />
        </View>
        {(showAddCostsPanel ? 
          <AddCostForm selectedCost={selectedCost} selectedOptions={selectedOptions} index={costs.length} />
        : null)}
        <CostList costs={costs} />
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default CostsScreen;
