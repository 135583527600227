import { useEffect, useState } from 'react';
import { View, TextInput } from 'react-native';
import { useDispatch } from 'react-redux';

import { default as Text } from '../../components/AppText';
import Button from '../../components/Button';
import { globalStyles } from '../../styles/GlobalStyles';
import { addAddress } from './organisationsSlice';

const AddressForm = ({
  selectedAddress,
  setShowEditAddress,
}) => {
  const dispatch = useDispatch();

  const [address, setAddress] = useState('');

  console.log(selectedAddress);

  const handleSaveAddress = () => {
    dispatch(addAddress({ id: selectedAddress?.id, label: address, }));
    if (typeof setShowEditAddress === 'function') {
      setShowEditAddress(null);
    }
    setAddress('');
  };

  useEffect(() => {
    if (selectedAddress) {
      setAddress(selectedAddress.label);
    }
  }, [selectedAddress]);

  return (
    <View style={[globalStyles.fieldContainer, { marginTop: 16 }]}>
      <Text style={globalStyles.label}>Details: </Text>
      <View style={{ flexDirection: 'row ', alignItems: 'center', flex: 1 }}>
        <TextInput
          style={[globalStyles.field, { marginRight: 10, minWidth: 120 }]}
          value={address}
          onChangeText={(value) => setAddress(value)}
        />
        <Button
          style={{ marginRight: 10 }}
          title={selectedAddress ? 'Save' : 'Add'}
          variant='secondary'
          onPress={handleSaveAddress}
        />
      </View>
    </View>
  );
};

export default AddressForm;
