import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FireStopAPI, setHeaderToken } from '../../services/api';

export const signIn = createAsyncThunk(
  '/auth/signin',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const { data } = await FireStopAPI.post('/v1/login-dev2.php', {
        action: 'login',
        data: { email, password },
      });

      if (data?.data && data.data?.user) {
        localStorage.setItem('user', JSON.stringify(data.data.user));
      }

      if (data?.data && data.data?.token) {
        localStorage.setItem('token', data.data.token);
      }

      return data.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue({ message: 'Your login details do not match our records. If you have an account with us and cannot remember your password, you may use the password reset routine which will email you a new password.' });
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  '/auth/resetPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const { data } = await FireStopAPI.post('/v1/login-dev2.php', {
        action: 'reset_password',
        data: { email },
      });

      return data.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue({ message: 'Something has gone wrong!' });
      }
    }
  }
);

const token = localStorage.getItem('token')
  ? localStorage.getItem('token')
  : null;

const user = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null;

const initialState = {
  user,
  token,
  status: 'idle',
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut(state) {
      state.user = null;
      state.token = null;

      setHeaderToken('');

      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
    reset(state) {
      state.status = 'idle';
      state.error = null;
      state.success = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(signIn.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(signIn.fulfilled, (state, action) => {
        setHeaderToken(action.payload.token);

        state.user = action.payload.user;
        state.token = action.payload.token;

        state.status = 'idle';
      })
      .addCase(signIn.rejected, (state, action) => {
        console.log(action.payload);
        state.error = action.payload.message;
        state.status = 'idle';
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.success = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = 'idle';
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.error = action.payload.message;
        state.status = 'idle';
      });
  },
});

export const selectToken = (state) => state.auth.token;
export const selectUser = (state) => state.auth.user;

export const { signOut, reset } = authSlice.actions;

export default authSlice.reducer;
