import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { default as Text } from '../../components/AppText';
import Button from '../../components/Button';
import { colors, padding } from '../../constants/theme';
import { deferRepair, selectEntryById } from './firestopSlice';
import RepairForm from './RepairForm';

const OutstandingRepairs = ({ repair, setRepairToCopy }) => {
  const dispatch = useDispatch();
  const entry = useSelector((state) => selectEntryById(state, repair.entry));
  
  let details;
  if (entry) {
    details = entry.elements.find((element) => element.id === repair.element);
  }

  const handleCopy = () => {
    setRepairToCopy(details);
    dispatch(deferRepair(repair));
  }

  let renderedList;
  if (details?.fail_type === 'hole') {
    if (details?.hole_size === 'other') {
      renderedList = (
        <>
          <View style={styles.detail}>
            <Text style={styles.label}>Hole Length (mm): </Text><Text>{details?.hole_size_other_length}</Text>
          </View>
          <View style={styles.detail}>
            <Text style={styles.label}>Hole Width (mm): </Text><Text>{details?.hole_size_other_width}</Text>
          </View>
        </>
      )
    } else {
      renderedList = (
        <View style={styles.detail}>
          <Text style={styles.label}>Hole Size (mm): </Text><Text>{details?.hole_size}</Text>
        </View>
      )
    }
  } else {
    renderedList = (
      <>
        <View style={styles.detail}>
          <Text style={styles.label}>Crack Depth (mm): </Text><Text>{details?.crack_depth}</Text>
        </View>
        <View style={styles.detail}>
          <Text style={styles.label}>Crack Length (mm): </Text><Text>{details?.crack_length}</Text>
        </View>
        <View style={styles.detail}>
          <Text style={styles.label}>Crack Width (mm): </Text><Text>{details?.crack_width}</Text>
        </View>
      </>
    )
  }

  return (
    <View>
      <Text variant="heading" size="xl">Outstanding Repairs</Text>
      <View>
        <View style={styles.detailsContainer}>
          <View style={styles.leftContainer}>
            <Text variant="heading">Details:</Text>
            <View style={styles.buttonContainer}>
              <Button title="Copy" variant="secondary" size="sm" onPress={handleCopy} />
            </View>
          </View>
          <View>
            <View style={[styles.detail, { marginTop: 0 }]}>
              <Text style={styles.label}>Fail Type: </Text><Text style={styles.value}>{details?.fail_type}</Text>
            </View>
            {renderedList}
            {details?.fail_type === 'hole' ? (
              <View style={styles.detail}>
                <Text style={styles.label}>Services Via Hole: </Text><Text style={styles.value}>{details?.services_via_hole}</Text>
              </View>
            ) : null}
          </View>
        </View>
      </View>
      <View style={styles.formContainer}>
        <RepairForm repair={repair} showBackButton={true} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  detailsContainer: {
    flexDirection: 'row',
    backgroundColor: colors.darkGrey,
    paddingVertical: padding.md,
    paddingHorizontal: padding.lg,
    marginTop: 16
  },
  leftContainer: {
    marginRight: 48,
  },
  formContainer: {
    marginTop: 16
  },
  buttonContainer: {
    marginTop: 8
  },
  detail: {
    flexDirection: 'row',
    marginTop: 8
  },
  label: {
    fontWeight: 600
  },
  value: {
    textTransform: 'capitalize'
  }
});

export default OutstandingRepairs;