import { StyleSheet, Text, View, ScrollView, TouchableOpacity } from 'react-native';
import { Component, cloneElement } from 'react';

export default class SortList extends Component
{
	state = { from: null };

	constructor(props) {
		super(props);

		this.completeSort = (to) =>
		{
			this.props.onSort(this.state.from, to);

			this.setState((state) => {
				return { from: null }
			});
		}
	}

	render()
	{
		const { from } = this.state;
		const { style, children } = this.props;
		let { highlight, selectedHighlight } = this.props;
		const defaultText = this.props.defaultText || "Please select an item to sort";
		
		if(!highlight) { highlight = '#DDDDDD'; }

		if(!selectedHighlight) { selectedHighlight = '#62EA75'; }

		const styles = StyleSheet.create({
			selected: {
				width: '100%',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				paddingVertical: 20
			},
			selectedText: {
				width: '15%', color: '#2196F3', paddingHorizontal: 15
			},
			selectedItem: {
				width: '70%',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: '#fff'
			},
			button: {
				marginVertical: 5,
				paddingHorizontal: 10,
				paddingVertical: 5,
				borderRadius: 10,
				backgroundColor: '#2196F3',
				color: '#fff',
				alignItems: 'center'
			},
			optionContainer: {
				backgroundColor: highlight,
				paddingHorizontal: 20,
				paddingVertical: 10,
				marginVertical: 0
			}
		});
		
		const Button = ({ style, children, ...props }) =>
		{
			return <TouchableOpacity style={{...styles.button, ...style}} {...props}>
				<Text style={{ fontFamily: 'Lato', color: '#fff', alignSelf: 'center', textTransform: 'uppercase' }}>{children}</Text>
			</TouchableOpacity>
		}

		const SlotAvailable = (props) =>
		{
			let { index } = props;

			return (from !== index) && <View style={{flexDirection: 'row'}}><Button onPress={() => { this.completeSort(index) }} >Move Here &gt;</Button></View>
		}

		const ListItem = (props) =>
		{
			const { index } = props;
			const child = props.children

			if(from === null)
			{
				return <TouchableOpacity onPress={() => {this.setState((state) => { return {from: parseInt(index)}})}}>{child}</TouchableOpacity>
			}

			let updateStyles = { marginVertical: 0 };

			if(index === from)
			{
				updateStyles = { marginVertical: 16, backgroundColor: selectedHighlight }
			}

			let position = index - (from < index ? 1 : 0);
			let object = cloneElement(child, { style: {...child.props.style, ...updateStyles }});

			console.log(object);

			return <>
				<SlotAvailable index={position} />
				{object}
				{children.length === index+1 && <SlotAvailable index={position+(from < index ? 1 : 0)}/>}
			</>
		}

		return(
			<View style={{width: '100%' }}>
				<View style={styles.selected}>{
					(from !== null ? 
						<>
							<View style={styles.selectedItem}>
								<Text style={styles.selectedText}>Selected</Text>
								<View style={{width: '85%'}}>{ cloneElement(children[from], { style: { ...styles.optionContainer }}) }</View>
							</View>
							<Button style={{paddingVertical:10}} onPress={() => this.setState((state) => { return {from: null}})}>Clear</Button>
						</>
					:
						<Text style={{ fontSize: 20 }}>{defaultText}</Text>
				)}</View>
				<ScrollView style={{ width: '100%', height: 500 }} showsVerticalScrollIndicator={false}>
					<View style={style}>
						{ children.map((item, index) => <ListItem key={index} index={index}>{item}</ListItem>)}
					</View>
				</ScrollView>
			</View>
		)
	}
}