import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { View, Text, Image, Dimensions, TouchableOpacity, StyleSheet } from 'react-native';
import ImageZoom from 'react-native-image-pan-zoom';
import { ScrollView } from 'react-native-web';
import Svg, { Path } from 'react-native-svg';

const Minus = ({width, height, colour, ...props}) => (
	<Svg width={width || 50} height={height || 50} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill={(colour || '#ffffff')} {...props}>
		<Path d="M8,1C4.134,1,1,4.134,1,8c0,3.866,3.134,7,7,7s7-3.134,7-7C15,4.134,11.866,1,8,1z M12,9H4V7h8V9z" />
	</Svg>
);

const Plus = ({width, height, colour, ...props}) => (
	<Svg width={width || 50} height={height || 50} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill={(colour || '#ffffff')} {...props}>
		<Path d="M8,1C4.134,1,1,4.134,1,8c0,3.866,3.134,7,7,7s7-3.134,7-7C15,4.134,11.866,1,8,1z M12,9H9v3H7V9H4V7h3V4h2v3h3V9z" />
	</Svg>
);

const PinMe = ({ image, imageDimensions, pin, size, updatePin, scaleButtons, ...props }) => 
{
	try
	{
		const containerReference = useRef();
		const imageZoomReference = useRef();

		if(image == null)
		{
			throw new Error("Please provide an image to pin a location");
		}

		if(scaleButtons == undefined)
		{
			scaleButtons = true;
		}

		const pinDetails = { size: { width: 50, height: 86 }, anchor: { x: 25, y: 86 }, image: require('../assets/icons/pin.png') };

		// cropDimensions can change at some point.  This will be needed when image changes
		const defaultCrop = size || { width: '100%', height: Dimensions.get('window').height * 0.6 };
		const [cropDimensions, setCropDimensions] = useState(defaultCrop);

		// Fixes issue where centering keeps happening after element refresh
		const [initialCenter, setInitialCenter] = useState(false);

		// Need to update this if the image is smaller to fix an issue with pin
		const [currentPosition, setPosition] = useState({ x: 0, y: 0, scale: 1 });

		const onReset = () =>
		{
			imageZoomReference.current.reset();
			setPosition({ x: 0, y: 0, scale: 1 });
		};

		const onMove = (event) =>
		{
			let origin = getOrigin(event.scale);
			let newPosition = { x: -(event.positionX+origin.x), y: -(event.positionY+origin.y), scale: event.scale };
			setPosition(newPosition);
		};

		const onLongPress = (event) =>
		{
			setInitialCenter(true);

			if(!!updatePin)
			{
				updatePin([Math.round(currentPosition.x + event.locationX/currentPosition.scale), Math.round(currentPosition.y + event.locationY/currentPosition.scale), currentPosition.scale]);
			}
		};

		const scale = (adjustment) =>
		{
			let newScale = Math.max(Math.min(currentPosition.scale + adjustment, imageZoomReference.current.props.maxScale), imageZoomReference.current.props.minScale);

			if(currentPosition.scale != newScale)
			{
				let {x: originX, y: originY} = getOrigin(currentPosition.scale);
				let {x: newOriginX, y: newOriginY} = getOrigin(newScale);

				let newX = Math.max(Math.min(-currentPosition.x - originX, Math.abs(newOriginX)), newOriginX);
				let newY = Math.max(Math.min(-currentPosition.y - originY, Math.abs(newOriginY)), newOriginY);
	
				imageZoomReference.current.centerOn({x: newX, y: newY, scale: newScale});
				setPosition({...currentPosition, scale: newScale});
			}
		}

		const findPin = () =>
		{
			// Gets the position of the pin relative to ImageZoom position.
			let [positionX, positionY] = pin;
			const {x: originX, y: originY} = getOrigin();
			const {width: imageW, height: imageH} = imageDimensions;

			if(!isNaN(positionX) && !isNaN(positionY) && !isNaN(originX) && !isNaN(originY) && !isNaN(imageW) && !isNaN(imageH))
			{
				// Convert pin position based on center point
				// Correction to boundaries.  Origin is the same for min and max sizes as 0,0 of imageZoom is dead centre
				positionX = Math.max(Math.min((imageW/2) - positionX, Math.abs(originX)), originX);
				positionY = Math.max(Math.min((imageH/2) - positionY, Math.abs(originY)), originY);

				return {x: positionX, y: positionY};
			}

			return false;
		}

		const goToPin = () =>
		{
			// Function to move the imageZoom to the pin.
			// No need to run this if the element can't be found
			if(!!imageZoomReference.current)
			{
				let pinCoordinate = findPin();
	
				if(!!pinCoordinate)
				{
					imageZoomReference.current.centerOn({...pinCoordinate, scale: 1, duration: 1});
					setInitialCenter(true);
				}
			}
		};

		useLayoutEffect(() => {
			// Do not use the current Crop dimensions this shrinks to the image.  This will give the biggest area possible
			onReset();

			let { width, height } = defaultCrop;
			const container = containerReference.current.getBoundingClientRect();

			if(typeof width === "string" && width.includes('%'))
			{
				width = container.width * (parseFloat(width.replace('%', '')) / 100);
			}

			if(typeof height === "string" && height.includes('%'))
			{
				height = container.height * (parseFloat(height.replace('%', '')) / 100);
			}

			width = Math.min(width, imageDimensions.width);
			height = Math.min(height, imageDimensions.height);

			setCropDimensions({
				width: width,
				height: height
			});
		}, [image]);

		// Correct the origin to the top left of the image.  This will allow us the get the proper location of the pin
		function getOrigin(scale = 1)
		{
			let originPoint = {
				x: Math.min(-((scale * imageDimensions.width) - cropDimensions.width) / (2 * scale), 0),
				y: Math.min(-((scale * imageDimensions.height) - cropDimensions.height) / (2 * scale), 0)
			};

			return originPoint;
		}

		useEffect(() => {
			let newOrigin = getOrigin();

			setPosition({ ...currentPosition, x: -newOrigin.x, y: -newOrigin.y});
		}, [cropDimensions]);

		if(!!pin && !!props.centerOnPin && !initialCenter)
		{
			goToPin();
		}

		return (
			<View style={{ paddingHorizontal: 16 }}>
				<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
					{!!updatePin && <Text>Please Tap and hold to set a marker</Text>}
					{scaleButtons && <View style={{ flexDirection: 'row', alignItems: 'center' }}>
						<TouchableOpacity style={styles.button} onPress={() => scale(-1)}><Minus colour='#333333' /></TouchableOpacity>
						<TouchableOpacity style={styles.button} onPress={() => scale(1)}><Plus colour='#333333' /></TouchableOpacity>
					</View>}
				</View>
				<View style={{ alignItems: 'center', marginTop: 8 }} ref={containerReference}>
					<Text style={cropDimensions} selectable={false}>
						<ScrollView scrollEnabled={false}>
							<ImageZoom ref={imageZoomReference} cropWidth={cropDimensions.width} cropHeight={cropDimensions.height} imageWidth={imageDimensions.width} imageHeight={imageDimensions.height} minScale={1} onMove={onMove} onLongPress={onLongPress} longPressTime={300} enableCenterFocus={false}>
								<Image style={imageDimensions} source={image} pointerEvents="none"/>
								{
									(!!pin ? <Image style={{ marginLeft: pin[0] - pinDetails.anchor.x, marginTop: pin[1] - pinDetails.anchor.y, width: pinDetails.size.width, height: pinDetails.size.height, position:'absolute' }} source={pinDetails.image} pointerEvents="none" /> : null)
								}
							</ImageZoom>
						</ScrollView>
					</Text>
				</View>
			</View>
		);
	}
	catch(e)
	{
		return <Text>Error{ (e !== undefined ? ": " + e.message : null) }</Text>
	}
}

const styles = StyleSheet.create({
	button: {
		marginHorizontal: 10,
		alignItems: 'center',
		justifyContent:'center'
	},
});

export default PinMe;