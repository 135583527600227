import 'react-native-gesture-handler';
import { useEffect, useCallback } from 'react';
import ErrorBoundary from 'react-native-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';

import RootNavigator from './navigation/RootNavigator';
import { persistor, store } from './store';
import { FireStopAPI, setHeaderToken } from './services/api';
import ErrorFallback from './components/ErrorFallback';

function App() {
    const [fontsLoaded] = useFonts({
        Lato: require('./assets/fonts/Lato-Regular.ttf'),
        'Lato-Bold': require('./assets/fonts/Lato-Bold.ttf'),
        'Lato-Black': require('./assets/fonts/Lato-Black.ttf'),
    });

    const token = localStorage.getItem('token')
        ? localStorage.getItem('token')
        : null;

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    const handleError = async (error, stackTrace) => {
        try {
            const { data } = await FireStopAPI.post('/v1/index.php', {
                data: { error: JSON.stringify(error), stackTrace },
                action: 'errors',
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        async function prepare() {
            await SplashScreen.preventAutoHideAsync();

            if (token) {
                setHeaderToken(token);
            }
        }
        prepare();
    }, []);

    if (!fontsLoaded) {
        return null;
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <GestureHandlerRootView style={{ flex: 1 }}>
                    <ErrorBoundary
                        onError={handleError}
                        FallbackComponent={ErrorFallback}
                    >
                        <RootNavigator onLayout={onLayoutRootView} />
                    </ErrorBoundary>
                </GestureHandlerRootView>
            </PersistGate>
        </Provider>
    );
}

export default App;
