import { useEffect, useState, useCallback, useContext } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { View, ScrollView, TextInput, StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesome, FontAwesome5, AntDesign } from '@expo/vector-icons';
import DropDownPicker from 'react-native-dropdown-picker';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import Layout from '../containers/Layout';
import FloorPlan from '../containers/floorplan';
import { colors, padding, radius } from '../constants/theme';
import { globalStyles } from '../styles/GlobalStyles';
import { createElement, updateQuantity, getCurrentSurvey, getSelectedElement, organisationSurveyHeading, saveElement, setElement, selectRepairs, selectSurveysElements, getSelectedSurvey } from '../features/firestop/firestopSlice';
import ElementPhotograph from '../features/firestop/ElementPhotograph';
import ElementPhotographThumbnail from '../features/firestop/ElementPhotographThumbnail';
import OutstandingRepairs from '../features/firestop/OutstandingRepairs';
import Preview from '../components/Preview';
import Modal from '../components/Modal';
import { calculateCost, calculateCrack, calculateQuantity } from '../functions/calculations';
import Camera from '../components/Camera.js';
import AddRemedialWorkItemForm from '../features/firestop/AddRemedialWorkItemForm.js';
import { selectCosts } from '../features/costs/costsSlice.js';
import FireStopContext from '../features/firestop/FireStopContext.js';
import RemedialWorkItem from '../features/firestop/RemedialWorkItem.js';
import { findValueInColumn } from '../functions/array.js';
import { isNumeric, moneyFormatter } from '../functions/utils';

const FireStopElementsFormScreen = ({ navigation }) => {
	const dispatch = useDispatch();

	const survey = useSelector(getSelectedSurvey);
	const element = useSelector(getSelectedElement);
	const element_survey = useSelector(getCurrentSurvey);
	const pageTitle = useSelector(organisationSurveyHeading);
	const repairs = useSelector(selectRepairs);
	const costs = useSelector(selectCosts);
	const { setShowAddCostForm, showAddCostForm } = useContext(FireStopContext);

	const [repairToCopy, setRepairToCopy] = useState(null);

	const elements = useSelector(selectSurveysElements);
	const filteredElements = elements.filter((el) => el?.deleted === 0).map((el) => el?.id);
	const newElement = typeof element?.id === "string";
	const newSurvey = typeof survey === "string";

	const currentIndex = filteredElements.indexOf(element?.id);

	let nextElement = null;

	if (currentIndex !== -1) {
		nextElement = filteredElements[currentIndex + 1];
	}

	const outstandingRepair = Object.values(repairs).find((repair) => repair.survey === survey && repair.element === element?.id && repair.status === 'outstanding');

	const [elementID, setElementID] = useState(element?.id || '');

	const [elementName, setElementName] = useState(element?.name || '');

	const [elementLocation, setElementLocation] = useState(element?.location || '');
	const [elementLocationIsOpen, setElementLocationIsOpen] = useState(false);
	const [elementLocationOptions, setElementLocationOptions] = useState([
		{ label: 'Wall', value: 'wall' },
		{ label: 'Floor', value: 'floor' },
		{ label: 'Ceiling', value: 'ceiling' },
		{ label: 'Other', value: 'other' },
	]);
	const [elementLocationOther, setElementLocationOther] = useState(element?.location_other || '');
	const [elementMaterial, setElementMaterial] = useState(element?.material || '');
	const [elementMaterialIsOpen, setElementMaterialIsOpen] = useState(false);
	const [elementMaterialOptions, setElementMaterialOptions] = useState([
		{ label: 'Brick', value: 'brick' },
		{ label: 'Block', value: 'block' },
		{ label: 'Stud', value: 'stud' },
		{ label: 'Plasterboard', value: 'plasterboard' },
		{ label: 'Concrete', value: 'concrete' },
		{ label: 'Timber', value: 'timber' },
		{ label: 'Other', value: 'other' },
	]);
	const [elementMaterialOther, setElementMaterialOther] = useState(element?.material_other || '');
	const [status, setStatus] = useState(element_survey?.surveyPassFailType === 1 ? element?.status : 'failed');

	const [showfloorplan, toggleFloorplan] = useState(false);
	const [floorPin, updatePin] = useState(element?.coordinates || null);

	const [failType, setFailType] = useState(element?.fail_type || '');
	const [photograph1, setPhotograph1] = useState(element?.photograph_1 || null);
	const [photograph2, setPhotograph2] = useState(element?.photograph_2 || null);
	const [showPhotograph1Preview, setShowPhotograph1Preview] = useState(false);
	const [showPhotograph2Preview, setShowPhotograph2Preview] = useState(false);
	const [showRequiredPhoto, setShowRequiredPhoto] = useState(element?.photograph_1 ? false : true);
	const [failTypeIsOpen, setFailTypeIsOpen] = useState(false);
	const [failTypeOptions, setFailTypeOptions] = useState([
		{ label: 'Hole', value: 'hole' },
		{ label: 'Crack', value: 'crack' },
		{ label: 'Other', value: 'other' },
	]);
	const [holeSize, setHoleSize] = useState(element?.hole_size || '');
	const [holeSizeIsOpen, setHoleSizeIsOpen] = useState(false);
	const [holeSizeOptions, setHoleSizeOptions] = useState([
		{ label: '50mm x 50mm', value: '50mm x 50mm' },
		{ label: '100mm x 100mm', value: '100mm x 100mm' },
		{ label: '200mm x 200mm', value: '200mm x 200mm' },
		{ label: '300mm x 300mm', value: '300mm x 300mm' },
		{ label: '1m x 1m', value: '1000mm x 1000mm' },
		{ label: 'Other', value: 'other' },
	]);
	const [holeSizeOtherWidth, setHoleSizeOtherWidth] = useState(element?.hole_size_other_width || '');
	const [holeSizeOtherLength, setHoleSizeOtherLength] = useState(element?.hole_size_other_length || '');
	const [servicesViaHole, setServicesViaHole] = useState(element?.services_via_hole || '');
	const [servicesViaHoleIsOpen, setServicesViaHoleIsOpen] = useState(false);
	const [servicesViaHoleOptions, setServicesViaHoleOptions] = useState([
		{ label: 'No Services', value: 'no services' },
		{ label: 'Cables', value: 'cables' },
		{ label: 'Gas Pipe', value: 'gas pipe' },
		{ label: 'Water Pipe', value: 'water pipe' },
	]);

	const [crackWidth, setCrackWidth] = useState(element?.crack_width || '');
	const [crackLength, setCrackLength] = useState(element?.crack_length || '');
	const [crackDepth, setCrackDepth] = useState(element?.crack_depth || '');

	const [workCosts, setWorkCosts] = useState(element?.remedial_work_costs || []);
	const [total, setTotal] = useState(0.00);

	useEffect(() => {
		setWorkCosts(element?.remedial_work_costs || []);
	}, [element]);

	useEffect(() => {
		let tmpTotal = 0.00;
		workCosts
			.filter((cost) => cost.deleted === 0)
			.forEach((workCost) => {
				tmpTotal += calculateCost(workCost.quantity, workCost.cost_price, workCost.option_price, false)
			});
		setTotal(Number(tmpTotal).toFixed(2));
	}, [workCosts]);

	useEffect(() => {
		let dimensions = [];

		if(failType == 'crack')
		{
			dimensions.push(crackWidth);
			dimensions.push(crackLength);
			dimensions.push(crackDepth);
		}
		else
		{
			let width, length;

			if (holeSize === 'other')
			{
				width = holeSizeOtherWidth;
				length = holeSizeOtherLength;
			}
			else
			{
				[width, length] = holeSize.replaceAll("m", "").replaceAll(" ", "").split("x");
			}

			dimensions.push(width);
			dimensions.push(length);
		}

		setWorkCosts(calculateQuantity(workCosts, dimensions));
	}, [holeSize, holeSizeOtherWidth, holeSizeOtherLength, crackWidth, crackLength, crackDepth]);

	useEffect(() => {
		if (repairToCopy) {
			setStatus(repairToCopy?.status);
			setPhotograph1(repairToCopy?.photograph_1);
			setPhotograph2(repairToCopy?.photograph_2);
			setShowRequiredPhoto(repairToCopy?.photograph_1 ? false : true);
			setFailType(repairToCopy?.fail_type);
			setHoleSize(repairToCopy?.hole_size);
			setHoleSizeOtherLength(repairToCopy?.hole_size_other_length);
			setHoleSizeOtherWidth(repairToCopy?.hole_size_other_width);
			setCrackDepth(repairToCopy?.crack_depth);
			setCrackLength(repairToCopy?.crack_length);
			setCrackWidth(repairToCopy?.crack_width);
			setServicesViaHole(repairToCopy?.services_via_hole);
			setWorkCosts(repairToCopy?.remedial_work_costs);
			setRepairToCopy(null);
		}
	}, [repairToCopy]);

	const handleFailTypeChange = () => {
		setWorkCosts([]);
	}

	useEffect(() => {
		if (failType) {
			setShowAddCostForm(false);
		}
	}, [failType]);

	// Set errors in a array.  Do not use boolean as the error may be different
	let errors = {};

	// Do not show errors on creation
	if(!!element_survey?.elements.filter((element) => element.id === elementID).length > 0)
	{
		if(!elementName)
		{
			errors['name'] = "Please Provide a name";
		}

		if(!elementLocation || (elementLocation == "other" && !elementLocationOther))
		{
			errors['type'] = "Please select a type";
		}

		// if(!elementMaterial || (elementMaterial == "other" && !elementMaterialOther))
		// {
		// 	errors['material'] = "Please select a material";
		// }

		if(!photograph1)
		{
			errors['photograph'] = "Please select a photograph";
		}

		if(!status)
		{
			errors['status'] = "Please confirm Pass/Fail";
		}
		else if(status == "failed")
		{
			switch (failType)
			{
				default: 

					errors['fail_type'] = "Please provide the fail type";

				break;

				case 'hole':

					if(!holeSize || (holeSize == "other" && !(holeSizeOtherWidth && holeSizeOtherLength)))
					{
						errors['hole_size'] = "Please provide the hole size";
					}
					
					if(!servicesViaHole)
					{
						errors['services'] = "Please provide the services via hole";
					}

				break;

				case 'crack':

					if(!crackWidth || !crackLength || !crackDepth)
					{
						errors['crack_size'] = "Please provide the crack size";
					}

				break;

				case 'other':

					// do nothing

				break;
			}
		}
	}

	const handleSave = () => {
		dispatch(saveElement({
			id: elementID,
			name: elementName,
			location: elementLocation,
			location_other: elementLocationOther,
			material: elementMaterial,
			material_other: elementMaterialOther,
			coordinates: floorPin,
			saveType: 'survey',
		}));
		dispatch(saveElement({
			id: elementID,
			status,
			fail_type: failType,
			photograph_1: photograph1,
			photograph_2: photograph2,
			hole_size: holeSize,
			hole_size_other_width: holeSizeOtherWidth,
			hole_size_other_length: holeSizeOtherLength,
			services_via_hole: servicesViaHole,
			crack_width: crackWidth,
			crack_length: crackLength,
			crack_depth: crackDepth,
			remedial_work_costs: workCosts,
			saveType: 'entry',
		}));
		dispatch(updateQuantity({
			holeSize,
			holeSizeOtherWidth,
			holeSizeOtherLength,
			crackWidth,
			crackLength,
			crackDepth
		}));
		return true;
	};

	const handleAddCosts = () => {
		if (costs.length > 0) {
			setShowAddCostForm(true);
		} else {
			navigation.navigate('CostsStack');
		}
	};

	const onDropdownOpen = useCallback((selected) => {
		if(selected !== "location")
		{
			setElementLocationIsOpen(false);
		}
		if(selected !== "material")
		{
			setElementMaterialIsOpen(false);
		}
		if(selected !== "type")
		{
			setFailTypeIsOpen(false);
		}
		if(selected !== "size")
		{
			setHoleSizeIsOpen(false);
		}
		if(selected !== "services")
		{
			setServicesViaHoleIsOpen(false);
		}
	}, []);

	if (!element) {
		return (
			<Layout title={pageTitle}>
				<View>
					<Text size='xl'>Something has gone wrong!</Text>
					<Button title='Go Back' onPress={() => navigation.navigate('FireStopElements')} />
				</View>
			</Layout>
		);
	}

	if (outstandingRepair) {
		return (
			<Layout title={pageTitle}>
				<ScrollView style={styles.container}>
					<OutstandingRepairs repair={outstandingRepair} setRepairToCopy={setRepairToCopy} />
				</ScrollView>
			</Layout>
		)
	}

	if(showfloorplan)
	{
		let activeFloorplans = {};

		Object.entries(element_survey?.floorplan).map(([id, plan]) => {
			if(plan.deleted === 0)
			{
				activeFloorplans[id] = plan
			}
		});

		return (
			<Layout title={pageTitle}>
				<ScrollView style={styles.container}>
					<FloorPlan styles={styles} floorplan={activeFloorplans} pin={floorPin} updatePin={updatePin} toggleFloorplan={toggleFloorplan} />
				</ScrollView>
			</Layout>
		)
	}

	return (
		<Layout title={pageTitle}>
			<ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
				{/* Element Heading */}
				<View>
					<Text variant='heading' size='xl'>Enter Survey Details</Text>
				</View>
				{element.id != elementID &&
					<View>
						<Text>Breach does not match. Editing ID: {elementID} instead of selected ID: {element.id}</Text>
					</View>
				}
				{!repairToCopy ? (
					<Modal showModal={showRequiredPhoto} setShowModal={() => setShowRequiredPhoto(false)}>
						<View style={styles.requiredPhotoContainer}>
							<FontAwesome name="camera" size={124} color={colors.primary} />
							<Text variant="heading" size="xl" style={{ marginTop: 32 }}>You need to attach a photo</Text>
							<Text variant="heading" style={{ marginTop: 16, textAlign: 'center' }}>To take a new photo or attach an existing one click choose file</Text>
							<View style={{ marginTop: 16 }}>
								<ElementPhotograph name='photograph_1' collection="element-photos" elementId={element.id} setPhotograph={setPhotograph1} onFinish={() => setShowRequiredPhoto(false)} />
							</View>
						</View>
					</Modal>
				) : null}
				{/* Element Form */}
				<View style={styles.formContainer}>
					<View style={[ globalStyles.fieldContainer, { alignItems: 'flex-start', maxWidth: '700px' } ]} >
						{/* <Text style={globalStyles.label}>Photographs <Text style={styles.required}>*</Text></Text> */}
						<View style={[styles.photographsContainer, { flex: 1 }]}>
							<View style={{ width: '50%' }}>
								<View style={[styles.photographContainer, { marginRight: 16 }]}>
									<View style={styles.photographButtonsContainer}>
										{/* <View style={styles.photographIcon}>
											<Text style={{ fontWeight: '600' }}>1</Text>
										</View> */}
										<ElementPhotograph name='photograph_1' buttonLabel="" buttonIcon={<Camera />} buttonVariant='transparent' collection="element-photos" elementId={element.id} setPhotograph={setPhotograph1} />
										{photograph1 ? (
											<Button icon={<Preview />} variant="transparent" style={{ padding: 0, marginRight: 10, marginTop: 24 }} onPress={() => setShowPhotograph1Preview(prev => !prev)} />
										) : null}
									</View>
									<View style={{...styles.uploadContainer, marginLeft: 16 }}>
										<View style={styles.uploadPreviewContainer}>
											{photograph1 ? (
												<ElementPhotographThumbnail photo={photograph1.id} collection="element-photos" resizeMode='cover' size={{ width: '100%', height: 300 }} />
											) : (
												<Text style={styles.uploadPreviewText}>No photo selected</Text>
											)}
										</View>
									</View>
								</View>
								<Modal showModal={showPhotograph1Preview} setShowModal={() => setShowPhotograph1Preview(false)} containerStyle={{ maxWidth: 600 }}>
									<View>
										<Text variant='heading'>Photograph 1</Text>
										<View style={{ marginTop: 20, width: 540 }}>
											<ElementPhotographThumbnail photo={photograph1?.id} collection="element-photos" size={{ width: '100%', height: 425 }} />
										</View>
									</View>
								</Modal>
								{!!errors['photograph'] ? (
									<Text style={{...globalStyles.error, marginLeft: 48 }}>{errors['photograph']}</Text>
								) : null}
							</View>
							<View style={{ width: '50%' }}>
								<View style={[styles.photographContainer, { marginRight: 16 }]}>
									<View style={styles.photographButtonsContainer}>
										{/* <View style={styles.photographIcon}>
											<Text style={{ fontWeight: '600' }}>2</Text>
										</View> */}
										<ElementPhotograph name='photograph_2' buttonLabel="" buttonIcon={<Camera />} buttonVariant='transparent' collection="element-photos" elementId={element.id} setPhotograph={setPhotograph2} />
										{photograph2 ? (
											<Button icon={<Preview />} variant="transparent" style={{ padding: 0 }} onPress={() => setShowPhotograph2Preview(prev => !prev)} />
										) : null}
									</View>
									<View style={{...styles.uploadContainer, marginLeft: 16 }}>
										<View style={styles.uploadPreviewContainer}>
											{photograph2 ? (
												<ElementPhotographThumbnail photo={photograph2.id} collection="element-photos" resizeMode='cover' size={{ width: '100%', height: 300 }} />
											) : (
												<Text style={styles.uploadPreviewText}>No photo selected</Text>
											)}
										</View>
									</View>
								</View>
								<Modal showModal={showPhotograph2Preview} setShowModal={() => setShowPhotograph2Preview(false)} containerStyle={{ maxWidth: 600 }}>
									<View>
										<Text variant='heading'>Photograph 2</Text>
										<View style={{ marginTop: 20, width: 540 }}>
											<ElementPhotographThumbnail photo={photograph2?.id} collection="element-photos" size={{ width: '100%', height: 425 }} />
										</View>
									</View>
								</Modal>
							</View>
						</View>
					</View>
					<View style={[globalStyles.fieldContainer, { marginTop: 24 }]}>
						<Text style={globalStyles.label}>Location / Name <Text style={styles.required}>*</Text></Text>
						<View style={{ flex: 1 }}>
							{(!newElement ? 
								<Text>{elementName}</Text>
							: 
								<>
									<TextInput
										style={globalStyles.field}
										onChangeText={(value) => setElementName(value)}
										value={elementName}
									/>
									{!!errors['name'] ? (
										<Text style={globalStyles.error}>{errors['name']}</Text>
									) : null}
								</>
							)}
						</View>
					</View>
					<View style={[globalStyles.fieldContainer, { zIndex: 1000 }]}>
						<Text style={globalStyles.label}>Breach Structure <Text style={styles.required}>*</Text></Text>
						<View style={{ flex: 1 }}>
							{(!newElement ? 
								<Text>{elementLocationOptions.map((location) => {
									if(location.value == elementLocation)
									{
										return location.label
									}
								})}</Text>
							: 
								<>
									<DropDownPicker
										placeholder='Please select a type'
										dropDownDirection="BOTTOM"
										open={elementLocationIsOpen}
										value={elementLocation}
										items={elementLocationOptions}
										setOpen={setElementLocationIsOpen}
										onOpen={() => onDropdownOpen("location")} 
										setValue={setElementLocation}
										setItems={setElementLocationOptions}
										style={globalStyles.dropdown}
										dropDownContainerStyle={globalStyles.dropdownItem}
									/>
									{!!errors['type'] ? (
										<Text style={globalStyles.error}>{errors['type']}</Text>
									) : null}
								</>
							)}
						</View>
					</View>
					{elementLocation === 'other' &&
						<View style={globalStyles.fieldContainer}>
							<Text style={globalStyles.label}>Breach Type (Other) <Text style={styles.required}>*</Text></Text>
							<View style={{ flex: 1 }}>
								<TextInput
								style={globalStyles.field}
								onChangeText={(value) => setElementLocationOther(value)}
								value={elementLocationOther}
								/>
							</View>
						</View>
					}
					{/* <View style={[globalStyles.fieldContainer, { zIndex: 800 }]}>
						<Text style={globalStyles.label}>Element Material <Text style={styles.required}>*</Text></Text>
						<View style={{ flex: 1 }}>
							{(!newElement ? 
								<Text>{elementMaterialOptions.map((material) => {
									if(material.value == elementMaterial)
									{
										return material.label
									}
								})}</Text>
							: 
								<>
									<DropDownPicker
										placeholder='Please select a material'
										dropDownDirection="BOTTOM"
										open={elementMaterialIsOpen}
										value={elementMaterial}
										items={elementMaterialOptions}
										setOpen={setElementMaterialIsOpen}
										onOpen={() => onDropdownOpen("material")} 
										setValue={setElementMaterial}
										setItems={setElementMaterialOptions}
										style={globalStyles.dropdown}
										dropDownContainerStyle={globalStyles.dropdownItem}
									/>
									{!!errors['material'] ? (
										<Text style={globalStyles.error}>{errors['material']}</Text>
									) : null}
								</>
							)}
						</View>
					</View> */}
					{elementMaterial === 'other' && 
						<View style={globalStyles.fieldContainer}>
						<Text style={globalStyles.label}>Breach Material (Other) <Text style={styles.required}>*</Text></Text>
						<View style={{ flex: 1 }}>
							<TextInput
							style={globalStyles.field}
							onChangeText={(value) => setElementMaterialOther(value)}
							value={elementMaterialOther}
							/>
						</View>
						</View>
					}
					{(element_survey?.floorplan && Object.entries(element_survey?.floorplan).length > 0 && 
						<View style={globalStyles.fieldContainer}>
							<Text style={globalStyles.label}>Floor Plan Marker</Text>
							<View style={{...styles.buttonContainer, marginTop: 0, paddingHorizontal: 0 }}>
								<Button title="View Floor Plan" variant="secondary" onPress={() => toggleFloorplan(true)} />
								{!!floorPin && <Text style={{marginLeft: 20, marginTop: 10}}>Pin Added</Text>}
							</View>
						</View>
					)}
					{element_survey?.surveyPassFailType === 1 ? (
						<View style={globalStyles.fieldContainer}>
							<Text style={globalStyles.label}>Pass / Fail <Text style={styles.required}>*</Text></Text>
								<View style={{ flex: 1 }}>
								<View style={styles.statusContainer}>
									<TouchableOpacity
										style={{
											...styles.statusButton,
											paddingHorizontal: 16,
											backgroundColor: colors.success,
											opacity: status === 'failed' ? 0.1 : 1,
											marginRight: 24,
										}}
										onPress={() => setStatus('passed')}
									>
										<FontAwesome5 name='check' size={30} color={colors.white} />
									</TouchableOpacity>
									
									<TouchableOpacity
										style={{
											...styles.statusButton,
											backgroundColor: colors.error,
											opacity: status === 'passed' ? 0.1 : 1,
										}}
										onPress={() => setStatus('failed')}
									>
										<FontAwesome5 name='times' size={30} color={colors.white} />
									</TouchableOpacity>
								</View>

								{!!errors['status'] &&
									<Text style={globalStyles.error}>{errors['status']}</Text>
								}
							</View>
						</View>
					) : null}
					{status === 'failed' &&
						<>
							<View style={{...globalStyles.fieldContainer, zIndex: 600 }}>
								<Text style={globalStyles.label}>Fail Type <Text style={styles.required}>*</Text></Text>
								<View style={{ flex: 1 }}>
									<DropDownPicker
										placeholder='Please select a type'
										dropDownDirection="BOTTOM"
										open={failTypeIsOpen}
										value={failType}
										items={failTypeOptions}
										setOpen={setFailTypeIsOpen}
										onOpen={() => onDropdownOpen("type")} 
										setValue={setFailType}
										setItems={setFailTypeOptions}
										onChangeValue={handleFailTypeChange}
										style={[globalStyles.dropdown, styles.field]}
										dropDownContainerStyle={globalStyles.dropdownItem}
									/>
									{!!errors['fail_type'] && 
										<Text style={globalStyles.error}>{errors['fail_type']}</Text>
									}
								</View>
							</View>
							{failType === 'hole' && 
								<>
									{!!errors['hole_size'] && 
										<Text style={{...globalStyles.error, marginLeft: 200}}>{errors['hole_size']}</Text>
									}
									<View style={[globalStyles.fieldContainer, { zIndex: 400 }]}>
										<Text style={globalStyles.label}>Size <Text style={styles.required}>*</Text></Text>
										<View style={{ flex: 1 }}>
											<DropDownPicker
												placeholder='Please select a hole size'
												dropDownDirection="BOTTOM"
												open={holeSizeIsOpen}
												value={holeSize}
												items={holeSizeOptions}
												setOpen={setHoleSizeIsOpen}
												onOpen={() => onDropdownOpen("size")} 
												setValue={setHoleSize}
												setItems={setHoleSizeOptions}
												style={[globalStyles.dropdown, styles.field]}
												dropDownContainerStyle={globalStyles.dropdownItem}
											/>
										</View>
									</View>
									{holeSize === 'other' && 
										<>
											<View style={globalStyles.fieldContainer}>
												<Text style={globalStyles.label}>Hole Width (mm) <Text style={styles.required}>*</Text></Text>
												<View style={{ flex: 1 }}>
													<TextInput
														style={globalStyles.field}
														onChangeText={(value) => setHoleSizeOtherWidth(value)}
														value={holeSizeOtherWidth}
													/>
												</View>
											</View>
											<View style={globalStyles.fieldContainer}>
												<Text style={globalStyles.label}>Hole Length (mm) <Text style={styles.required}>*</Text></Text>
												<View style={{ flex: 1 }}>
													<TextInput
														style={globalStyles.field}
														onChangeText={(value) => setHoleSizeOtherLength(value)}
														value={holeSizeOtherLength}
													/>
												</View>
											</View>
										</>
									}
									<View style={[globalStyles.fieldContainer, { zIndex: 200 }]}>
										<Text style={globalStyles.label}>Services Via Hole <Text style={styles.required}>*</Text></Text>
										<View style={{ flex: 1 }}>
											<DropDownPicker
												placeholder='Please select an option'
												dropDownDirection="BOTTOM"
												open={servicesViaHoleIsOpen}
												value={servicesViaHole}
												items={servicesViaHoleOptions}
												setOpen={setServicesViaHoleIsOpen}
												onOpen={() => onDropdownOpen("services")} 
												setValue={setServicesViaHole}
												setItems={setServicesViaHoleOptions}
												style={[globalStyles.dropdown, styles.field]}
												dropDownContainerStyle={globalStyles.dropdownItem}
											/>
											{!!errors['services'] &&
												<Text style={globalStyles.error}>{errors['services']}</Text>
											}
										</View>
									</View>
								</>
							}
							{failType === 'crack' && 
								<>
									{!!errors['crack_size'] && 
										<Text style={{...globalStyles.error, marginLeft: 200}}>{errors['crack_size']}</Text>
									}
									<View style={globalStyles.fieldContainer}>
										<Text style={globalStyles.label}>Crack Width (mm) <Text style={styles.required}>*</Text></Text>
										<TextInput
										style={globalStyles.field}
										onChangeText={(value) => setCrackWidth(value)}
										value={crackWidth}
										/>
									</View>
									<View style={globalStyles.fieldContainer}>
										<Text style={globalStyles.label}>Crack Depth (mm) <Text style={styles.required}>*</Text></Text>
										<TextInput
										style={globalStyles.field}
										onChangeText={(value) => setCrackDepth(value)}
										value={crackDepth}
										/>
									</View>
									<View style={globalStyles.fieldContainer}>
										<Text style={globalStyles.label}>Crack Length (m) <Text style={styles.required}>*</Text></Text>
										<TextInput
										style={globalStyles.field}
										onChangeText={(value) => setCrackLength(value)}
										value={crackLength}
										/>
									</View>
								</>
							}
							{isNumeric(crackWidth) && isNumeric(crackLength) && isNumeric(crackDepth) && (
								<View style={styles.crackRequirementContainer}>
									<Text variant='bold' size='md'>Requires {calculateCrack(crackWidth, crackLength, crackDepth)} Millilitres of Mastic:</Text>
								</View>
							)}
						</>
					}
				</View>
				{status === 'failed' ? (
					<>
						<View style={styles.costsHeader}>
							<Text variant='heading' size='xl'>Reinstatement Costs</Text>
						</View>
						<View style={styles.listContainer}>
							{workCosts.filter((item) => item.deleted === 0).map((item) => 
								<RemedialWorkItem key={item.id} item={item} cost={costs[findValueInColumn(item.cost_id, costs, "id")]} element={element} setElementCosts={setWorkCosts} />)
							}
							<TouchableOpacity onPress={handleAddCosts}>
								<View style={styles.addReinstatementContainer}>
									<View style={{ marginRight: 16 }}>
										{costs.length > 0 ? (
											<AntDesign name="pluscircleo" size={20} color="#2196F3" />
										) : (
											<Text color="#2196F3">Set Up Costs</Text>
										)}
									</View>
								</View>
							</TouchableOpacity>
						</View>
						{showAddCostForm && <AddRemedialWorkItemForm element={{fail_type: failType, hole_size: holeSize, hole_size_other_width: holeSizeOtherWidth, hole_size_other_length: holeSizeOtherLength, crack_width: crackWidth, crack_length: crackLength, crack_depth: crackDepth}} elementCosts={workCosts} setElementCosts={setWorkCosts}/>}
						{total > 0.00 ? (
							<View style={styles.totalContainer}>
								<Text variant='bold' size="md">Total Reinstatement Cost {moneyFormatter.format(total)}</Text>
							</View>
						) : null}
					</>
				) : null}
				<View style={styles.buttonContainer}>
					<Button
						style={{ marginRight: 24 }}
						// title={nextElement ? 'Save & Next' : newElement ? 'Save & Next' : 'Save & Finish'}
						title={newSurvey ? 'Save & Next' : newElement ? 'Save & Finish' : nextElement ? 'Save & Next' : 'Save & Finish'}
						variant='secondary'
						onPress={() => {
							if(handleSave())
							{
								if (newSurvey) {
									if (nextElement) {
										dispatch(setElement(nextElement));
										navigation.reset({
											index: 0,
											routes: [{ name: 'FireStopElementsFormEdit' }]
										});
									} else {
										dispatch(createElement());
										navigation.reset({
											index: 0,
											routes: [{ name: 'FireStopElementsFormEdit' }]
										});
									}
								} else {
									if (newElement) {
										// navigation.navigate('FireStopElements');
										navigation.reset({
											index: 0,
											routes: [{ name: 'FireStopElements' }]
										});
									} else {
										if (nextElement) {
											dispatch(setElement(nextElement));
										} else {
											// navigation.navigate('FireStopElements');
											navigation.reset({
												index: 0,
												routes: [{ name: 'FireStopElements' }]
											});
										}
									}
								}
							}
						}}
					/>
					<Button
						style={{ marginRight: 24 }}
						title='Save & Close'
						variant='orange'
						onPress={() => {
							if(handleSave()) {
								navigation.reset({
									index: 0,
									routes: [{ name: 'FireStopElements' }]
								});
							}
						}}
					/>
					<Button
						title='Back'
						onPress={() => {
							// This fixes an issue where the cost changes but the dimensions do not
							if(element?.remedial_work_costs.length > 0) {
								dispatch(
									updateQuantity({
									holeSize: element?.hole_size,
									holeSizeOtherWidth: element?.hole_size_other_width,
									holeSizeOtherLength: element?.hole_size_other_length,
									crackWidth: element?.crack_width,
									crackLength: element?.crack_length,
									crackDepth: element?.crack_depth
								}));
							}
							navigation.navigate('FireStopElements');
						}}
					/>
				</View>
			</ScrollView>
		</Layout>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingTop: padding.xxl,
		paddingBottom: padding.xl,
	},
	costsHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingVertical: 24,
	},
	formContainer: {
		backgroundColor: colors.darkGrey,
		borderRadius: radius.sm,
		marginTop: 16,
		paddingVertical: padding.md,
		paddingHorizontal: padding.md,
		zIndex: 800
	},
	addReinstatementContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		backgroundColor: colors.white,
		paddingHorizontal: 20,
		paddingVertical: 16,
		marginVertical: 5,
	},
	photographIcon: {
		borderRadius: 100,
		borderColor: 'black',
		borderWidth: 2,
		justifyContent: 'center',
		alignItems: 'center',
		width: 30,
		height: 30,
		marginBottom: 16
	},
	photographsContainer: {
		flexDirection: 'row'
	},
	photographContainer: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	photographButtonsContainer: {
		alignItems: 'center',
		justifyContent: 'center',
		width: '20%'
	},
	uploadContainer: {
		backgroundColor: '#E9E9E9',
		borderRadius: radius.sm,
		flex: 1,
		height: 300,
		width: '80%',
		maxWidth: 300
	},
	uploadPreviewContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%'
	},
	uploadPreviewText: {
		fontWeight: 500,
		color: colors.darkGrey
	},
	statusContainer: {
		flexDirection: 'row',
	},
	statusButton: {
		borderRadius: 100,
		paddingHorizontal: 20,
		paddingVertical: 2,
		flexDirection: 'row',
		alignItems: 'center',
	},
	buttonContainer: {
		flexDirection: 'row',
		marginTop: 20,
		paddingHorizontal: 20
	},
	required: {
		color: colors.primary,
	},
	requiredPhotoContainer: {
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: 340,
	},
	listContainer: {
		zIndex: 600
	},
	totalContainer: {
		paddingHorizontal: 20,
		marginTop: 20
	},
	crackRequirementContainer: {
		marginLeft: 180,
		marginTop: 16,
		marginBottom: 40,
		paddingHorizontal: 18
	}
});

export default FireStopElementsFormScreen;