import { StyleSheet } from 'react-native';

import { colors, fonts, radius } from '../constants/theme';

export const globalStyles = StyleSheet.create({
  fieldContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  label: {
    fontSize: fonts.md,
    fontWeight: '600',
    width: 180,
    paddingVertical: 10,
    fontFamily: 'Lato'
  },
  fieldInput: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontFamily: 'Lato',
    outline: 'none'
  },
  field: {
    backgroundColor: colors.white,
    minHeight: 50,
    borderRadius: radius.sm,
    paddingHorizontal: 10,
    flex: 1,
    maxWidth: 400,
    fontFamily: 'Lato'
  },
  dropdown: {
    backgroundColor: colors.white,
    borderRadius: radius.sm,
    borderWidth: 0,
    paddingHorizontal: 10,
    minHeight: 50,
    flex: 1,
    zIndex: 2000,
    elevation: 2000,
    maxWidth: 400,
    fontFamily: 'Lato',
	outline: 'none'
},
  dropdownItem: {
    borderWidth: 1,
  },
  error: {
    color: colors.error,
    fontSize: 12,
    marginTop: 2,
  },
  disabledInput: {
    width: 100,
    marginRight: 20,
    backgroundColor: colors.lightGrey,
    outline: 'none',
    cursor: 'initial',
  }
});
