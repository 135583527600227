import { useEffect, useState } from 'react';
import { View, Image } from 'react-native';
import Localbase from 'localbase';

const db = new Localbase('firestop');

export async function getPhotoFromDB(collection, id, callback)
{
	if (!!id)
	{
		console.log(db.collection(collection));
		db.collection(collection)
		.doc(id)
		.get()
		.then((data) => {
			if (!!data && typeof callback === 'function')
			{
				callback(data);
			}
		})
		.catch(err => console.error(err));
	}
}

const ElementPhotographThumbnail = ({ photo, collection, resizeMode = 'contain', size }) => {
	const [uri, setUri] = useState(null);

	useEffect(() => {
		getPhotoFromDB(collection, photo, (data) =>
		{
			setUri(data.photograph_uri);
		});
	}, [photo]);

	return (
		<View style={{ width: '100%' }}>
			{uri && (
				<Image source={{ uri }} resizeMode={resizeMode} style={size || { width: 100, height: 100 }} />
			)}
		</View>
	);
};

export default ElementPhotographThumbnail;