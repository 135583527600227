import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sync: {
    organisations: [],
    costs: [],
    surveys: [],
    repairs: [],
  },
  itemsToSync: 0,
  error: null,
};

const syncSlice = createSlice({
  name: 'sync',
  initialState,
  reducers: {
    updateSync(state, action) {
      const { type, id } = action.payload;

      if (Array.isArray(id)) {
        id.forEach((key) => state.sync[type].push(key));
      } else {
        if (!state.sync[type].includes(id)) {
          state.sync[type].push(id);
        }
      }
    },
    removeSyncItem(state, action) {
      const { type, id } = action.payload;

      state.sync[type].pop(id);
    },
    setItems(state, action) {
      state.itemsToSync = action.payload;
    },
    resetItems(state) {
      state.itemsToSync = 0;
    },
    decreaseItems(state) {
      state.itemsToSync--;
    }
  },
});

export const selectSyncItems = (state) => {
  let hasValues = false;

  for (let prop in state.sync.sync) {
    if (state.sync.sync[prop].length > 0) {
      hasValues = true;
      break;
    }
  }

  return hasValues;
};

export const selectTotalItemsToSync = (state) =>
  Object.values(state.sync.sync).reduce((acc, cur) => acc + cur.length, 0);

export const { updateSync, removeSyncItem, setItems, decreaseItems, resetItems } = syncSlice.actions;

export default syncSlice.reducer;
