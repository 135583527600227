import { useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { findValueInColumn } from '../functions/array';
import Button from '../components/Button';
import DropDownPicker from 'react-native-dropdown-picker';

import { default as Text } from '../components/AppText';
import { DatePicker } from '../functions/date';

import { colors, padding, radius } from '../constants/theme';
import { getSelectedSurvey, selectSurveys, selectEntries, selectRepairs } from '../features/firestop/firestopSlice';
import { selectOrganisations } from '../features/organisations/organisationsSlice';

import Layout from '../containers/Layout';
import RepairForm from '../features/firestop/RepairForm';
import { globalStyles } from '../styles/GlobalStyles';
import { selectUser } from '../features/auth/authSlice';

export default function FireStopRepairsScreen()
{
	const navigation = useNavigation();
	const user = useSelector(selectUser);

	const [status, setStatus] = useState('outstanding');
  const [statusOptions, setStatusOptions] = useState([
		{ label: 'All', value: 'all' },
		{ label: 'Outstanding', value: 'outstanding' },
    { label: 'Repaired', value: 'repaired' },
  ]);
  const [statusIsOpen, setStatusIsOpen] = useState(false);

	const newFromDate = new Date();
	newFromDate.setMonth(newFromDate.getMonth() - 1);

	const newToDate = new Date();
	newToDate.setDate(newToDate.getDate() + 1);

	const [dateTo, setDateTo] = useState(newToDate);
	const [dateFrom, setDateFrom] = useState(newFromDate);

	// Show for a specific survey.  If null show all
	//const current_survey = null;
	const current_survey = useSelector(getSelectedSurvey);

	// All four are needed for display
	const organisations = useSelector(selectOrganisations);
	const repairs = useSelector(selectRepairs);
	const surveys = useSelector(selectSurveys);
	const entries = useSelector(selectEntries);

	const handleExport = () => {
		if (user) {
			window.open(`https://firestop.webglu35.co.uk/repairs_report/index.php?customer_id=${user.id}&sk=${user.secret_key}&status=${status}&date_from=${dateFrom}&date_to=${dateTo}`, '_blank').focus();
		}
	}

	// Sorts out repairs in a simplier way
	let array_repairs = {};

	for(var repair of Object.entries(repairs))
	{
		if(entries[repair[1].entry] !== undefined)
		{
			let entry_survey = entries[repair[1].entry].survey;

			if(current_survey == null || current_survey == entry_survey)
			{
				let element_index = findValueInColumn(repair[1].element, entries[repair[1].entry].elements, 'id');

				if(element_index !== -1)
				{
					if (surveys[entry_survey]) {
						if(array_repairs[entry_survey] === undefined)
						{
							let organisation_index = findValueInColumn(surveys[entry_survey].organisationName, organisations, 'id');
							array_repairs[entry_survey] = { 'name': (organisation_index !== -1 ? organisations[organisation_index].name + ': ' : '') + surveys[entry_survey].surveyName, 'repairs': {}};
						}
	
						const isDateBetween = new Date(entries[repair[1].entry].created).getTime() >= new Date(dateFrom).getTime() && new Date(entries[repair[1].entry].created).getTime() <= new Date(dateTo).getTime();
	
						if (((status !== 'all' && repair[1].status === status) || status === 'all') && isDateBetween) {
							const elementId = repair[1].element;
							const elementName = surveys[entry_survey].elements.find((element) => element.id === elementId)?.name;
							array_repairs[entry_survey].repairs[repair[0]] = { ...repair[1], ...{ 'name': elementName }};
						}
					}
				}
			}
		}
	}

	const hasRepairs = Object.values(array_repairs).every((repair) => Object.values(repair.repairs).length > 0);

	const SurveyItem = (props) => 
	{
		const [showForm, setShowForm] = useState(0);
		const [selectedSurvey, setSelectedSurvey] = useState(0);

		console.log(props);

		if (Object.entries(props.survey.repairs).length > 0 ) {
			return (
				<View style={[styles.list, { zIndex: selectedSurvey === props.survey?.id ? 1000 : 0 }]}>
					<View style={styles.listHeader}>
						<View>
							<Text variant={props.variant} size='md'>{ props.survey?.name }</Text>
						</View>
					</View>
					{Object.values(props.survey.repairs).map((repair) => (
						<View key={repair.id} style={{ width: '100%', zIndex: showForm === repair.id ? 1000 : 0 }}>
							<View style={[styles.listItem, { flexDirection: 'row', backgroundColor: colors.white }]}>
								<View>
									<Text variant={props.variant} size='md'>{repair.name}: <Text style={{ textTransform: 'capitalize' }}>{repair.status}</Text></Text>
								</View>
								<View>
									<Button size="sm" title="View" variant="secondary" onPress={() => {
										setShowForm((repair.id == showForm ? 0 : repair.id));
										setSelectedSurvey(props.survey?.id);
									}}/>
								</View>
							</View>
							{showForm === repair.id ? (
								<RepairForm key={repair.id} repair={repair} setShowForm={setShowForm} setSelectedSurvey={setSelectedSurvey} />
							) : null}
						</View>	
					))}
				</View>
				);
		}

		return null;
	}

	return (
		<Layout title='Fire Stop Repairs'>

			<ScrollView showsVerticalScrollIndicator={false} style={styles.container}>

				<Text style={{marginTop: '50px'}} heading size='xl'>Repairs</Text>

				<View style={{...styles.filtersContainer, zIndex: 1000 }}>
					<View style={styles.filterRow}>
						<Text style={{ marginRight: 8 }}>From: </Text>
						<DatePicker name="date" style={styles.fieldInput} value={dateFrom} setValue={setDateFrom} />
					</View>
					<View style={styles.filterRow}>
						<Text style={{ marginRight: 8 }}>To: </Text>
						<DatePicker name="date" style={styles.fieldInput} value={dateTo} setValue={setDateTo} />
					</View>
					<View style={{...styles.filterRow, ...styles.fieldContainer, paddingRight: 0, marginRight: 9, zIndex: 1000 }}>
						<DropDownPicker
							containerStyle={{ width: 150, marginRight: 0 }}
							placeholder='Please select a status'
							dropDownDirection="BOTTOM"
							open={statusIsOpen}
							value={status}
							items={statusOptions}
							setOpen={setStatusIsOpen}
							setValue={setStatus}
							setItems={setStatusOptions}
							style={{...globalStyles.dropdown, ...styles.field}}
							dropDownContainerStyle={globalStyles.dropdownItem}
						/>
					</View>
				</View>

				{hasRepairs ? (
					<View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginRight: 9 }}>
						<Button title="Export as PDF" onPress={handleExport} />
					</View>
				) : null}

				{repairs !== undefined && Object.entries(array_repairs).length > 0 ? (
					<View>
						{
							Object.entries(array_repairs).map((index) => (
								<SurveyItem key={index[0]} survey={index[1]} />
							))
						}
					</View>
				) : (
					<Text>No Outstanding Repairs</Text>
				)}
				<View style={styles.footerButtonsContainer}>
					<Button title="Back" variant="black" onPress={() => navigation.navigate('FireStopDashboard')} />
				</View>
			</ScrollView>

		</Layout>
	);
}

const styles = StyleSheet.create({
	container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
	list: {
		alignItems: 'left',
		paddingHorizontal: padding.sm,
		paddingVertical: padding.sm,
	},
	listHeader: {
		flexDirection: 'row',
		marginTop: 10,
		paddingVertical: 15,
	},
	listItem: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingVertical: padding.sm,
		paddingHorizontal: padding.sm,
		marginTop: 8,
	},
	filtersContainer: {
		marginTop: 16,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	filterRow: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginRight: 16
	},
	form: {
		paddingHorizontal: padding.sm,
		paddingVertical: padding.sm,
	},
	fieldBlock: {
		paddingVertical: padding.sm,
		flexDirection: 'row',
		alignItems: 'flex-start'
		// justifyContent: 'space-between'
	},
	fieldLabel: {
		width: '150px',
		marginTop: 8
	},
	fieldInput: {
		paddingHorizontal: padding.sm,
		paddingVertical: padding.sm,
		flex: 1,
		backgroundColor: '#ffffff'
	},
	fieldContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  field: {
    backgroundColor: 'white',
    flex: 1,
    borderRadius: radius.sm,
    paddingHorizontal: 10,
    paddingVertical: 15,
  },
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	footerButtonsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: 20,
		zIndex: -1
	}
});