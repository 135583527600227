import { createContext, useState } from 'react';

const OptionsContext = createContext(null);

export const OptionsProvider = ({ children }) => {
  const [showAddCostsPanel, setShowAddCostsPanel] = useState(false);
  const [showSortButton, setShowSortButton] = useState(true);
  const [canSort, setCanSort] = useState(false);
  const [selectedCostItem, setSelectedCostItem] = useState(null);

  return (
    <OptionsContext.Provider
      value={{
        showAddCostsPanel,
        showSortButton,
        selectedCostItem,
        canSort,
        setShowAddCostsPanel,
        setShowSortButton,
        setSelectedCostItem,
        setCanSort,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};

export default OptionsContext;
