import Svg, { Path } from 'react-native-svg';

import { colors } from '../constants/theme';

const Edit = (props) => (
  <Svg
    height={30}
    width={30}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 30 30'
    fill={colors.black}
    {...props}
  >
    <Path d="M24.09375 25.3125c0 .515625-.421875.9375-.9375.9375H4.6875c-.515625 0-.9375-.421875-.9375-.9375V6.84375c0-.515625.421875-.9375.9375-.9375h10.882812c.261719 0 .496094-.105469.664063-.273438.171875-.171874.273437-.40625.273437-.664062 0-.519531-.417968-.9375-.9375-.9375H4.6875c-1.550781 0-2.8125 1.261719-2.8125 2.8125V25.3125c0 1.550781 1.261719 2.8125 2.8125 2.8125h18.46875c1.550781 0 2.8125-1.261719 2.8125-2.8125V14.429688c0-.519532-.417969-.9375-.9375-.9375-.257812 0-.492188.101562-.664062.273437-.167969.167969-.273438.402344-.273438.664063Zm0 0"/>
    <Path d="m9.835938 14.855469-1.558594 5.714843c-.089844.324219.003906.671876.242187.910157.175781.179687.417969.273437.664063.273437.082031 0 .164062-.007812.246094-.03125l5.714843-1.558594c.15625-.042968.300781-.125.414063-.242187L26.988281 8.492188c1.511719-1.511719 1.511719-3.96875 0-5.480469-1.511719-1.511719-3.96875-1.511719-5.480469 0L10.078125 14.441406c-.117187.113282-.199219.257813-.242187.414063ZM25.664062 4.335938c.78125.78125.78125 2.050781 0 2.832031l-.65625.65625-2.832031-2.832031.65625-.65625c.78125-.78125 2.050781-.78125 2.832031 0Zm-4.8125 1.980468 2.832032 2.832032-9.273438 9.273437-3.890625 1.0625 1.058594-3.894531Zm0 0"/>
  </Svg>
);
export default Edit;