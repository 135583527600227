import { useState, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';

import Modal from '../../components/Modal';
import { deleteElement, duplicateElement, getCurrentSurvey, setElement } from './firestopSlice';
import { default as Text } from '../../components/AppText';
import Button from '../../components/Button';
import { colors } from '../../constants/theme';
import Trash from '../../components/Trash';
import { getPhotoFromDB } from '../../features/firestop/ElementPhotographThumbnail';

const ElementListItem = ({ element, entryElement }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [showPreview, setShowPreview] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const survey = useSelector(getCurrentSurvey);

  const handleEditElement = () => {
    dispatch(setElement(element.id));
    navigation.navigate('FireStopElementsFormEdit');
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  }
  
  const handleConfirmDelete = () => {
    dispatch(deleteElement(element.id));
    setConfirmDelete(false);
  }

  const handleCancelDelete = () => {
    setConfirmDelete(false);
  }

  useEffect(() => {
	getPhotoFromDB('element-photos', entryElement?.photograph_1?.id, (data) =>
	{
		setThumbnail(data.photograph_uri);
	});
  }, [entryElement]);

  return (
	<View style={styles.container}>
		<View style={styles.row}>
			<Text style={styles.name}>{element.name}</Text>
			<View style={{ width: '5%' }}>
				{(!entryElement.isComplete ?
					<FontAwesome name='exclamation-circle' size={24} color={colors.error} />
				: null)}
			</View>
      {survey.surveyPassFailType === 1 ? (
        <View style={{ width: '5%' }}>
          {(entryElement?.status === 'passed' ?
            <FontAwesome name='check' size={24} color={colors.success} />
          : 
            <FontAwesome name='times' size={24} color={colors.error} />
          )}
        </View>
      ) : null}
      <View style={{ width: '5%' }}>
        <Button icon={<FontAwesome name='camera' size={24} color={entryElement?.photograph_1 ? 'black' : '#EBEBEB'} />} variant='transparent' onPress={() => entryElement?.photograph_1 ? setShowPreview((prev) => !prev) : null} />
      </View>
			<View style={[styles.column, styles.actions]}>
				<Button title='Edit' variant='secondary' size='sm' onPress={handleEditElement} />
				<Button icon={<Trash />} variant='transparent' onPress={handleDelete} />
        {/* <Button title="Duplicate" variant='secondary' size='sm' onPress={() => dispatch(duplicateElement({id: element.id, amount:1 }))} />
				<Button title="Duplicate 10" variant='secondary' size='sm' onPress={() => dispatch(duplicateElement({ id: element.id, amount:10 }))} /> */}
			</View>
		</View>
		<Modal showModal={showPreview} setShowModal={() => setShowPreview(false)} containerStyle={{ maxWidth: 600 }}>
			<View>
				<Text variant='heading'>{element.name}</Text>
				<View style={{ marginTop: 20, width: 540 }}>
					<Image source={{ uri: thumbnail }} resizeMode='contain' style={{ width: '100%', height: 425 }} />
				</View>
			</View>
		</Modal>
    <Modal type="confirmation" showModal={confirmDelete} onConfirmation={handleConfirmDelete} onCancel={handleCancelDelete}>
      <Text>Are you sure you want to delete?</Text>
    </Modal>
	</View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    backgroundColor: colors.white,
    paddingHorizontal: 20,
    paddingVertical: 8,
    marginVertical: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  name: {
    width: '65%',
  },
  column: {
    width: '20%',
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ElementListItem;
