import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import Localbase from 'localbase';

import { FireStopAPI, APIError } from '../../services/api';
import { CurrentDateTime } from '../../functions/date';
import { findValueInColumn, checkIfNew } from '../../functions/array';
import { calculateCrack, calculateHole, calculateCost } from '../../functions/calculations';
import { updateSync } from '../sync/syncSlice';
import { addNewOrganisation } from '../organisations/organisationsSlice';

const db = new Localbase('firestop');

const initialState = {
  selectedSurvey: null,
  selectedSurveyEntry: null,
  selectedElement: null,
  surveys: {},
  surveysEntries: {},
  repairs: {},
  newOrganisation: null,
  status: 'idle', // idle, loading, succeeded, failed
  error: null,
};

export const postSurvey = createAsyncThunk('surveys/postSurvey', async (Integer_Survey, Slice) =>
{
	const newOrganisation = Slice.getState().firestop.surveys[Integer_Survey]?.newOrganisation;
	let survey = { ...Slice.getState().firestop.surveys[Integer_Survey], newOrganisation };

	survey.floorplan = await Promise.all(Object.entries(survey.floorplan).map(async (plan) =>
	{
		let savedImage = await db.collection('images').doc(plan[1].db_id).get();

		return { ...plan[1], ...savedImage };
	}));

	if (newOrganisation) {
		Slice.dispatch(addNewOrganisation(newOrganisation));
	}

	return FireStopAPI.post('/v1/index.php',
	{
		data: survey,
		action: 'surveys'
	}).catch((error) => {
		Slice.dispatch(updateSync({ type: 'surveys', id: Integer_Survey }));
		
		return APIError(error);
	});
});

export const postSurveyEntry = createAsyncThunk('surveys/postSurveyEntry', async (Integer_Entry, Slice) =>
{
	let entry = JSON.parse(JSON.stringify(Slice.getState().firestop.surveysEntries[Integer_Entry]));

	entry.elements = await Promise.all(entry.elements.map(async (element) => {
		let photographOne, photographTwo;

		if (element.photograph_1) {
			photographOne = await db.collection('element-photos').doc(element.photograph_1.id).get();
		}
		
		if (element.photograph_2) {
			photographTwo = await db.collection('element-photos').doc(element.photograph_2.id).get();
		}

		return {
			...element,
			photograph_1: photographOne,
			photograph_2: photographTwo,
		};
	}));

	return FireStopAPI.post('/v1/index.php',
	{
		data: entry,
		action: "surveys_entries"
	}).catch(APIError);
});

export const postRepair = createAsyncThunk('surveys/postRepair', async (repairs, ThunkAPI) =>
{
	let requestData;
	if (repairs === undefined || repairs === null) {
		const savedRepairs = ThunkAPI.getState().firestop.repairs;
		const entryId = ThunkAPI.getState().firestop.selectedSurveyEntry;

		if (Object.keys(savedRepairs).length > 0) {
			requestData = Object.values(savedRepairs).filter((repair) => repair.entry === entryId);
		}
	} else {
		requestData = await Promise.all(repairs.map(async (repair) => {
			let photograph;
			if (repair.photograph !== undefined && repair.photograph.id !== '') {
				photograph = await db.collection('repair-photos').doc(repair.photograph.id).get();
			}
			return { ...repair, photograph };
		}));
	}

	try {
		const { data } = await FireStopAPI.post('/v1/index.php', {
			data: requestData,
			action: 'repairs'
		});

		return { ...data, repairs: requestData };
	} catch (err) {
		if (repairs) {
			const ids = Object.values(repairs).map((repair) => repair.id);
			ThunkAPI.dispatch(updateSync({ type: 'repairs', id: ids }));
		}

		return APIError(err);
	}
});

export const deleteSurvey = createAsyncThunk('surveys/deleteSurvey', async (survey) => {
	if(!checkIfNew(survey))
	{
		await FireStopAPI.delete('/v1/index.php', {
			params: {
				action: "surveys",
				id: survey,
			}
		}).catch(APIError);
	}

	return survey;
});

export const deleteEntry = createAsyncThunk('surveys/deleteEntry', async (entry) => {
	if(!checkIfNew(entry))
	{
		await FireStopAPI.delete('/v1/index.php', {
			params: {
				action: "surveys_entries",
				id: entry,
			}
		}).catch(APIError);
	}

	return entry;
});

export const firestopSlice = createSlice({
  name: 'firestop',
  initialState,
  reducers: {
    saveSurvey(state, action)
	{
		action.payload.elements = [];

		if(state.selectedSurvey != null)
		{
			action.payload.id = state.selectedSurvey;
			
			if(!!state.surveys[state.selectedSurvey].elements)
			{
				const deletedFloorplans = Object.entries(state.surveys[state.selectedSurvey].floorplan).map(([id, plan]) => {
					if(plan.deleted === 1)
					{
						return id;
					}
				})

				action.payload.elements = state.surveys[state.selectedSurvey].elements.map((element) => {
					if(element.coordinates != null && deletedFloorplans.indexOf(element.coordinates[3]) >= 0)
					{
						return {...element, coordinates: null };
					}

					return element;
				});
			}
		}
		else
		{
			action.payload.id = 'NEW_1';
			if (!!Object.keys(state.surveys)) {
				action.payload.id = `NEW_${Object.entries(state.surveys).length + 1}`;
			}
			let entryId = 'NEW_1';
			if (!!Object.keys(state.surveysEntries)) {
				entryId = `NEW_${Object.entries(state.surveysEntries).length + 1}`;
			}

			action.payload.deleted = 0;
			
			state.surveysEntries[entryId] = {
				id: entryId,
				survey: action.payload.id,
				elements: [],
				status: 'In Progress',
				created: CurrentDateTime(),
				adjustment: 0,
				deleted: 0
			};

			state.selectedSurvey = action.payload.id;
			state.selectedSurveyEntry = entryId;
		}

		state.surveys[action.payload.id] = { ...action.payload, newOrganisation: state.newOrganisation, deleted: 0 };
    },
	processSurvey(state, action)
	{
		const { adjustment } = action.payload;

		if(state.surveysEntries[state.selectedSurveyEntry].status != 'Complete')
		{
			let repair_length = Object.keys(state.repairs).length;

			Object.entries(state.surveysEntries[state.selectedSurveyEntry].elements).map((element) =>
			{
				if(element[1].status == "failed")
				{
					let new_id = "NEW_" + (++repair_length).toString();
					// add to repair
					state.repairs[new_id] =
					{
						"id": new_id,
						"survey": state.selectedSurvey,
						"entry": state.selectedSurveyEntry,
						"element": element[1].id,
						"notes": "",
						"status": "outstanding",
						"date": "",
						"deleted": 0
					};
				}
			});
		}

		if(!state.surveys[state.selectedSurvey].created)
		{
			state.surveys[state.selectedSurvey].created = CurrentDateTime();
		}

		if(!state.surveysEntries[state.selectedSurveyEntry].completed)
		{
			state.surveysEntries[state.selectedSurveyEntry].completed = CurrentDateTime();
		}

		state.surveysEntries[state.selectedSurveyEntry].adjustment = adjustment;
		state.surveysEntries[state.selectedSurveyEntry].status = 'Complete';
    },
    updateQuantity(state, action) {
      const selectedElement = state.selectedElement;

		selectedElement.remedial_work_costs = [...selectedElement.remedial_work_costs.map((work) => {
			let calculatedSize;
			
			switch(work.calculation) {
				case 2:

					const { holeSize, holeSizeOtherLength, holeSizeOtherWidth } = action.payload;
	
					let width, length;
	
					if (holeSize === 'other') {
						width = holeSizeOtherWidth;
						length = holeSizeOtherLength;
					} else {
						[width, length] = holeSize.replaceAll("m", "").replaceAll(" ", "").split("x");
					}
					
					calculatedSize = calculateHole(width, length);

				break;

				default:
			
					calculatedSize = work.quantity;

				break;
			}

			work.quantity = calculatedSize;

			return work;
		})];


		const entryElement = state.surveysEntries[state.selectedSurveyEntry].elements.find((element) => element.id === selectedElement.id);
		
		if (entryElement) {
        
			const updatedWorkCosts = entryElement.remedial_work_costs.map((work) => {
				let calculatedSize;

				if (work.calculation === 2) {
					const { holeSize, holeSizeOtherLength, holeSizeOtherWidth } = action.payload;
		
					let width, length;
		
					if (holeSize === 'other') {
						width = holeSizeOtherWidth;
						length = holeSizeOtherLength;
					} else {
						[width, length] = holeSize.replaceAll("m", "").replaceAll(" ", "").split("x");
					}

					calculatedSize = calculateHole(width, length);
				} else {
					calculatedSize = work.quantity;
				}

				work.quantity = calculatedSize;
				
				return work;
			});

			entryElement.remedial_work_costs = [...updatedWorkCosts];

			const updatedElements = state.surveysEntries[state.selectedSurveyEntry].elements.map((element) => {
				if (element.id === selectedElement.id) {
					return entryElement;
				}

				return element;
			});

        	// Update entry, not selectedElement as issue with the setElement function was overriding changes.
			state.surveysEntries[state.selectedSurveyEntry].elements = updatedElements;
		}
    },
    clearRemedialWorks(state) {
      const selectedElement = state.selectedElement;

      const updatedElement = state.surveysEntries[state.selectedSurveyEntry].elements.find((element) => element.id === selectedElement.id);

      const updatedElements = state.surveysEntries[state.selectedSurveyEntry].elements.map((element) => {
        if (element.id === selectedElement.id) {
          return { ...updatedElement, remedial_work_costs: [] };
        }

        return element;
      });

      state.surveysEntries[state.selectedSurveyEntry].elements = updatedElements;
    },
    calculateCosts(state, action) {
      const { id, costPrice, options } = action.payload;

      let filteredOptions = {};

      options.forEach((option) => {
        filteredOptions[option.id] = option.price;
      });

      const filteredEntries = Object.values(state.surveysEntries).filter((entry) => entry.status === 'In Progress');

      const updatedFilteredEntries = filteredEntries.map((entry) => {
        entry.elements.map((element) => {
          if (element.status === 'failed') {
            element.remedial_work_costs.map((work) => {
              const updatedOption = options.find((option) => option.id === work.option_id);

              if (updatedOption) {
                return {
                  ...work,
                  cost_price: Number(costPrice),
                  option_price: work.option_id !== 0 ? filteredOptions[work.option_id] : 0.00,
                };
              }
              
              if (work.cost_id === id) {
                return {
                  ...work,
                  cost_price: Number(costPrice),
                }
              }

              return work;
            })
          }
        });
      });

      if (state.selectedElement) {
        const newRemedialWorkCosts = state.selectedElement.remedial_work_costs.map((newWork) => {
          const updatedOption = options.find((option) => option.id === newWork.option_id);

          if (updatedOption) {
            // console.log(current(updatedOption));
            return {
              ...newWork,
              cost_price: Number(costPrice),
              option_price: newWork.option_id !== 0 ? filteredOptions[newWork.option_id] : 0.00,
            };
          }
          
          if (newWork.cost_id === id) {
            return {
              ...newWork,
              cost_price: Number(costPrice),
            }
          }

          return newWork;
        });

        state.selectedElement.remedial_work_costs = newRemedialWorkCosts;
      }
    },
    setSurvey(state, action) {
      state.selectedSurvey = action.payload;
    },
    clearSelectedSurvey(state) {
      state.selectedSurvey = null;
      state.selectedSurveyEntry = null;
      state.selectedElement = null;
			state.newOrganisation = null;
    },
    resumeSurvey(state, action) {
      const { entryId } = action.payload;
			let surveyId = Object.values(state.surveysEntries).filter((entry) => entry.id === entryId).map((entry) => { return entry.survey})[0];
			const survey = state.surveys[surveyId];

			if (survey) {
				state.newOrganisation = survey.newOrganisation;
			}

      state.selectedSurvey = surveyId;
      state.selectedSurveyEntry = entryId;
    },
    createOrganisation(state, action) {
			const { name, numberOfOrganisations } = action.payload;

      if (state.newOrganisation) {
        state.newOrganisation.name = name;
      } else {
        state.newOrganisation = {
          id: `NEW_${Number(numberOfOrganisations) + 1}`,
          name,
          addresses: [],
					deleted: 0
        };
      }
    },
    createOrganisationAddress(state, action) {
      let addressId;

      if (state.newOrganisation) {
        if (state.newOrganisation.addresses.length > 0) {
          state.newOrganisation.addresses[0].label = action.payload.address;
        } else {
          addressId = `NEW_${state.newOrganisation.addresses.length + 1}`
          state.newOrganisation.addresses.push({
            id: addressId,
            label: action.payload.address,
						deleted: 0,
          });
        }
      } else {
        // id = organisationId
        const { id, name, address, numberOfAddresses } = action.payload;

        if (id === 'new') {
          console.log('Something went wrong');

          return;
        }

        const newOrganisation = {
          id,
					name,
          addresses: [{
              id: `NEW_${Number(numberOfAddresses) + 1}`,
              label: address,
							deleted: 0
          }]
        }

        state.newOrganisation = newOrganisation;
      }
	},
	clearOrganisation(state, action)
	{
		state.newOrganisation = null;
	},
	createElement(state, action)
	{
		const element = {
			id: `NEW_${state.surveys[state.selectedSurvey].elements.length + 1}`,
			remedial_work_costs: [],
			deleted: 0,
		};

		state.selectedElement = element;
	},
	setElement(state, action)
	{
		const surveyElement = state.surveys[state.selectedSurvey].elements.find((element) => 
			element.id === action.payload
		);
		const entryElement = state.surveysEntries[state.selectedSurveyEntry].elements.find((element) => element.id === action.payload);

		state.selectedElement = { ...surveyElement, ...entryElement };
	},
	saveElement(state, action)
	{
		const { id, saveType } = action.payload;

		try
		{
			let newElements;
			let Boolean_Existed = false;

			switch (saveType)
			{
				case 'selectedElement':

					let newSelectedElement = { ...state.selectedElement, ...action.payload };

					delete newSelectedElement.saveType;

					state.selectedElement = newSelectedElement;

				break;

				case 'survey':

					if (state.selectedSurvey === undefined)
					{
						throw new Error('selectedSurvey not set');
					}

					const { name, location, location_other, material, material_other, coordinates } = action.payload;

					newElements = state.surveys[state.selectedSurvey].elements.map(
						(element) => {
							if (element.id === id)
							{
								Boolean_Existed = true;
								return { ...element, name, location, location_other, material, material_other, coordinates };
							}
							return element;
						}
					);

					if(!Boolean_Existed)
					{
						newElements = [
						...state.surveys[state.selectedSurvey].elements,
						{
							id: state.selectedElement.id,
							deleted: 0,
							name,
							location,
							location_other,
							material,
							material_other,
							coordinates
						}
						];
					}

					state.surveys[state.selectedSurvey].elements = newElements;

				break;
				
				case 'entry':

					if (state.selectedSurveyEntry === undefined)
					{
					throw new Error('selectedSurveyEntry not set');
					}

					const {
						status,
						fail_type,
						photograph_1,
						photograph_2,
						hole_size,
						hole_size_other_width,
						hole_size_other_length,
						services_via_hole,
						crack_width,
						crack_length,
						crack_depth,
						remedial_work_costs,
					} = action.payload;

					newElements = state.surveysEntries[state.selectedSurveyEntry].elements.map((element) => {
						if (element.id === id)
						{
							Boolean_Existed = true;
							return { ...element,
								fail_type,
								photograph_1,
								photograph_2,
								hole_size,
								hole_size_other_width,
								hole_size_other_length,
								services_via_hole,
								crack_width,
								crack_length,
								crack_depth,
								status,
								remedial_work_costs
							};
						}
						return element;
					});

					if(!Boolean_Existed)
					{
						newElements = [...state.surveysEntries[state.selectedSurveyEntry].elements, {
							id: state.selectedElement.id,
							deleted: 0,
							photograph_1,
							photograph_2,
							fail_type,
							hole_size,
							hole_size_other_width,
							hole_size_other_length,
							services_via_hole,
							crack_width,
							crack_length,
							crack_depth,
							status,
							remedial_work_costs
						}];
					}

					state.surveysEntries[state.selectedSurveyEntry].elements = newElements;

				break;

				default:

					throw new Error('Type not Found');

				break;
        	}
		}
		catch (error)
		{
			console.error(error.message);
		}
	},
    deleteElement(state, action) {
      const updatedEntriesElements = state.surveysEntries[
        state.selectedSurveyEntry
      ].elements.filter((element) => element.id !== action.payload);

      const updatedElements = state.surveys[state.selectedSurvey].elements.map(
        (element) => {
          if (element.id === action.payload) {
            return { ...element, deleted: 1 };
          }

          return element;
        }
      );

      state.surveysEntries[state.selectedSurveyEntry].elements =
        updatedEntriesElements;
      state.surveys[state.selectedSurvey].elements = updatedElements;
    },
    /*addCostToRemedialWorks(state, action) {
      const { cost, option, quantity } = action.payload;

      let costOption;

      if (option) {
        costOption = {
          option_id: option.id,
          option_title: option.title,
          option_price: Number(option.price),
        };
      } else {
        costOption = {
          option_id: 0,
          option_title: '',
          option_price: 0.0,
        };
      }

      if (state.selectedElement) {
        state.selectedElement.remedial_work_costs.push({
          id: `NEW_${state.selectedElement.remedial_work_costs.length + 1}`,
          cost_id: cost.id,
          cost_title: cost.title,
          cost_price: Number(cost.price),
          calculation: cost.calculation,
          ...costOption,
          quantity: Number(quantity),
        });
      }
    },
    removeRemedialWorkCost(state, action) {
      const { id } = action.payload;

      state.selectedElement.remedial_work_costs =
        state.selectedElement.remedial_work_costs.filter(
          (cost) => cost.id !== id
        );
    },
    changeRemedialWorkItemQuantity(state, action) {
      const newRemedialWorkCosts =
        state.selectedElement.remedial_work_costs.map((cost) => {
          if (cost.id === action.payload.id) {
            return { ...cost, quantity: Number(action.payload.quantity) };
          }
          return cost;
        });

      state.selectedElement.remedial_work_costs = newRemedialWorkCosts;
    },*/
    completeRepair(state, action) {
      // May need a 'unable to repair' status
      action.payload.status = 'repaired';
      state.repairs[action.payload.id] = action.payload;
    },
		deferRepair(state, action) {
			let updatedRepairs = state.repairs;
			if (updatedRepairs[action.payload.id]) {
				updatedRepairs[action.payload.id].status = 'deferred';
			}
			
			state.repairs = updatedRepairs;
		},
    startNewSurveyEntry(state, action) {
      let entryId = 'NEW_1';
      if (!!Object.keys(state.surveysEntries)) {
        entryId = `NEW_${Object.entries(state.surveysEntries).length + 1}`;
      }

      const surveyTemplate = Object.values(state.surveys).find(
        (survey) => survey.id === action.payload.surveyId
      );

      let newElements = [];

			const entries = Object.values(state.surveysEntries).filter((entry) => {
				let surveyIdToFilter;
				if (String(action.payload.surveyId).includes('NEW')) {
					surveyIdToFilter = action.payload.surveyId;
				} else {
					surveyIdToFilter = Number(action.payload.surveyId);
				}
		
				if (entry.survey === surveyIdToFilter && entry.deleted === 0) {
					return entry;
				}
			});

      if (action.payload.usePreviousElements && surveyTemplate) {
				if (action.payload?.useOnlyOutstandingRepairs) {
					const entryRepairs = Object.values(state.repairs).filter((repair) => {
						const entry = entries.find((entry) => entry.id === repair.entry);
      
						if (entry && repair.status === 'outstanding') {
							return true;
						}
						return false;
					});

					if (entryRepairs.length > 0) {
						newElements = surveyTemplate.elements
							.filter((element) => {
								if (element.deleted === 0) {
									const matchingRepair = entryRepairs.find((repair) => repair.element === element.id);
		
									if (matchingRepair) {
										return true;
									}
								}
								return false;
							})
							.map((element) => ({
								id: element.id,
								photograph_1: '',
								photograph_2: '',
								remedial_work_costs: [],
								deleted: 0,
						}));

						const updatedElements = state.surveys[action.payload.surveyId].elements
							.filter((element) => element.deleted === 0)
							.map((element) => {
								const matchingRepair = entryRepairs.find((repair) => repair.element === element.id);
		
								if (matchingRepair) {
									return {
										...element,
										deleted: 0
									};
								}

								return {
									...element,
									deleted: 1
								};
							});
		
						state.surveys[action.payload.surveyId].elements = [...updatedElements];
					}
				} else {
					newElements = surveyTemplate.elements.filter((element) => element.deleted === 0).map((element) => ({
						id: element.id,
						photograph_1: '',
						photograph_2: '',
						remedial_work_costs: [],
						deleted: 0,
					}));
				}
      }

			if (!action.payload.usePreviousElements) {
				const updatedElements = state.surveys[action.payload.surveyId].elements.map((element) => ({
					...element,
					deleted: 1
				}));

				state.surveys[action.payload.surveyId].elements = updatedElements;
			}
			
      state.selectedSurvey = action.payload.surveyId;
      state.selectedSurveyEntry = entryId;
      state.surveysEntries[entryId] = {
        id: entryId,
        survey: action.payload.surveyId,
        elements: newElements,
        status: 'In Progress',
        created: CurrentDateTime(),
        adjustment: 0,
				deleted: 0
      };
    },
	duplicateElement(state, action)
	{
		const { id, amount, entry } = action.payload;

		try
		{
			if(id === undefined)
			{
				throw new Error('Breach Not found');
			}

			if(amount === undefined)
			{
				amount = 1;
			}

			let entryID = entry;

			if(entryID === undefined)
			{
				entryID = state.selectedSurveyEntry;
			}

			let surveyID = state.surveysEntries[entryID].survey;
			let elementID = findValueInColumn(id, state.surveys[surveyID].elements, "id");

			let totalElements = state.surveys[surveyID].elements.length

			for(let i = 0; i < amount; i++)
			{
				let newIndex = "NEW_" + ++totalElements;

				state.surveys[surveyID].elements = [...state.surveys[surveyID].elements, { ...state.surveys[surveyID].elements[elementID], id: newIndex }];
				state.surveysEntries[entryID].elements = [...state.surveysEntries[entryID].elements, { ...state.surveysEntries[entryID].elements[elementID], id: newIndex }];
			}
		}
		catch(error)
		{
			console.error(error.message);
		}

	}
  },
	extraReducers(builder)
	{
		builder
		.addCase(postSurvey.fulfilled, (state, action) => {
			try
			{
				if(action.payload.data.status == "ok" && action.payload.data.survey !== undefined)
				{
					const [ updateID, surveyUpdates ] = Object.entries(action.payload.data.survey)[0];

					let newSurveyArray = state.surveys[updateID];

					let newFloorplan = {};

					Object.entries(newSurveyArray.floorplan).map((plan) => 
					{
						let newID = plan[0];

						if(surveyUpdates.floorplans[plan[0]] !== undefined)
						{
							newID = surveyUpdates.floorplans[plan[0]];
							plan[1].id = newID;
						}

						newFloorplan[newID] = plan[1];
					});

					newSurveyArray.floorplan = {...newFloorplan};

					if(surveyUpdates.id !== undefined)
					{	
						// Remove from the local storage first if NEW_#
						delete state.surveys[updateID];
						newSurveyArray.id = surveyUpdates.id;

						if (action.payload.data?.organisation?.is_new) {
							newSurveyArray.organisationName = Number(action.payload.data.organisation.organisation_id);
							newSurveyArray.organisationAddress = Number(action.payload.data.organisation.address_id);
							newSurveyArray.newOrganisation = null;
						}
						state.surveys[surveyUpdates.id] = newSurveyArray;
					}

					newSurveyArray.elements.map((element) => 
					{
						if(surveyUpdates.elements[element.id] !== undefined)
						{
							element.id = surveyUpdates.elements[element.id];
						}

						if (element.coordinates) {
							if(!!surveyUpdates.floorplans[element.coordinates[3]])
							{
								element.coordinates[3] = surveyUpdates.floorplans[element.coordinates[3]];
							}
						}
					});

					Object.entries(state.surveysEntries).filter((entry) => entry[1].survey == updateID).map((entry) => 
					{
						if(surveyUpdates.id !== undefined)
						{	
							state.surveysEntries[entry[0]].survey = surveyUpdates.id;
						}
						
						state.surveysEntries[entry[0]].elements.map((element) => 
						{
							if(surveyUpdates.elements[element.id] !== undefined)
							{
								element.id = surveyUpdates.elements[element.id];
							}
						});
						
						Object.entries(state.repairs).filter((repair) => repair[1].entry === entry[0]).map((repair) => 
						{
							state.repairs[repair[0]].element = surveyUpdates.elements[repair[1].element];
							state.repairs[repair[0]].survey = surveyUpdates.id;
						});
					});
					
					if(state.selectedSurvey == updateID && surveyUpdates.id !== undefined)
					{
						state.selectedSurvey = surveyUpdates.id;
					}
				} else {
					if (state.surveys[state.selectedSurvey]) {
						state.surveys[state.selectedSurvey].newOrganisation = state.newOrganisation; 
					}
				}

				return state;
			}
			catch(e)
			{
				console.error(e.message);
			}
		})
		.addCase(postSurveyEntry.fulfilled, (state, action) => {
			try
			{
				if(action.payload.data.status == "ok" && action.payload.data.entry !== undefined)
				{
					let entryUpdates = Object.entries(action.payload.data.entry)[0];

					let newEntryArray = state.surveysEntries[entryUpdates[0]];

					if(entryUpdates[1].id !== undefined)
					{
						// Remove from the local storage first if NEW_#
						delete state.surveysEntries[entryUpdates[0]];
						newEntryArray.id = entryUpdates[1].id;
						newEntryArray.entry_secret_key = action.payload.data.entry_secret_key;
						state.surveysEntries[entryUpdates[1].id] = newEntryArray;
					}

					newEntryArray.elements.map((element) => 
					{
						element.remedial_work_costs.map((work_costs) => 
						{
							if(entryUpdates[1].elements[work_costs.id] !== undefined)
							{
								work_costs.id = entryUpdates[1].elements[work_costs.id];
							}
						});
					});

					Object.entries(state.repairs).filter((repair) => repair[1].entry === entryUpdates[0]).map((repair) => 
					{
						state.repairs[repair[0]].entry = entryUpdates[1].id;
					});

					if(state.selectedSurveyEntry == entryUpdates[0] && entryUpdates[1].id !== undefined)
					{
						state.selectedSurveyEntry = entryUpdates[1].id;
					}
				}
			}
			catch(e)
			{
				console.error(e.message);
			}
		})
		.addCase(postRepair.fulfilled, (state, action) => {
			let updatedRepairs = state.repairs;

			if (action.payload.data?.repairs) {
				Object.keys(action.payload.data.repairs).map((key) => {
					if (typeof action.payload.repairs !== 'undefined') {
						const repair = Object.values(action.payload.repairs).find((repair) => repair.id === key);
						updatedRepairs[action.payload.data.repairs[key]] = { ...repair, id: action.payload.data.repairs[key] };
						delete updatedRepairs[key];
					}
				});
			} else {
				const repair = action.payload?.repairs?.[0];

				if (repair) {
					updatedRepairs[repair.id] = repair;
				}
			}

			state.repairs = updatedRepairs;
		})
		/*.addCase(deleteSurvey.pending, (state, action) => {
			// Don't go to the api
		})*/
		.addCase(deleteSurvey.fulfilled, (state, action) => {
			// state.surveys = Object.values(state.surveys).map((survey) => survey.id === action.payload ? { ...survey, deleted: 1 } : survey);
			if (state.surveys[action.payload]) {
				state.surveys[action.payload] = { ...state.surveys[action.payload], deleted: 1 };
				Object.keys(state.surveysEntries).forEach(entryKey => {
					if (state.surveysEntries[entryKey].survey === action.payload) {
						Object.keys(state.repairs).forEach(key => {
							if (state.repairs[key].entry === Number(entryKey)) {
								state.repairs[key].deleted = 1;
							}
						});
					}
				});
			}
		})
		.addCase(deleteEntry.fulfilled, (state, action) => {
			if (state.surveysEntries[action.payload]) {
				// state.surveysEntries = Object.values(state.surveysEntries).map((entry) => entry.id === action.payload ? { ...entry, deleted: 1 } : entry);
				state.surveysEntries[action.payload] = { ...state.surveysEntries[action.payload], deleted: 1 };
				Object.keys(state.repairs).forEach(key => {
					if (state.repairs[key].entry === action.payload) {
						state.repairs[key].deleted = 1;
					}
				});
			}
		});
	}
});

export const selectOrganisations = (state) => state.firestop.organisations;
export const selectNewOrganisation = (state) => state.firestop.newOrganisation;
export const getSelectedSurvey = (state) => state.firestop.selectedSurvey;
export const getCurrentSurvey = (state) => state.firestop.surveys[state.firestop.selectedSurvey];
export const getSelectedSurveyEntry = (state) => state.firestop.selectedSurveyEntry;
export const getCurrentSurveyEntry = (state) => state.firestop.surveysEntries[state.firestop.selectedSurveyEntry];

export const selectSurveys = (state) => 
{
	return Object.entries(state.firestop.surveys).reduce(function(surveys, object)
	{
		if(object[1].deleted === 0)
		{
			surveys[object[0]] = object[1]
		}
		return surveys;
	}, {});
}
export const selectEntries = (state) => 
{
	return Object.entries(state.firestop.surveysEntries).reduce(function(entries, object)
	{
		if(object[1].deleted === 0)
		{
			entries[object[0]] = object[1]
		}
		return entries;
	}, {});
}

export const getSelectedElement = (state) => state.firestop.selectedElement;
export const selectSurveysElements = (state) => {
  try {
    return state.firestop.surveys[
      state.firestop.selectedSurvey
    ].elements.filter((element) => element.deleted === 0);
  } catch (err) {
    return [];
  }
};
export const selectSurveysEntriesElements = (state) => {
  try {
    return state.firestop.surveysEntries[
      state.firestop.selectedSurveyEntry
    ].elements.filter((element) => element.deleted === 0);
  } catch (err) {
    return [];
  }
};
export const selectElement = (state, elementId) => {
  try {
    return state.firestop.surveys[state.firestop.selectedSurvey].elements.find(
      (element) => element.id === elementId
    );
  } catch (err) {
    return null;
  }
};
export const selectElementTotalCost = (state) => {
	let cost = 0;
	if (state.firestop.selectedElement?.remedial_work_costs)
	{
		state.firestop.selectedElement.remedial_work_costs.map((workCost) =>
		{
			cost += calculateCost(workCost.quantity, workCost.cost_price, workCost.option_price)
		});
	}

	return (cost/100).toFixed(2);
};
export const selectEntryTotalCost = (state, id) => {
	let cost = 0;
	if (state.firestop.surveysEntries[id]?.elements)
	{
		cost = state.firestop.surveysEntries[id]?.elements.map(
			(element) => {
				let elementCost = 0
				if(element.status === 'failed' && element.remedial_work_costs.length > 0)
				{
					element.remedial_work_costs.filter((workCost) => workCost.deleted === 0).map((workCost) =>
					{
						elementCost += calculateCost(workCost.quantity, workCost.cost_price, workCost.option_price)
					});
				}

				return elementCost;
			}
		).reduce(function(sum, current)
		{
			return current + sum;
		}, 0.00).toFixed(2);
	}

	return (cost/100).toFixed(2);
};
export const selectEntryElementById = (state, id) => {
  return state.firestop.surveysEntries[
    state.firestop.selectedSurveyEntry
  ].elements.find((element) => element.id === id);
};
export const selectRepairs = (state) => Object.keys(state.firestop.repairs)
	.filter(key => state.firestop.repairs[key].deleted === 0)
	.reduce((obj, key) => {
		obj[key] = state.firestop.repairs[key];
		return obj;
	}, {});

export const selectSurveyById = (state, surveyId) => {
	return state.firestop.surveys[surveyId];
};

export const selectEntryById = (state, id) => {
	return state.firestop.surveysEntries[id];
};

export const selectEntryBySurveyId = (state, surveyId) => {
	return Object.values(state.firestop.surveysEntries).filter((entry) => {
		let surveyIdToFilter;
		if (String(surveyId).includes('NEW')) {
			surveyIdToFilter = surveyId;
		} else {
			surveyIdToFilter = Number(surveyId);
		}

		if (entry.survey === surveyIdToFilter && entry.deleted === 0) {
			return entry;
		}
	});
};
export const organisationSurveyHeading = (state) => {
  const surveyId = state.firestop.selectedSurvey;
  const surveyName = state.firestop.surveys[surveyId]?.surveyName;

  let organisationName;
  if (state.firestop.newOrganisation) {
    organisationName = state.firestop.newOrganisation.name;
  } else {
    const surveyOrganisation = state.organisations.organisations.find((org) => org.id === state.firestop.surveys[surveyId]?.organisationName);

    if (surveyOrganisation) {
      organisationName = surveyOrganisation.name;
    } else {
      organisationName = 'Not specified';
    }
  }

  return `${organisationName}: ${surveyName}`;
}

export function elementValidation(element)
{
	// const passMandatory = [element.name, element.location, (element.location != "other" || element.location_other), element.material, (element.material != "other" || element.material_other), element.status, element.photograph_1].every(Boolean);
	const passMandatory = [element.name, element.location, (element.location != "other" || element.location_other), element.status, element.photograph_1].every(Boolean);

	let failMandatory = (element.status == "passed");

	// Don't continue if the fail type hasn't been specified.
	if(!failMandatory && element.fail_type != "")
	{
		switch (element.fail_type)
		{
			case 'hole':

				failMandatory = [element.hole_size, (element.hole_size != "other" || (element.hole_size_other_width && element.hole_size_other_length)), element.services_via_hole].every(Boolean);

			break;

			case 'crack':

				failMandatory = [element.crack_width, element.crack_length, element.crack_depth].every(Boolean);

			break;

			default:

				failMandatory = true;

			break;
		}
	}

	return (passMandatory && failMandatory);
}

export const {
  createOrganisation,
  createOrganisationAddress,
  clearOrganisation,
  saveSurvey,
  resumeSurvey,
  setSurvey,
  createElement,
  saveElement,
  setElement,
  addCostToRemedialWorks,
  removeRemedialWorkCost,
  changeRemedialWorkItemQuantity,
  processSurvey,
  clearSelectedSurvey,
  completeRepair,
	deferRepair,
  startNewSurveyEntry,
  deleteElement,
  updateQuantity,
  clearRemedialWorks,
  calculateCosts,
  duplicateElement
} = firestopSlice.actions;

export default firestopSlice.reducer;
