import { useState } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import Localbase from 'localbase';

import ElementPhotographThumbnail from './ElementPhotographThumbnail';
import { default as Text } from '../../components/AppText';
import Preview from '../../components/Preview';
import Trash from '../../components/Trash';
import Edit from '../../components/Edit';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { globalStyles } from '../../styles/GlobalStyles';
import { colors } from '../../constants/theme';

const db = new Localbase('firestop');

const FloorplanPreview = ({ photographId, image, files, setFiles, setArray }) => {
	const [canEdit, setCanEdit] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [fileName, setFileName] = useState(image.file_name || '');

	const deleteImage = () =>
	{
		//await db.collection('images').doc(image.db_id).delete();
		//delete updatedFiles[photographId];
		const updatedFiles = { ...files, [photographId]: { ...files[photographId], deleted: 1 }};
		setFiles(updatedFiles);
	};

	const handleImageNameChange = (file_name) =>
	{
		const updatedFiles = { ...files, [photographId]: { ...files[photographId], file_name }};

		setFileName(file_name);
		setFiles(updatedFiles);
	}

  return (
    <>
      <View style={styles.filesRow}>
        <View style={{ width: '10%' }}>
          <Button icon={<Trash/>} variant='transparent' style={{ paddingLeft: 0 }} onPress={deleteImage} />
        </View>
        <View style={[ styles.previewThumbnail, { width: '20%' } ]}>
          <ElementPhotographThumbnail photo={image.db_id} collection="images" />
        </View>
        <View style={[styles.buttonsContainer, { width: '15%' }]}>
          {/* <Button icon={<Preview />} variant="transparent" style={{ padding: 0, marginRight: 20 }} onPress={() => updateViewImage((viewImage == index ? null : index))} /> */}
          <Button icon={<Preview />} variant="transparent" style={{ padding: 0, marginRight: 20 }} onPress={() => setShowPreview((prev) => !prev)} />
          <Button icon={<Edit />} variant="transparent" style={{ padding: 0 }} onPress={() => setCanEdit((prev) => !prev)} />
        </View>
        {(setArray ? (
          <View style={{ width: '55%' }}>
            {canEdit ? (
              <TextInput style={[globalStyles.field, { maxWidth: 125 }]} onChangeText={(value) => handleImageNameChange(value)} value={fileName} editable={canEdit} />
            ) : (
              <Text style={{ marginLeft: 10, maxWidth: 125 }}>{fileName}</Text>
            )}
          </View>
        ) : null)}
      </View>
      <Modal showModal={showPreview} setShowModal={() => setShowPreview((prev) => !prev)}>
        <View>
          <View style={{ marginTop: 20 }}>
            <ElementPhotographThumbnail photo={image.db_id} collection="images" size={{ width: 450, height: 300 }} />
          </View>
        </View>
      </Modal>
    </>
  )
}

const styles = StyleSheet.create({
  previewThumbnail: {
    borderRadius: 8,
    backgroundColor: colors.white,
    padding: 5,
    marginRight: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
	filesRow: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
    marginTop: 16,
    maxWidth: 600
	},
	buttonsContainer: {
		flexDirection: 'row',
		marginRight: 16
	}
});

export default FloorplanPreview