import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ClipLoader from "react-spinners/ClipLoader";
import { colors } from '../constants/theme';

function Button({title, size, variant, style, textStyles, icon, onPress, isLoading, disabled, ...props})
{
  return (
    <TouchableOpacity
      style={[
        styles.buttonContainer,
        size === 'sm' && {
          paddingHorizontal: 8,
          paddingVertical: 6,
          elevation: 6,
        },
        variantStyles[variant],
        { ...style },
        disabled && { opacity: 0.5 }
      ]}
      activeOpacity={0.8}
      onPress={onPress}
      disabled={disabled ? disabled : false}
      {...props}
    >
      <Text
        style={[
          styles.buttonText,
          size === 'sm' && { fontSize: 14 },
          { ...textStyles },
        ]}
      >
        {isLoading ? (
          <ClipLoader color="#FFF" loading={isLoading} size={14} />
        ) : (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {icon ? icon : null}
            <Text>{title}</Text>
          </View>
        )}
      </Text>
    </TouchableOpacity>
  );
}

TouchableOpacity.defaultProps = { activeOpacity: 0.8 };

Button.defaultProps = {
  variant: 'black',
  isLoading: false,
};

const variantStyles = {
  black: {
    backgroundColor: colors.black,
  },
  orange: {
    backgroundColor: colors.orange
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  primary: {
    backgroundColor: colors.primary,
  },
  secondary: {
    backgroundColor: colors.secondary,
  },
  outlined: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: colors.white,
  },
};

const styles = StyleSheet.create({
  buttonContainer: {
    elevation: 8,
    backgroundColor: '#009688',
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
	outline: 'none'
},
  buttonText: {
    fontFamily: 'Lato',
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
    alignSelf: 'center',
    textTransform: 'uppercase'
  },
});

export default Button;
