import { useContext } from 'react';
import { View, StyleSheet } from 'react-native';

import { default as Text } from '../../components/AppText';
import Button from '../../components/Button';
import Trash from '../../components/Trash';
import { colors } from '../../constants/theme';
import EditRemedialWorkItemForm from './EditRemedialWorkItemForm';
import FireStopContext from './FireStopContext';

const RemedialWorkItem = ({ item, cost, setElementCosts }) => {
	const { setSelectedElementCost, selectedElementCost } = useContext(FireStopContext);

	const calculationSymbol = cost?.calculation === 2 ? '@' : 'x';
	const calculationSuffix = cost?.calculation === 2 ? 'per m²' : null;

	const handleEdit = () => {
		setSelectedElementCost(item.id);
	};

	const handleDelete = () => {
		setElementCosts((state) => state.map((cost) => {
			if(cost?.id === item.id) {
				return {...cost, deleted: 1};
			}

			return cost;
		}));
	};

	if (!cost) {
		return (
			<>
				<View style={styles.container}>
					<View style={styles.title}>
						<Text>Something has gone wrong. Please delete item and try again!</Text>
					</View>
					<View style={styles.price} />
					<View style={styles.buttons}>
						<Button icon={<Trash />} variant='transparent' onPress={handleDelete} />
					</View>
				</View>
			</>
		)
	}

	return (
		<>
			<View style={styles.container}>
				<View style={styles.title}>
					<Text>{item.cost_title}</Text>
					<Text>{`${item.option_title  + (item.calculation === 3 ? 'ml' : '')}`}</Text>
				</View>
				<View style={styles.price}>
					<Text>{item.quantity} {calculationSymbol} £{Number(item.cost_price + item.option_price).toFixed(2)} {calculationSuffix}</Text>
				</View>
				<View style={styles.buttons}>
					<View style={{ marginRight: 20 }}>
						{(item.calculation !== 2 ? 
							<Button title='Edit' variant='secondary' size="sm" onPress={handleEdit} />
						: null)}
					</View>
					<Button icon={<Trash />} variant='transparent' onPress={handleDelete} />
				</View>
			</View>
			{item.id === selectedElementCost && 
				<EditRemedialWorkItemForm item={item} cost={cost} setElementCosts={setElementCosts} />
			}
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: colors.white,
		paddingHorizontal: 20,
		paddingVertical: 10,
		marginVertical: 5,
	},
	title: {
		width: '40%',
	},
	price: {
		width: '35%',
	},
	buttons: {
		width: '25%',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
});

export default RemedialWorkItem;
