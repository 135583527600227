import { useState } from 'react';
import { ScrollView, StyleSheet, TextInput, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { default as Text } from '../components/AppText';
import { colors, padding } from '../constants/theme';
import Layout from '../containers/Layout';
import Button from '../components/Button';
import { FireStopAPI } from '../services/api';

const HelpScreen = () => {
    const [message, setMessage] = useState('');

    const handleReport = async () => {
        try {
            const data = await AsyncStorage.getItem('persist:root');

            let splitData = [];

            if (data.length > 10000) {
                splitData = data.match(/.{1,10000}/g);
            } else {
                splitData.push(data);
            }

			const response = await FireStopAPI.post('/v1/index.php', { data: { message }, action: 'reports' });

			if (response) {
				splitData.forEach(async (data, i) => {
					await FireStopAPI.post('/v1/index.php', {
						data: { data: JSON.stringify(data), section: i, report_id: response.data.report_id },
						action: 'reports_logs',
					});
				});
			}
        } catch (error) {
			console.log(error);
		}
    };

    return (
        <Layout>
            <ScrollView style={styles.container}>
                <View>
                    <View style={styles.heading}>
                        <Text variant='bold' size='xl'>
                            Help & Support
                        </Text>
                    </View>
                    <View style={styles.subHeading}>
                        <Text>
                            If you are experiencing a problem with this
                            application, please click on the 'Report a Fault'
                            button below. We'll be in touch to investigate the
                            issue
                        </Text>
                    </View>
                    <View style={styles.inputContainer}>
                        <TextInput
                            style={styles.field}
                            multiline
                            value={message}
                            onChangeText={(value) => setMessage(value)}
                        />
                    </View>
                    <View style={styles.buttonContainer}>
                        <Button title='Report a Fault' onPress={handleReport} />
                    </View>
                </View>
            </ScrollView>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingTop: padding.xxl,
        paddingBottom: padding.xl,
    },
    heading: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    subHeading: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 16,
    },
    inputContainer: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 600,
        marginTop: 32,
    },
    field: {
        backgroundColor: colors.white,
        minHeight: 100,
    },
    buttonContainer: {
        marginTop: 32,
    },
});

export default HelpScreen;
