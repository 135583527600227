import {
  StyleSheet,
  Platform,
  StatusBar,
  SafeAreaView,
  View,
} from 'react-native';

import Header from '../components/Header';
import { colors } from '../constants/theme';

function Layout({ children, title }) {
  return (
    <SafeAreaView style={styles.wrapper}>
      <Header title={title} />
      <View style={styles.container}>{children}</View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  container: {
    flex: 1,
    backgroundColor: colors.lightGrey,
    paddingHorizontal: 24,
  },
});

export default Layout;
