import { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { useDispatch } from 'react-redux';

import { default as Text } from '../../components/AppText';
import Button from '../../components/Button';
import { colors, radius } from '../../constants/theme';
import { globalStyles } from '../../styles/GlobalStyles';
import { optionAdded } from './costsSlice';

import OptionsContext from './OptionsContext';
import { isNumeric, sanitizeNumericString } from '../../functions/utils';

const EditOptionForm = ({ option, setSelectedOption, selectedCost }) => {
    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const [titleError, setTitleError] = useState(null);
    const [priceError, setPriceError] = useState(null);

    const { setShowSortButton } = useContext(OptionsContext);

    const canSave =
        [title, price].every(Boolean) && addRequestStatus === 'idle';

    const isMastic = selectedCost?.calculation === 3 ? true : false;

    const handlePriceChange = (value) => {
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(value) || value === '') {
            setPrice(value);
        }
    };

    const handleSave = () => {
        if (canSave) {
            try {
                setAddRequestStatus('loading');

                let formattedTitle = title;

                if (isMastic) {
                    if (!isNumeric(title)) {
                        formattedTitle = sanitizeNumericString(title);
                    }
                }

                dispatch(
                    optionAdded({
                        id: option.id,
                        title: formattedTitle,
                        price: Number(price),
                    })
                );

                setTitle('');
                setPrice('');

                setTitleError(null);
                setPriceError(null);
            } catch (err) {
                console.error('Could not save option ', err);
            } finally {
                setSelectedOption(null);
                setShowSortButton(true);
            }
        } else {
            if (!title) {
                setTitleError('Required');
            }

            if (!price) {
                setPriceError('Required');
            }
        }
    };

    const handleClose = () => {
        setSelectedOption(null);
        setShowSortButton(true);
    };

    useEffect(() => {
        if (option) {
            setTitle(option.title);
            setPrice(option.price);
        }
    }, [option]);

    return (
        <View style={styles.container}>
            <View style={[globalStyles.fieldContainer, { marginTop: 0 }]}>
                <Text style={globalStyles.label}>
                    {!isMastic ? 'Option Title' : 'Tube Size (ml)'}
                    <Text style={styles.required}>*</Text>
                </Text>
                <View style={{ flex: 1 }}>
                    <TextInput
                        style={[globalStyles.field, { width: 300 }]}
                        value={title}
                        onChangeText={(value) => setTitle(value)}
                    />
                    {titleError ? (
                        <Text style={globalStyles.error}>{titleError}</Text>
                    ) : null}
                </View>
            </View>
            <View style={globalStyles.fieldContainer}>
                <Text style={globalStyles.label}>
                    Price (£) <Text style={styles.required}>*</Text>
                </Text>
                <View>
                    <TextInput
                        style={[globalStyles.field, { width: 100 }]}
                        value={price}
                        onChangeText={handlePriceChange}
                        keyboardType='numeric'
                    />
                    {priceError ? (
                        <Text style={globalStyles.error}>{priceError}</Text>
                    ) : null}
                </View>
            </View>
            <View style={globalStyles.fieldContainer}>
                <Button
                    title='Save'
                    variant='secondary'
                    onPress={handleSave}
                    style={{ marginRight: 20 }}
                />
                <Button title='Close' onPress={handleClose} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 40,
        paddingVertical: 16,
        marginTop: 10,
        backgroundColor: colors.darkGrey,
        borderRadius: radius.md,
    },
    label: {
        width: 100,
    },
    fieldContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 20,
    },
    fieldInput: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    required: {
        color: colors.primary,
    },
});

export default EditOptionForm;
