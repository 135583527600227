import { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';

import { default as Text } from '../../components/AppText';
import Button from '../../components/Button';
import { colors } from '../../constants/theme';
import AddressForm from './AddressForm';
import { removeAddress } from './organisationsSlice';
import Modal from '../../components/Modal';
import Trash from '../../components/Trash';

const AddressItem = ({ address, showEditAddress, setShowEditAddress }) => {
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleView = () => {
    if (showEditAddress) {
      setShowEditAddress(null);
    } else {
      setShowEditAddress(address.id);
    }
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  }
  
  const handleConfirmDelete = () => {
    dispatch(removeAddress({ id: address.id }));
    setConfirmDelete(false);
  }

  const handleConfirmCancel = () => {
    setConfirmDelete(false);
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.title}>
          <Text>{address.label}</Text>
        </View>
        <View style={styles.buttons}>
          <Button
            icon={<Trash />}
            variant="transparent"
            onPress={handleDelete}
            style={{ marginRight: 20 }}
          />
          <Button title='Edit' variant='secondary' size='sm' onPress={handleView} />
        </View>
      </View>
      {showEditAddress === address.id ? (
        <AddressForm selectedAddress={address} setShowEditAddress={setShowEditAddress} />
      ) : null}
      <Modal type="confirmation" showModal={confirmDelete} onConfirmation={handleConfirmDelete} onCancel={handleConfirmCancel}>
        <Text>Are you sure you want to delete?</Text>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.darkGrey,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginVertical: 5,
  },
  title: {
    width: '70%',
  },
  buttons: {
    width: '30%',
    flexDirection: 'row',
    alignItems: 'center'
  },
});

export default AddressItem;
