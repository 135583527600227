import { useNavigation } from '@react-navigation/native';
import { ScrollView, View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import { padding } from '../constants/theme';
import Layout from '../containers/Layout';
import { signOut } from '../features/auth/authSlice';

const MenuScreen = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const handleSignOut = () => {
        dispatch(signOut());
    };

    return (
        <Layout>
            <ScrollView style={styles.container}>
                {/* <View style={styles.buttonContainer}>
                    <Button
                        title='Help'
                        variant='black'
                        onPress={() => navigation.navigate('Help')}
                    />
                </View> */}
                <View style={styles.buttonContainer}>
                    <Button
                        title='Sign Out'
                        variant='secondary'
                        onPress={handleSignOut}
                    />
                </View>
                <View style={styles.versionContainer}>
                    <Text variant='bold'>Version 1.0.0</Text>
                </View>
            </ScrollView>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingTop: padding.xxl,
        paddingBottom: padding.xl,
    },
    buttonContainer: {
        marginTop: 20,
    },
    versionContainer: {
        marginTop: 8,
        flexDirection: 'row',
        justifyContent: 'center',
    },
});

export default MenuScreen;
