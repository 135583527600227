import { useEffect, useState } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { colors, radius } from '../../constants/theme';
import { globalStyles } from '../../styles/GlobalStyles';
import { default as Text } from '../../components/AppText';
import { addAddressToOrganisation, selectOrganisations } from '../organisations/organisationsSlice';
import {
  createOrganisationAddress,
  selectNewOrganisation,
} from './firestopSlice';

const NewAddressForm = ({ organisationId, setOrganisationAddress, error }) => {
  const dispatch = useDispatch();
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const newOrganisation = useSelector(selectNewOrganisation);
  const [address, setAddress] = useState(newOrganisation?.addresses[0]?.label || '');
  const organisations = useSelector(selectOrganisations);

  const canEdit = newOrganisation || organisationId;

  const handleBlur = () => {
    dispatch(createOrganisationAddress({ id: organisationId, name: selectedOrganisation?.name, numberOfAddresses: selectedOrganisation?.addresses.length, address }));
    dispatch(addAddressToOrganisation({ organisationId, address }));
  };

  const handleChangeText = (value) => {
    if (!organisationId) {
      setOrganisationAddress(value);
    }
    setAddress(value);
  }

  useEffect(() => {
    if (organisations) {
      const selectedOrganisation = organisations.find((organisation) => organisation.id === organisationId);

      if (selectedOrganisation) {
        setSelectedOrganisation(selectedOrganisation);
      }
    }
  }, [organisations]);

  return (
    <View>
      <TextInput
        placeholder='New Address'
        placeholderTextColor={colors.placeholder}
        style={[globalStyles.field, styles.field, { marginTop: 2 }]}
        onChangeText={handleChangeText}
        onBlur={handleBlur}
        value={address}
        editable={canEdit}
      />
      {error ? (
        <Text style={globalStyles.error}>Required</Text>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  field: {
    marginTop: 20,
    backgroundColor: 'white',
    flex: 1,
    borderRadius: radius.sm,
    paddingHorizontal: 10,
    paddingVertical: 15,
  }
});

export default NewAddressForm;
