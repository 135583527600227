import { StyleSheet, View } from 'react-native';

import CostItem from './CostItem';

function CostList({ costs }) {
  return (
    <View style={styles.costsList}>
      {costs.map((cost) => (
        <CostItem key={cost.id} item={cost} />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  costsList: {
    marginTop: 32,
  },
});

export default CostList;
