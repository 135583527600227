import { eachMonthOfInterval, eachYearOfInterval, addDays, addMonths, addYears } from 'date-fns';

export const calculateHole = (width, length) => {
  return Math.ceil(Number((parseInt(width) * parseInt(length)) / 1000000) * 100) / 100;
};

export const calculateCrack = (width, length, depth) => {
  // Dimensions in mm.  1 litre = 0.001 cubic meters.  With the changes in mm and the multiplication, it is the same as dividing by a million (1000 * 1000 * 1000) / 1000
  
	/**
	 * 	Crack result is calculated in Cubic meters using the following formula. We are multiplying, using ceil and dividing by 100 to round up the number.

			From cm: length (cm) × width (cm) × height (cm) ÷ 1000000

			We are displaying the measurements as mm, not cm. This will need correcting.

			A calculator can be seen her:

			https://www.thecalculatorsite.com/misc/cubic-meters-calculator.php
	 */
	
	// return Math.ceil(Number((parseInt(width) * parseInt(length) * parseInt(depth)) / 1000000) * 100) / 100;
	return Math.ceil(Number(width) * Number(length) * Number(depth));
};

export const calculateQuantity = (costs, dimensions) =>
{
	return [...costs.map((work) => {
		let calculatedSize;
		switch(work.calculation) {
			case 2:

				calculatedSize = calculateHole(dimensions[0], dimensions[1]);

			break;

			default:

				calculatedSize = work.quantity;

			break;
		}

		return {...work, quantity: calculatedSize};
	})];
}

export const calculateCost = (quantity, cost_price, option_price, pence) => {
	// Remove decimal points from the numbers as *100 will give a floating point error.
	cost_price = parseFloat(cost_price.toFixed(2).replace(".",""));
	option_price = parseFloat(option_price.toFixed(2).replace(".",""));

	let item_cost = Number(Math.ceil(quantity * (cost_price + option_price)));

	if(item_cost <= 0 && quantity)
	{
		item_cost = 1;
	}

	if(!pence && pence != undefined)
	{
		item_cost /= 100;
	}

	return item_cost;
};

export const calculateDeadline = (s, l, f, a) =>
{
	let deadline = null;

	if(!!s && !!l)
	{
		let start = new Date(s);
		let last = new Date(l);

		switch(f)
		{
			case 'month':

				try {
					let Interval_Month = eachMonthOfInterval({
						start: start,
						end: last
					}).length;
	
					deadline = addMonths(start, Interval_Month);
	
					if(!!a)
					{
						deadline = addDays(deadline, -7);
					}
				} catch (error) {
					console.log(error);
				}

			break;

			case 'year':
			default:

				try {
					let Interval_Year = eachYearOfInterval({
						start: start,
						end: last
					}).length;
	
					deadline = addYears(start, Interval_Year);
	
					if(!!a)
					{
						deadline = addDays(deadline, -28);
					}
				} catch (error) {
					console.log(error);
				}

			break;
		}
	}

	return deadline;
}