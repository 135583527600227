import { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { default as Text } from '../../components/AppText';
import Button from '../../components/Button';
import { colors } from '../../constants/theme';
import { deleteOrganisation, setSelectedOrganisation } from './organisationsSlice';
import Modal from '../../components/Modal';
import Trash from '../../components/Trash';

const OrganisationItem = ({ organisation }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { isLoading } = useSelector((state) => state.organisations)

  const handleView = () => {
    dispatch(setSelectedOrganisation(organisation.id));
    navigation.navigate('OrganisationDetails');
  };
  
  const handleDelete = () => {
    setConfirmDelete(true);
  }
  
  const handleConfirmDelete = () => {
    dispatch(deleteOrganisation(organisation.id))
      .unwrap()
      .then((res) => {
        setConfirmDelete(false);
      })
      .catch((error) => console.error(error));
  }

  const handleConfirmCancel = () => {
    setConfirmDelete(false);
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.title}>
          <Text>{organisation.name}</Text>
        </View>
        <View style={styles.buttons}>
          <Button
            icon={<Trash />}
            variant="transparent"
            onPress={handleDelete}
            style={{ marginRight: 20 }}
          />
          <Button
            title='Edit'
            variant='secondary'
            size='sm'
            onPress={handleView}
          />
        </View>
      </View>
      <Modal type="confirmation" showModal={confirmDelete} onConfirmation={handleConfirmDelete} onCancel={handleConfirmCancel} isLoading={isLoading}>
        <Text>Are you sure you want to delete?</Text>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingHorizontal: 20,
    paddingVertical: 8,
    marginVertical: 5,
  },
  title: {
    width: '70%',
  },
  buttons: {
    width: '30%',
    flexDirection: 'row',
    alignItems: 'center'
  },
});

export default OrganisationItem;
