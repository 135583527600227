import { useState, useContext } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { useDispatch } from 'react-redux';

import { default as Text } from '../../components/AppText';
import { globalStyles } from '../../styles/GlobalStyles';
import { colors, radius } from '../../constants/theme';
import Button from '../../components/Button';
import FireStopContext from './FireStopContext';

const EditRemedialWorkItemForm = ({ item, cost, setElementCosts }) => {
	const dispatch = useDispatch();
	const { setSelectedElementCost } = useContext(FireStopContext);

	const [quantity, setQuantity] = useState(item.quantity);

	const handleSave = () => {

		setElementCosts((state) => state.map((cost) => {
			if(cost.id === item.id)
			{
				return {...cost, quantity};
			}

			return cost;
		}));

		setSelectedElementCost(null);
	};

	const handleClose = () => {
		setSelectedElementCost(null);
	};

	return (
		<View style={styles.container}>
			{(cost.calculation === 2 ? <View style={globalStyles.fieldContainer}>
				<Text style={globalStyles.label}>Calculated</Text>
				<Text style={styles.field}>{quantity}</Text>
			</View> : <View style={globalStyles.fieldContainer}>
				<Text style={globalStyles.label}>Quantity</Text>
				<TextInput style={styles.field} onChangeText={(value) => setQuantity(value)} value={quantity} />
			</View>)}
			<View style={styles.buttonContainer}>
				<Button title='Save' variant='secondary' onPress={handleSave} style={{ marginRight: 60 }} />
				<Button title='Close' onPress={handleClose} />
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingHorizontal: 40,
		paddingVertical: 16,
		marginTop: 20,
		backgroundColor: colors.darkGrey,
		borderRadius: radius.md,
	},
	field: {
		backgroundColor: 'white',
		borderRadius: radius.sm,
		paddingHorizontal: 10,
		paddingVertical: 15,
		width: 100,
		height: 40,
	},
	buttonContainer: {
		flexDirection: 'row',
		marginTop: 20,
	},
});

export default EditRemedialWorkItemForm;