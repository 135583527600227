import Svg, { Path } from 'react-native-svg';

import { colors } from '../constants/theme';

const Exclamation = (props) => (
  <Svg
    height={160}
    width={160}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    fill={colors.error}
    {...props}
  >
    <Path
      d="M506.3 417 293 53c-8.2-14-22.6-21-37-21-14.4 0-28.8 7-37.9 21L4.9 417c-15.49 27.9 4.951 63 37.84 63h426.6c32.76 0 53.26-35 36.96-63zM52.58 432 255.1 84.8 459.4 432H52.58zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44s14.11 31.44 31.48 31.44 31.4-14.08 31.4-31.44C287.4 351.2 273.4 337.1 256 337.1zM232 184v96c0 13.3 10.8 24 24 24s24-10.75 24-24v-96c0-13.2-10.7-24-24-24s-24 10.8-24 24z"
      path={colors.error}
    />
  </Svg>
);
export default Exclamation;
