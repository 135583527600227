import { ScrollView, StyleSheet, View } from 'react-native';

import { default as Text } from '../components/AppText';
import { padding } from '../constants/theme';
import Layout from '../containers/Layout';

const PhotoManagementScreen = () => {
  return (
    <Layout>
      <ScrollView style={styles.container}>
        <Text>PhotoManagementScreen</Text>
      </ScrollView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  buttonContainer: {
    marginTop: 20,
  },
});

export default PhotoManagementScreen;
