import { ScrollView, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute  } from '@react-navigation/native';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import { padding } from '../constants/theme';
import Layout from '../containers/Layout';
import ElementListItem from '../features/firestop/ElementListItem';
import { getCurrentSurveyEntry, clearSelectedSurvey, createElement, selectSurveysElements, elementValidation, organisationSurveyHeading, getCurrentSurvey } from '../features/firestop/firestopSlice';
import FirstVisitSurveyEntryQuestions from '../features/firestop/FirstVisitSurveyEntryQuestions';

const FireStopElementsScreen = () => {
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const route = useRoute();
	const elements = useSelector(selectSurveysElements);
	const survey = useSelector(getCurrentSurvey);
	const entry = useSelector(getCurrentSurveyEntry);

	let pageTitle = useSelector(organisationSurveyHeading);
	const isFirstVisit = route.params?.type === 'first_visit';
	const hasElements = route.params?.has_elements === 'yes';
	const numOfRepairs = route.params?.num_of_repairs;
	const surveyId = route.params?.survey;
	
	let surveyComplete = true;
	
	const renderedElements = elements
	.filter((element) => element.deleted === 0)
	.map((element) => {
		const entryElement = entry?.elements.filter((entryElement) => entryElement.id === element.id)[0];
		const isComplete = elementValidation({...element, ...entryElement});
		surveyComplete = surveyComplete && isComplete;

		return <ElementListItem key={element.id} element={element} entryElement={{...entryElement, "isComplete": isComplete}} />
	});

	const handleAddElement = () => {
		dispatch(createElement());
		navigation.navigate('FireStopElementsFormEdit');
	};

	const handleSaveAndClose = () => {
		dispatch(clearSelectedSurvey());
		navigation.navigate('FireStopDashboard');
	};

	let renderContent;

	if (isFirstVisit && hasElements) {
		pageTitle = 'New Survey Questions';

		renderContent = <FirstVisitSurveyEntryQuestions surveyId={surveyId} numOfRepairs={numOfRepairs} />
	} else {
		renderContent = <>
			{/* Elements Header */}
			<View>
				<View style={styles.headerContainer}>
					<Text variant='heading' size='xl'>Survey Summary</Text>
					<Button title='Edit Details' onPress={() => navigation.navigate('FireStopSurveyForm')} />
				</View>
				<View style={{ marginTop: 8 }}>
					<Text>
						Once you have completed each breach click the process survey button
						at the bottom of the page.
					</Text>
				</View>
			</View>
			{/* Element Buttons */}
			<View style={styles.topButtonContainer}>
				<Button title='Add Breach' variant='secondary' onPress={handleAddElement} />
			</View>
			{/* Elements List */}
			<View style={styles.listContainer}>
				{!!elements.length ? (
					<View>{renderedElements}</View>
				) : (
					<Text style={styles.intro}>You have no breaches. Please press 'Add Breach' to add your first breach</Text>
				)}
			</View>
			{/* Process Survey */}
			<View style={styles.bottomButtonContainer}>
				<Button title='Save & Close Survey' style={{ marginRight: 20 }} onPress={handleSaveAndClose} />
				{(survey?.surveyPassFailType && elements.length > 0) || !survey?.surveyPassFailType ? (
					<Button title='Process Survey' disabled={!surveyComplete} variant='secondary' onPress={() => navigation.navigate('FireStopSummary')} />
				) : null}
			</View>
		</>
	}

	return (
		<Layout title={pageTitle}>
			<ScrollView style={styles.container}>
				{renderContent}
			</ScrollView>
		</Layout>
	);
};

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
  topButtonContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  listContainer: {
    marginTop: 24,
  },
  bottomButtonContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  intro: {
    textAlign: 'center',
  },
});

export default FireStopElementsScreen;
