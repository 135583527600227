import React from 'react';
import { StyleSheet, View } from 'react-native';

import { padding } from '../constants/theme';
import { default as Text } from './AppText';
import Layout from '../containers/Layout';

const ErrorFallback = ({ error, resetError }) => {
    return (
        <Layout>
            <View style={styles.container}>
                <Text variant='heading' size='xl'>
                    Something went wrong:
                </Text>
                <Text>{error.toString()}</Text>
            </View>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingTop: padding.xxl,
        paddingBottom: padding.xl,
    },
});

export default ErrorFallback;
