import { useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import Localbase from 'localbase';

import Button from '../../components/Button';
import { colors } from '../../constants/theme';

const db = new Localbase('firestop');

const ElementPhotograph = ({ name, collection, photographs, setPhotograph, setArray, onFinish, buttonLabel = 'Choose a file', buttonIcon, buttonVariant = 'secondary' }) => {
	const [status, setStatus] = useState('idle');
	const [error, setError] = useState(null);

	const fileTypes = ["image/jpeg","image/jpg","image/gif","image/png"];

	const handleUpload = async () => {
		try
		{
			setError(null);

			const result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.Images,
				allowsEditing: true,
				aspect: [4, 3],
				quality: 1,
			});

			if (result.canceled)
			{
				throw new Error("Cancelled");
			}

			if (status === 'idle') {
				setStatus('loading');

				let [, mimeType] = result.uri.split(";")[0].split(":");

				if(fileTypes.find((type) => type==mimeType) === undefined)
				{
					throw new Error("Incompatible File Type");
				}

				const [photo] = result.assets;
				let resize = {};

				if (photo.width >= photo.height) {
					resize = { width: 1000 };
				} else {
					resize = { height: 1000 };
				}

				const compressedPhoto = await manipulateAsync(photo.uri, [{ resize }], {
					compress: 0.8,
					format: SaveFormat.JPEG,
				});

				db.collection(collection)
				.add({
					photograph_type: name,
					photograph_uri: compressedPhoto.uri,
					width: compressedPhoto.width,
					height: compressedPhoto.height
				})
				.then((res) => {
					let newImage = { id: res.data.key, photograph_type: name };
					let result = newImage;

					if(setArray)
					{
						result = photographs;
						result.push(newImage);
					}

					setPhotograph(result);
					setStatus('idle');
					if (typeof onFinish === 'function') {
						onFinish();
					}
				});
			}
		}
		catch(e)
		{
			setStatus('idle');
			setError(e.message);
		}
	};

	return (
		<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
			<Button title={status === 'idle' ? buttonLabel : <ActivityIndicator color='#FFFFFF' />} icon={buttonIcon} variant={buttonVariant} size='sm' onPress={handleUpload} disabled={status !== 'idle'} />
			{(error && <Text style={{ marginTop: 10, color: colors.error }}>{error}</Text>)}
		</View>
	);
};

export default ElementPhotograph;
