import { useState, useEffect, useCallback, useRef } from "react";
import DropDownPicker from 'react-native-dropdown-picker';
import { Text, TextInput, View, StyleSheet, TouchableHighlight } from 'react-native';
import Svg, { Path } from 'react-native-svg';

export const CONST_MONTHS = 
{
	1: { label: "January", days: 31, leapyear: 0 },
	2: { label: "February", days: 28, leapyear: 1 },
	3: { label: "March", days: 31, leapyear: 0 },
	4: { label: "April", days: 30, leapyear: 0 },
	5: { label: "May", days: 31, leapyear: 0 },
	6: { label: "June", days: 30, leapyear: 0 },
	7: { label: "July", days: 31, leapyear: 0 },
	8: { label: "August", days: 31, leapyear: 0 },
	9: { label: "September", days: 30, leapyear: 0 },
	10: { label: "October", days: 31, leapyear: 0 },
	11: { label: "November", days: 30, leapyear: 0 },
	12: { label: "December", days: 31, leapyear: 0 },
}

function padNumber(num, size)
{
	num = num.toString();
	while (num.length < size) num = "0" + num;
	return num;
}

export function CurrentDateTime(format, date)
{
	let current_date = (date != null ? new Date(date) : new Date());

	if(format == null)
	{
		format = "Y-m-dTH:i:s";
	}

	let Object_DateVariables = 
	{
		"Y": current_date.getFullYear(),
		"m": padNumber(current_date.getMonth()+1, 2),
		"d": padNumber(current_date.getDate(), 2),
		"H": padNumber(current_date.getHours(), 2),
		"i": padNumber(current_date.getMinutes(), 2),
		"s": padNumber(current_date.getSeconds(), 2),
	}

	if(format == "object")
	{
		format = Object_DateVariables;
	}
	else
	{
		Object.entries(Object_DateVariables).map((index) => (
			format = format.replaceAll(index[0],index[1])
		));
	}

	return format;
};

export const DatePicker = (props) =>
{
	const currentTime = CurrentDateTime("object");
	const valueTime = CurrentDateTime("object", (!!props.value ? props.value: null));
	const [pickerYear, setYear] = useState(valueTime.Y);
	const [pickerMonth, setMonth] = useState(valueTime.m);
	const [pickerDay, setDay] = useState(valueTime.d);
	const inputReference = useRef();

	const OpenIcon = (props) => (
		<Svg height={1200} width={1200} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 1200' fill="#ffffff" {...props}>
			<Path d="m735.36 835.36c19.523-19.527 19.523-51.184 0-70.711-19.527-19.523-51.184-19.523-70.711 0l-14.645 14.645v-179.29c0-27.613-22.387-50-50-50s-50 22.387-50 50v179.29l-14.645-14.645c-19.527-19.523-51.184-19.523-70.711 0-19.527 19.527-19.527 51.184 0 70.711l100 100c9.375 9.375 22.094 14.645 35.355 14.645s25.98-5.2695 35.355-14.645z"/>
			<Path d="m350 100c27.613 0 50 22.387 50 50v50h400v-50c0-27.613 22.387-50 50-50s50 22.387 50 50v50h50c82.844 0 150 67.156 150 150v600c0 82.844-67.156 150-150 150h-700c-82.844 0-150-67.156-150-150v-600c0-82.844 67.156-150 150-150h50v-50c0-27.613 22.387-50 50-50zm-100 200c-27.613 0-50 22.387-50 50v50h800v-50c0-27.613-22.387-50-50-50zm750 200h-800v450c0 27.613 22.387 50 50 50h700c27.613 0 50-22.387 50-50z" fillRule="evenodd"/>
		</Svg>
	);

	const CloseIcon = (props) => (
		<Svg height={1200} width={1200} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 1200' fill="#ffffff" {...props}>
			<Path d="m735.36 664.64c19.523 19.527 19.523 51.184 0 70.711-19.527 19.523-51.184 19.523-70.711 0l-14.645-14.645v179.29c0 27.613-22.387 50-50 50s-50-22.387-50-50v-179.29l-14.645 14.645c-19.527 19.523-51.184 19.523-70.711 0-19.527-19.527-19.527-51.184 0-70.711l100-100c9.375-9.375 22.094-14.645 35.355-14.645s25.98 5.2695 35.355 14.645z"/>
			<Path d="m350 100c27.613 0 50 22.387 50 50v50h400v-50c0-27.613 22.387-50 50-50s50 22.387 50 50v50h50c82.844 0 150 67.156 150 150v600c0 82.844-67.156 150-150 150h-700c-82.844 0-150-67.156-150-150v-600c0-82.844 67.156-150 150-150h50v-50c0-27.613 22.387-50 50-50zm-100 200c-27.613 0-50 22.387-50 50v50h800v-50c0-27.613-22.387-50-50-50zm750 200h-800v450c0 27.613 22.387 50 50 50h700c27.613 0 50-22.387 50-50z" fillRule="evenodd"/>
		</Svg>
	);

	// Display may use slashes in the date which will be read in an American format (m/d/Y).  By separating this out, the display can look however it wants without affecting any save routines.
	const inputFormat = props?.inputFormat || "d-m-Y";
	const displayFormat = props?.displayFormat || "d/m/Y";

	const [dayOpen, setDayOpen] = useState(false);
	const [monthOpen, setMonthOpen] = useState(false);
	const [yearOpen, setYearOpen] = useState(false);

	const [showPicker, togglePicker] = useState(false);

	// Possible update to only show from a specific date.  eg 25-12-2022
	let startYear = (!!props.start ? props.start : 1970);
	let endYear = (!!props.end ? props.end : currentTime.Y);

	let dayList = [];

	for(let i = 1; i <= CONST_MONTHS[parseInt(pickerMonth)].days + (CONST_MONTHS[parseInt(pickerMonth)].leapyear && pickerYear % 4 === 0 ? 1 : 0); i++)
	{
		dayList.push({ "label": i, "value": padNumber(i, 2) });
	}

	let monthList = [];

	for(let i = 1; i <= 12; i++)
	{
		monthList.push({ "label": CONST_MONTHS[i].label, "value": padNumber(i, 2) });
	}

	let yearList = [];
	
	for(let i = startYear; i <= endYear; i++)
	{
		yearList.push({ "label": i, "value": i });
	}

	yearList.reverse();

	// pickerYear is added here for the february leapyear case
	useEffect(() => {
		if(dayList.length < pickerDay)
		{
			setDay(padNumber(1, 2));
		}
	}, [pickerYear,pickerMonth]);

	useEffect(() => {
		props.setValue(pickerYear + "-" + pickerMonth + "-" + pickerDay);
	}, [pickerYear,pickerMonth,pickerDay]);

	const onDropdownOpen = useCallback((selected) => {
		if(selected !== "day")
		{
			setDayOpen(false);
		}
		if(selected !== "month")
		{
			setMonthOpen(false);
		}
		if(selected !== "year")
		{
			setYearOpen(false);
		}
	}, []);

	return(
		<View style={{alignItems: 'right', zIndex: 10000}}>
			<View ref={inputReference} style={{ ...styles.container, width: 200 }}>
				<TextInput style={{...props.style, display: 'none'}} editable={false} name={props.name} value={CurrentDateTime(inputFormat, pickerYear + "-" + pickerMonth + "-" + pickerDay)} />
				<Text style={{...props.style}}>{CurrentDateTime(displayFormat, pickerYear + "-" + pickerMonth + "-" + pickerDay)}</Text>
				<TouchableHighlight style={{width: 34, marginLeft: 5, padding: 2, backgroundColor: (showPicker ? "#333333" : "#2196F3")}} onPress={() => togglePicker(!showPicker)}>
					{showPicker ? <CloseIcon style={{width: 30, height: 30}} /> : <OpenIcon style={{width: 30, height: 30}} /> }
				</TouchableHighlight>
			</View>
			{(showPicker && 
				<View style={{...styles.container, ...styles.picker, top: inputReference.current.getBoundingClientRect().height }}>
					<View style={{width: '20%'}}>
						<DropDownPicker placeholder='Day' dropDownDirection="BOTTOM" open={dayOpen} setOpen={setDayOpen} onOpen={() => onDropdownOpen("day")} value={pickerDay} setValue={setDay} items={dayList} style={styles.dropdown} dropDownContainerStyle={styles.dropdownItem} />
					</View>
					<View style={{width: '50%', marginLeft: 5}}>
						<DropDownPicker placeholder='Month' dropDownDirection="BOTTOM" open={monthOpen} setOpen={setMonthOpen} onOpen={() => onDropdownOpen("month")} value={pickerMonth} setValue={setMonth} items={monthList} style={styles.dropdown} dropDownContainerStyle={styles.dropdownItem} />
					</View>
					<View style={{width: '25%', marginLeft: 5}}>
						<DropDownPicker placeholder='Year' dropDownDirection="BOTTOM" open={yearOpen} setOpen={setYearOpen} onOpen={() => onDropdownOpen("year")} value={pickerYear} setValue={setYear} items={yearList} style={styles.dropdown} dropDownContainerStyle={styles.dropdownItem} />
					</View>
				</View>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-around'
	},
	dropdown: {
		width: '100%',
		borderWidth: 1,
		broderColor: '#333333',
		borderRadius: 0,
		minHeight: 40,
		paddingHorizontal: 3,
		paddingLeft: 10,
	},
	dropdownItem: {
		borderWidth: 1,
	},
	picker: {
		width: 350, 
		right: 0, 
		position: 'absolute', 
		padding: 5,
		backgroundColor: '#FFFFFF'
	}
});