import { createContext, useState } from 'react';

const FireStopContext = createContext(null);

export const FireStopProvider = ({ children }) => {
  const [showAddCostForm, setShowAddCostForm] = useState(false);
  const [selectedElementCost, setSelectedElementCost] = useState(null);

  return (
    <FireStopContext.Provider
      value={{
        showAddCostForm,
        setShowAddCostForm,
        selectedElementCost,
        setSelectedElementCost,
      }}
    >
      {children}
    </FireStopContext.Provider>
  );
};

export default FireStopContext;
