import { StyleSheet, View, useWindowDimensions } from 'react-native';
import RNModal from 'react-native-modal';

import Button from './Button';

const Modal = ({ children, type = 'alert', isLoading = false, onConfirmation, onCancel, showModal, setShowModal, containerStyle = {} }) => {
	let renderedButtons;
	const {height:deviceHeight, width:deviceWidth} = useWindowDimensions();

	if (type === 'alert') {
		renderedButtons = <Button title='Close' onPress={setShowModal} />
	} else if (type === 'confirmation') {
		renderedButtons = (
			<>
				<Button title='Yes' variant="primary" onPress={onConfirmation} isLoading={isLoading} style={{ marginRight: 20 }} />
				<Button title='Cancel' onPress={onCancel} />
			</>
		);
	}

	return (
		<RNModal isVisible={showModal} style={{ justifyContent: 'center', margin: 0 }} deviceWidth={deviceWidth} deviceHeight={deviceHeight}>
			<View style={[styles.container, { ...containerStyle }]}>
				<View style={styles.innerContainer}>{children}</View>
				<View style={styles.buttonContainer}>{renderedButtons}</View>
			</View>
		</RNModal>
	);
};

const styles = StyleSheet.create({
	container: {
		marginHorizontal: 'auto',
		maxWidth: 520,
		width: '90%',
		backgroundColor: '#F4F4F4',
		paddingHorizontal: 20,
		paddingVertical: 40,
	},
	innerContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		marginTop: 20,
	},
});

export default Modal;
