import { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { useSelector } from 'react-redux';
import DropDownPicker from 'react-native-dropdown-picker';

import { default as Text } from '../../components/AppText';
import { globalStyles } from '../../styles/GlobalStyles';
import { selectCosts } from '../costs/costsSlice';
import { colors, radius } from '../../constants/theme';
import Button from '../../components/Button';
import FireStopContext from './FireStopContext';
import { calculateCrack, calculateHole } from '../../functions/calculations';
import { sanitizeNumericString } from '../../functions/utils';

const AVAILABLE_COST_OPTIONS = {
	'hole': [1, 2],
	'crack': [1, 3],
	'other': [1]
}

const AddRemedialWorkItemForm = ({ element, elementCosts, setElementCosts }) => {
	const costs = useSelector(selectCosts);
	const { setShowAddCostForm } = useContext(FireStopContext);

	const [quantity, setQuantity] = useState(1);

	const [costTitle, setCostTitle] = useState('');
	const [costTitleIsOpen, setCostTitleIsOpen] = useState(false);
	const [costTitleOptions, setCostTitleOptions] = useState([]);

	const [costSize, setCostSize] = useState('');
	const [costSizeIsOpen, setCostSizeIsOpen] = useState(false);
	const [costSizeOptions, setCostSizeOptions] = useState([]);

	const [titleError, setTitleError] = useState(null);
	const [quantityError, setQuantityError] = useState(null);

	const canSave = [costTitle].every(Boolean);

	const selectedCost = costs.find((cost) => cost.id === costTitle);

	const handleSave = () => {
		if (canSave)
		{
			setTitleError(null);
			setQuantityError(null);

			const costToSave = costs.find((cost) => cost.id === costTitle);
			const optionToSave = costToSave.options.find(
				(option) => option.id === costSize
			);
		
			setElementCosts([...elementCosts, {
				id: `NEW_${elementCosts.length + 1}`,
				cost_id: costToSave.id,
				cost_title: costToSave.title,
				cost_price: Number(costToSave.price),
				calculation: costToSave.calculation,
				option_id: (!!optionToSave ? optionToSave.id: 0),
				option_title: (!!optionToSave ? optionToSave.title : ''),
				option_price: (!!optionToSave ? Number(optionToSave.price) : 0.00),
				quantity: Number(quantity),
				deleted: 0
			}]);

			setCostTitle('');
			setCostSize('');
			setQuantity(1);
			setShowAddCostForm(false);
		}
		else
		{
			if (!costTitle)
			{
				setTitleError('Please select a cost');
			}

			if (!quantity)
			{
				setQuantityError('Quantity must be greater than 0');
			}
		}
	};

	const calculateQuantityByCostOption = (optionSize) => {
		const calculatedCrack = calculateCrack(element.crack_width, element.crack_length, element.crack_depth);

		let suggestedQuantity = 1;
		while ((suggestedQuantity * optionSize) < calculatedCrack) {
			suggestedQuantity++;
		}

		return suggestedQuantity;
	}

	useEffect(() => {
		if (costs && element) {
			const formattedCosts = costs.filter((cost) => AVAILABLE_COST_OPTIONS[element?.fail_type].includes(cost.calculation)).map((cost) => {
				return { label: cost.title, value: cost.id };
			});

			setCostTitleOptions(formattedCosts);
		}
	}, [costs, element]);

	useEffect(() => {
		if (costSize) {
			if (selectedCost.calculation === 3) {
				const selectedTubeOption = costSizeOptions.find((option) => option.value === costSize);
	
				console.log(selectedTubeOption);
	
				if (selectedTubeOption.label) {
					const tubeSize = sanitizeNumericString(selectedTubeOption.label);
	
					setQuantity(calculateQuantityByCostOption(tubeSize));
				}
			}
		}
	}, [costSize]);

	useEffect(() => {
		setCostSize('');
		setCostSizeIsOpen(false);

		if(selectedCost !== undefined)
		{
			const options = selectedCost.options
				.filter((option) => option.deleted === 0)
				.map((option) => {
					return { label: `${option.title + (selectedCost.calculation === 3 ? 'ml' : '')}`, value: option.id };
				});

			setCostSizeOptions(options);

			let calculatedSize = quantity;

			switch(selectedCost.calculation)
			{
				case 2:

					let width, length;

					if (element.hole_size === 'other') {
						width = element.hole_size_other_width;
						length = element.hole_size_other_length;
					} else {
						[width, length] = element.hole_size.replaceAll("m", "").replaceAll(" ", "").split("x");
					}

					calculatedSize = calculateHole(width, length);

				break;

				case 3:

					if (costSize) {
						calculatedSize = calculateQuantityByCostOption(costSize);
					}
					
				break;

				default:

					console.log(quantity)
					if(!Number.isInteger(quantity))
					{
						calculatedSize = 1;
					}

				break;
			}

			setQuantity(calculatedSize);
		}
		else
		{
			setCostSizeOptions([]);
		}
	}, [element, costs, costTitle]);

	return (
		<View style={[styles.container, { zIndex: 1000 }]}>
			<View style={[styles.rowContainer, { zIndex: 1000 }]}>
				<View style={styles.formContainer}>
					<View style={[globalStyles.fieldContainer, { zIndex: 1000 }]}>
						<Text style={globalStyles.label}>Cost Title</Text>
						<View style={{ flex: 1 }}>
							<DropDownPicker
								placeholder='Please select a cost'
								open={costTitleIsOpen}
								value={costTitle}
								items={costTitleOptions}
								setOpen={setCostTitleIsOpen}
								setValue={setCostTitle}
								style={[globalStyles.dropdown, styles.select]}
								dropDownContainerStyle={globalStyles.dropdownItem}
							/>
						</View>
					</View>
					{!!costSizeOptions.length ? (
						<View style={[globalStyles.fieldContainer, { zIndex: 800 }]}>
							<Text style={globalStyles.label}>Size</Text>
							<View style={{ flex: 1 }}>
								<DropDownPicker
									placeholder='Please select a size'
									dropDownDirection="BOTTOM"
									open={costSizeIsOpen}
									value={costSize}
									items={costSizeOptions}
									setOpen={setCostSizeIsOpen}
									setValue={setCostSize}
									setItems={setCostSizeOptions}
									style={[globalStyles.dropdown, styles.select]}
									dropDownContainerStyle={globalStyles.dropdownItem}
								/>
							</View>
						</View>
					) : null}
					{(!!selectedCost ? 
						(selectedCost.calculation === 2 ? <View style={globalStyles.fieldContainer}>
							<Text style={globalStyles.label}>Calculated</Text>
							<Text style={styles.field}>{quantity}</Text>
							<View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 8 }}>
								<Text>m2</Text>
							</View>
						</View> : <View style={globalStyles.fieldContainer}>
							<Text style={globalStyles.label}>Quantity</Text>
							<TextInput style={styles.field} onChangeText={(value) => setQuantity(value)} value={quantity} />
						</View>)
					: null)}
				</View>
				{selectedCost?.calculation === 3 ? (
					<View style={styles.suggestionContainer}>
						<Text size="md" variant="bold">Estimated</Text>
						{selectedCost?.options.map((option, index) => {
							const optionInMl = Number(option.title);
							const suggestedQuantity = calculateQuantityByCostOption(optionInMl);

							return (
								<View key={option.id} style={{ marginTop: 4 }}>
									<Text>{suggestedQuantity} x {optionInMl}ml{index + 1 < selectedCost?.options.length ? ' or' : ''}</Text>
								</View>
							);
						})}
					</View>
				) : null}
			</View>
			<View style={styles.buttonContainer}>
				<Button title='Save' variant='secondary' onPress={handleSave} style={{ marginRight: 60 }} />
				<Button title='Close' onPress={() => setShowAddCostForm(false)} />
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingHorizontal: 40,
		paddingVertical: 16,
		marginTop: 20,
		backgroundColor: colors.darkGrey,
		borderRadius: radius.md,
	},
	rowContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	formContainer: {

	},
	suggestionContainer: {
		marginTop: 10,
		paddingRight: 40,
		alignItems: 'flex-end'
	},
	select: {
		width: 350,
	},
	field: {
		backgroundColor: 'white',
		borderRadius: radius.sm,
		paddingHorizontal: 10,
		paddingVertical: 15,
		width: 100,
		height: 40,
	},
	buttonContainer: {
		flexDirection: 'row',
		marginTop: 20,
	},
});

export default AddRemedialWorkItemForm;
