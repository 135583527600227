import { useNavigation } from '@react-navigation/native';
import { ScrollView, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import { padding } from '../constants/theme';
import Layout from '../containers/Layout';
import OrganisationItem from '../features/organisations/OrganisationItem';
import {
  addOrganisation,
  selectOrganisations,
} from '../features/organisations/organisationsSlice';

const OrganisationsScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const organisations = useSelector(selectOrganisations);

  const filteredOrganisations = organisations
    .filter((organisation) => organisation.deleted === 0)
    .sort((a, b) => {
      const nameA = String(a.name).toUpperCase();
      const nameB = String(b.name).toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

  const handleAddOrganisation = () => {
    dispatch(addOrganisation());
    navigation.navigate('OrganisationDetails');
  };

  const renderedOrganisations = filteredOrganisations.map((organisation) => (
    <OrganisationItem
      key={organisation.id}
      organisation={organisation}
    />
  ));

  return (
    <Layout title='Customers'>
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        <View style={styles.header}>
          <Text variant='heading' size='xl'>
            Customers
          </Text>
          <Button
            title='Add Customer'
            variant='secondary'
            onPress={handleAddOrganisation}
          />
        </View>
        <View style={styles.listContainer}>
          {filteredOrganisations.length > 0 ? renderedOrganisations : <Text>You have no customers set up. Please click 'Add Customer' to begin creating a customer.</Text>}
        </View>
      </ScrollView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listContainer: {
    marginTop: 32,
  },
});

export default OrganisationsScreen;
