export const colors = {
  primary: '#CD2121',
  secondary: '#D3A045',
  orange: '#D4733B',
  white: '#FFFFFF',
  black: '#333333',
  darkGrey: '#DEDEDE',
  lightGrey: '#F4F4F4',
  placeholder: '#999',
  error: '#CD2121',
  success: '#4BC33E',
};

export const padding = {
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 40,
  xxxl: 80,
};

export const radius = {
  sm: 4,
  md: 8,
  lg: 12,
  xl: 16,
};

export const fonts = {
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
};
