import Svg, { Path } from 'react-native-svg';

import { colors } from '../constants/theme';

const Trash = (props) => (
  <Svg
    height={24}
    width={24}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill={colors.error}
    {...props}
  >
    <Path
      d='M7 4c0-1.10457.89543-2 2-2h6c1.1046 0 2 .89543 2 2v2h1.9897a.961022.961022 0 0 1 .0186 0H21c.5523 0 1 .44772 1 1s-.4477 1-1 1h-1.0689l-.8673 12.1425C18.989 21.1891 18.1182 22 17.0689 22H6.93112c-1.04928 0-1.92016-.8109-1.99492-1.8575L4.06888 8H3c-.55228 0-1-.44772-1-1s.44772-1 1-1h1.99174a.958956.958956 0 0 1 .01858 0H7V4Zm2 2h6V4H9v2ZM6.07398 8l.85714 12H17.0689l.8571-12H6.07398ZM10 10c.5523 0 1 .4477 1 1v6c0 .5523-.4477 1-1 1-.55228 0-1-.4477-1-1v-6c0-.5523.44772-1 1-1Zm4 0c.5523 0 1 .4477 1 1v6c0 .5523-.4477 1-1 1s-1-.4477-1-1v-6c0-.5523.4477-1 1-1Z'
      fill={colors.error}
    />
  </Svg>
);
export default Trash;
