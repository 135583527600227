import { useEffect } from 'react';
import { SafeAreaView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  FontAwesome5,
  MaterialCommunityIcons,
  Ionicons,
} from '@expo/vector-icons';

import PasswordReset from '../components/PasswordReset';

import FireStopDashboardScreen from '../screens/FireStopDashboardScreen.js';
import FireStopSurveyFormScreen from '../screens/FireStopSurveyFormScreen.js';
import CostsScreen from '../screens/CostsScreen.js';
import CostsOptionsScreen from '../screens/CostsOptionsScreen.js';

import FireStopElementsScreen from '../screens/FireStopElementsScreen.js';
import FireStopRepairsScreen from '../screens/FireStopRepairsScreen.js';
import FireStopElementsFormScreen from '../screens/FireStopElementsFormScreen.js';
import FireStopSummaryScreen from '../screens/FireStopSummaryScreen.js';

import LoginScreen from '../screens/LoginScreen.js';
import ResetPasswordScreen from '../screens/ResetPasswordScreen.js';
import MenuScreen from '../screens/MenuScreen.js';
import HelpScreen from '../screens/HelpScreen.js';
import DataManagementScreen from '../screens/DataManagementScreen.js';
import PhotoManagementScreen from '../screens/PhotoManagementScreen.js';

import { OptionsProvider } from '../features/costs/OptionsContext.js';
import { FireStopProvider } from '../features/firestop/FireStopContext.js';
import { selectToken, selectUser } from '../features/auth/authSlice.js';
import FireStopSurveyDashboardScreen from '../screens/FireStopSurveyDashboardScreen.js';
import FireStopStatsScreen from '../screens/FireStopStatsScreen.js';

import OrganisationsScreen from '../screens/OrganisationsScreen.js';
import OrganisationDetailsScreen from '../screens/OrganisationDetailsScreen.js';
import { getOrganisations } from '../features/organisations/organisationsSlice.js';

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

const FireStopStack = () => {
  return (
    <FireStopProvider>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName='FireStopDashboard'
      >
        <Stack.Screen
          name='FireStopDashboard'
          component={FireStopDashboardScreen}
        />
        <Stack.Screen
          name='FireStopSurveyForm'
          component={FireStopSurveyFormScreen}
        />
        <Stack.Screen
          name='FireStopElements'
          component={FireStopElementsScreen}
        />
        <Stack.Screen
          name='FireStopRepairs'
          component={FireStopRepairsScreen}
        />
        <Stack.Screen
          name='FireStopElementsFormEdit'
          component={FireStopElementsFormScreen}
        />
        <Stack.Screen
          name='FireStopSummary'
          component={FireStopSummaryScreen}
        />
        <Stack.Screen
          name='FireStopSurveyDashboard'
          component={FireStopSurveyDashboardScreen}
        />
        <Stack.Screen name='FireStopStats' component={FireStopStatsScreen} />
      </Stack.Navigator>
    </FireStopProvider>
  );
};

const CostsStack = () => {
  return (
    <OptionsProvider>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName='Costs'
      >
        <Stack.Screen name='Costs' component={CostsScreen} options={{ title: 'Costs' }} />
        <Stack.Screen name='CostsOptions' component={CostsOptionsScreen} options={{ title: 'Costs Options' }} />
      </Stack.Navigator>
    </OptionsProvider>
  );
};

const MenuStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName='Menu'
    >
      <Stack.Screen name='Menu' component={MenuScreen} />
      <Stack.Screen name='Help' component={HelpScreen} />
      <Stack.Screen name='DataManagement' component={DataManagementScreen} />
      <Stack.Screen name='PhotoManagement' component={PhotoManagementScreen} />
    </Stack.Navigator>
  );
};

const OrganisationsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName='Organisations'
    >
      <Stack.Screen name='Organisations' component={OrganisationsScreen} />
      <Stack.Screen
        name='OrganisationDetails'
        component={OrganisationDetailsScreen}
      />
    </Stack.Navigator>
  );
};

const linking = {
  config: {
    screens: {
      Dashboard: '',
      Login: '/login',
      FireDoor: '/firedoor',
      FireStopStack: {
        screens: {
          FireStopDashboard: '/firestop',
          FireStopSurveyForm: '/firestop/new',
          FireStopElements: '/firestop/elements',
          FireStopRepairs: '/firestop/repairs',
          FireStopElementsFormEdit: '/firestop/elements/edit',
          FireStopSummary: '/firestop/summary',
          FireStopSurveyDashboard: '/firestop/:surveyId/dashboard',
          FireStopStats: '/firestop/:surveyId/dashboard/summary/:entryId',
        },
      },
      CostsStack: {
        screens: {
          Costs: '/costs',
          CostsOptions: '/costs/options',
        },
      },
      OrganisationsStack: {
        screens: {
          Organisations: '/organisations',
          OrganisationDetails: '/organisations/edit',
        },
      },
      MenuStack: {
        screens: {
          Menu: '/menu',
          Help: '/help',
          DataManagement: '/data-management',
          PhotoManagement: '/photo-management',
        },
      },
      NotFound: '*',
    },
  },
};

function RootNavigator() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user && token) {
      // dispatch(getOrganisations());
    }
  },[user, token]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
      <NavigationContainer linking={linking}>
        <Tab.Navigator
          screenOptions={{
            headerShown: false,
            tabBarActiveTintColor: '#54BE42',
            tabBarInactiveTintColor: '#fff',
            tabBarActiveBackgroundColor: '#333333',
            tabBarInactiveBackgroundColor: '#333333',
            tabBarLabelPosition: 'below-icon',
          }}
        >
          {token ? (
            <>
              <Tab.Screen
                name='FireStopStack'
                component={FireStopStack}
                options={{
                  tabBarLabel: 'Dashboard',
                  tabBarIcon: ({ color, size }) => (
                    <MaterialCommunityIcons
                      name='dots-grid'
                      color={color}
                      size={size}
                    />
                  ),
                }}
              />
              <Tab.Screen
                name='CostsStack'
                component={CostsStack}
                options={{
                  tabBarLabel: 'Costs',
                  tabBarIcon: ({ color, size }) => (
                    <FontAwesome5 name='pound-sign' color={color} size={size} />
                  ),
                }}
              />
              <Tab.Screen
                name='OrganisationsStack'
                component={OrganisationsStack}
                options={{
                  tabBarLabel: 'Customers',
                  tabBarIcon: ({ color, size }) => (
                    <FontAwesome5 name='building' color={color} size={size} />
                  ),
                }}
              />
              <Tab.Screen
                name='MenuStack'
                component={MenuStack}
                options={{
                  tabBarLabel: 'Menu',
                  tabBarIcon: ({ color, size }) => (
                    <Ionicons
                      name='md-menu-outline'
                      size={size}
                      color={color}
                    />
                  ),
                }}
              />
            </>
          ) : (
            <>
              <Tab.Screen
                name='Login'
                component={LoginScreen}
                options={{
                  tabBarLabel: 'Login',
                  tabBarIcon: ({ color, size }) => (
                    <MaterialCommunityIcons
                      name='login'
                      size={size}
                      color={color}
                    />
                  ),
                }}
              />
              <Tab.Screen
                name='ResetPassword'
                component={ResetPasswordScreen}
                options={{
                  tabBarLabel: 'Reset Password',
                  tabBarIcon: ({ color, size }) => (
                    <PasswordReset color={color} size={size} />
                  ),
                }}
              />
            </>
          )}
        </Tab.Navigator>
      </NavigationContainer>
    </SafeAreaView>
  );
}

export default RootNavigator;
