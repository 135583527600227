import { useState, useEffect, useCallback } from 'react';
import { ScrollView, StyleSheet, View, TextInput } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import DropDownPicker from 'react-native-dropdown-picker';
import { Formik } from 'formik';

import Layout from '../containers/Layout';
import { colors, fonts, padding, radius } from '../constants/theme';
import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import ImageUpload from '../components/ImageUpload';
import { globalStyles } from '../styles/GlobalStyles';
import { getCurrentSurvey, saveSurvey, getSelectedSurvey, selectNewOrganisation } from '../features/firestop/firestopSlice';
import { selectOrganisations } from '../features/organisations/organisationsSlice';
import NewOrganisationForm from '../features/firestop/NewOrganisationForm';
import NewAddressForm from '../features/firestop/NewAddressForm';

function FireStopSurveyFormScreen() {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const survey = useSelector(getCurrentSurvey);
  const organisations = useSelector(selectOrganisations);
  const selectedSurvey = useSelector(getSelectedSurvey);
  const newOrganisation = useSelector(selectNewOrganisation);

	const initialValues = {
		primaryContact: survey?.primaryContact || '',
		emailAddress: survey?.emailAddress || '',
		mobileNumber: survey?.mobileNumber || '',
		surveyName: survey?.surveyName || '',
		notes: survey?.notes || ''
	};

	const newSurvey = (typeof survey?.id === "string" || survey?.id == undefined);

  const [organisationName, setOrganisationName] = useState(survey?.organisationName || '');
  const [organisationNameOptions, setOrganisationNameOptions] = useState([]);
  const [organisationNameIsOpen, setOrganisationNameIsOpen] = useState(false);

  const [showCreateOrganisation, setShowCreateOrganisation] = useState(false);
  const [showCreateAddress, setShowCreateAddress] = useState(false);

  const [organisationAddress, setOrganisationAddress] = useState(survey?.organisationAddress || '');
  const [organisationAddressOptions, setOrganisationAddressOptions] = useState([]);
  const [organisationAddressIsOpen, setOrganisationAddressIsOpen] = useState(false);

  const [newOrganisationError, setNewOrganisationError] = useState(false);

  const [surveyType, setSurveyType] = useState(survey?.surveyType || '');
  const [surveyTypeOptions, setSurveyTypeOptions] = useState([
    { label: 'Fire Stop', value: 'Fire Stop' },
  ]);
  const [surveyTypeIsOpen, setSurveyTypeIsOpen] = useState(false);

  const [frequency, setFrequency] = useState(survey?.frequency || '');
  const [frequencyOptions, setFrequencyOptions] = useState([
    { label: 'One Off', value: 'one off' },
    { label: 'Monthly', value: 'month' },
    { label: 'Annual', value: 'year' },
  ]);
  const [frequencyIsOpen, setFrequencyIsOpen] = useState(false);

  const [floorplan, setFloorplan] = useState(survey?.floorplan || {});

  const [surveyPassFailType, setSurveyPassFailType] = useState(survey?.surveyPassFailType || 0);
  const [surveyPassFailTypeOptions, setSurveyPassFailTypeOptions] = useState([
    { label: 'Fail Breaches Only', value: 0 },
    { label: 'Pass & Fail Breaches', value: 1 },
  ]);
  const [surveyPassFailTypeIsOpen, setSurveyPassFailTypeIsOpen] = useState(false);

  const [organisationNameError, setOrganisationNameError] = useState(null);
  const [organisationAddressError, setOrganisationAddressError] = useState(null);
  const [surveyNameError, setSurveyNameError] = useState(null);
  const [frequencyError, setFrequencyError] = useState(null);

  let canSave = [organisationName, organisationAddress, frequency].every(Boolean);

  useEffect(() => {
    let options = [];
    if (!!organisations.length) {
      options = organisations
        .filter((organisation) => organisation.deleted === 0)
        .sort((a, b) => {
          const nameA = String(a.name).toUpperCase();
          const nameB = String(b.name).toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .map((organisation) => ({ label: organisation.name, value: organisation.id }));
    }

    setOrganisationNameOptions([
      ...options,
      { label: 'Create a new customer', value: 'new' },
    ]);
  }, [organisations]);

  useEffect(() => {
    setOrganisationAddressIsOpen(false);
    setShowCreateOrganisation(false);

    if (!!organisations.length && organisationName !== '') {
      const selectedOrganisation = organisations.find(
        (organisation) => organisation.id === organisationName
      )

      if (selectedOrganisation) {
        const options = selectedOrganisation.addresses
          .filter((address) => address.deleted === 0)
          .sort((a, b) => {
            const nameA = String(a.label).toUpperCase();
            const nameB = String(b.label).toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .map((address) => ({ label: address.label, value: address.id }));

        if(!selectedOrganisation.addresses.filter((address) => address.id === organisationAddress).length)
        {
          setOrganisationAddress('');
        }
        
        setOrganisationAddressOptions([
          ...options,
          { label: 'Create a new address', value: 'new' },
        ]);
        // dispatch(clearOrganisation());
      } else if (organisationName === 'new') {
        setShowCreateOrganisation(true);
        setOrganisationAddress('new');
        setShowCreateAddress(false);
      } else {
        setOrganisationAddress('');
      }
    } else if (organisationName === 'new') {
      setShowCreateOrganisation(true);
    } else {
      setOrganisationAddress('');
    }
  }, [organisationName]);

	useEffect(() => {
		if (organisationAddress === 'new' && organisationName !== 'new') {
			setShowCreateAddress(true);
		} else {
			setShowCreateAddress(false);
		}
	}, [organisationAddress]);
	
	const onDropdownOpen = useCallback((selected) => {
		if(selected !== "organisation")
		{
			setOrganisationNameIsOpen(false);
		}
		if(selected !== "address")
		{
			setOrganisationAddressIsOpen(false);
		}
		if(selected !== "type")
		{
			setSurveyTypeIsOpen(false);
		}
		if(selected !== "frequency")
		{
			setFrequencyIsOpen(false);
		}
	}, []);

  const handleBack = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else {
			navigation.navigate('FireStopDashboard');
		}
	};

  return (
    <Layout title={`Fire Stopping Survey: ${selectedSurvey ? 'Edit' : 'Create'}`}>
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        <View style={styles.titleContainer}>
          <Text size='xl' variant='heading'>Enter Survey Basic Details</Text>
        </View>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            setOrganisationNameError(null);
            setOrganisationAddressError(null);
            setSurveyNameError(null);
            setFrequencyError(null);

            if (organisationAddress === 'new') {
              if (!newOrganisation) {
                canSave = false;
                setNewOrganisationError(true);
              }
            }

            if (canSave && values.surveyName !== "") {
              try {
                dispatch(
                  saveSurvey({
                    ...values,
                    organisationName,
                    organisationAddress,
                    surveyType,
                    frequency,
                    floorplan,
                    surveyPassFailType
                  })
                );
                navigation.navigate('FireStopElements');
              } catch (err) {
                console.log(err);
              }
            } else {
              if (!organisationName) {
                setOrganisationNameError('Required');
              }

              if (!organisationAddress) {
                setOrganisationAddressError('Required');
              }

              if (!values.surveyName) {
                setSurveyNameError('Required');
              }

              if (!frequency) {
                setFrequencyError('Required');
              }
            }
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, values }) => (
            <View style={styles.formContainer}>
              <View style={[styles.fieldContainer, { zIndex: 1200 }]}>
                <Text style={styles.label}>Customer<Text style={styles.required}>*</Text></Text>
                <View style={{ flex: 1 }}>
                  {(!newSurvey ?
                    <View style={{ justifyContent: 'center', flex: 1 }}>
                      <Text>{organisations.map((organisation) => {
                        if(organisation.id == organisationName)
                        {
                          return organisation.name + (organisation.deleted ? ' (Deleted)' : '');
                        }
                      })}</Text>
                    </View>
                  : <>
                    <DropDownPicker
                      placeholder='Please select a customer'
                      dropDownDirection="BOTTOM"
                      open={organisationNameIsOpen}
                      value={organisationName}
                      items={organisationNameOptions}
                      setOpen={setOrganisationNameIsOpen}
                      onOpen={() => onDropdownOpen("organisation")} 
                      setValue={setOrganisationName}
                      setItems={setOrganisationNameOptions}
                      style={globalStyles.dropdown}
                      dropDownContainerStyle={globalStyles.dropdownItem}
                    />
                    {organisationNameError ? (
                      <Text style={globalStyles.error}>{organisationNameError}</Text>
                    ) : null}
                    {showCreateOrganisation ? <NewOrganisationForm /> : null}
                  </>)}
                </View>
              </View>
              {organisationName ? (
                <View style={[styles.fieldContainer, { zIndex: 1000 }]}>
                  <Text style={styles.label}>
                    Building Address <Text style={styles.required}>*</Text>
                  </Text>
                  <View style={{ flex: 1 }}>
                    {(!newSurvey ? 
                      <View style={{ justifyContent: 'center', flex: 1 }}>
                        <Text>{organisations.find((organisation) => organisation.id === organisationName)?.addresses.map((address) => {
                          if (address.id == organisationAddress) {
                            return address.label + (address.deleted ? ' (Deleted)' : '')
                          }
                        })}</Text>
                    </View>
                    : 
                      <>
                        {organisationName === 'new' ? (
                          <NewAddressForm setOrganisationAddress={setOrganisationAddress} />
                        ) : (
                          <DropDownPicker
                            placeholder='Please select an address'
                            dropDownDirection="BOTTOM"
                            open={organisationAddressIsOpen}
                            value={organisationAddress}
                            items={organisationAddressOptions}
                            setOpen={setOrganisationAddressIsOpen}
                            onOpen={() => onDropdownOpen("address")} 
                            setValue={setOrganisationAddress}
                            setItems={setOrganisationAddressOptions}
                            style={globalStyles.dropdown}
                            dropDownContainerStyle={globalStyles.dropdownItem}
                          />
                        )}
                        {showCreateAddress ? (
                          <View style={{ marginTop: 16 }}>
                            <NewAddressForm setOrganisationAddress={setOrganisationAddress} organisationId={organisationName} error={newOrganisationError} />
                          </View>
                        ) : null}
                        {organisationAddressError ? (
                          <Text style={globalStyles.error}>
                            {organisationAddressError}
                          </Text>
                        ) : null}
                      </>
                    )}
                  </View>
                </View>
              ) : null}
              <View style={styles.fieldContainer}>
                <Text style={styles.label}>Primary Contact</Text>
                <TextInput
                  style={globalStyles.field}
                  onChangeText={handleChange('primaryContact')}
                  onBlur={handleBlur('primaryContact')}
                  value={values.primaryContact}
                />
              </View>
              <View style={styles.fieldContainer}>
                <Text style={styles.label}>Email Address</Text>
                <TextInput
                  style={globalStyles.field}
                  onChangeText={handleChange('emailAddress')}
                  onBlur={handleBlur('emailAddress')}
                  value={values.emailAddress}
                />
              </View>
              <View style={styles.fieldContainer}>
                <Text style={styles.label}>Mobile Number</Text>
                <TextInput
                  style={globalStyles.field}
                  onChangeText={handleChange('mobileNumber')}
                  onBlur={handleBlur('mobileNumber')}
                  value={values.mobileNumber}
                />
              </View>
              <View style={styles.fieldContainer}>
                <Text style={styles.label}>Survey Name <Text style={styles.required}>*</Text></Text>
                <View style={{ flex: 1 }}>
                  <TextInput
                    style={globalStyles.field}
                    onChangeText={handleChange('surveyName')}
                    onBlur={handleBlur('surveyName')}
                    value={values.surveyName}
                  />
                  {surveyNameError ? (
                    <Text style={globalStyles.error}>{surveyNameError}</Text>
                  ) : null}
                </View>
              </View>
              <View style={[styles.fieldContainer, { zIndex: 800 }]}>
                <Text style={styles.label}>Survey Type</Text>
                <View style={{ flex: 1 }}>
                  {(!newSurvey ?
                    <View style={{ justifyContent: 'center', flex: 1 }}>
                      <Text>{surveyTypeOptions.map((option) => {
                        if(option.value == surveyType)
                        {
                          return option.label
                        }
                      })}</Text>
                    </View>
                  :
                    <DropDownPicker
                      placeholder='Please select a type'
                      dropDownDirection="BOTTOM"
                      open={surveyTypeIsOpen}
                      value={surveyType}
                      items={surveyTypeOptions}
                      setOpen={setSurveyTypeIsOpen}
                      onOpen={() => onDropdownOpen("type")} 
                      setValue={setSurveyType}
                      setItems={setSurveyTypeOptions}
                      style={globalStyles.dropdown}
                      dropDownContainerStyle={globalStyles.dropdownItem}
                    />
                  )}
                </View>
              </View>
              <View style={[styles.fieldContainer, { zIndex: 600 }]}>
                <Text style={styles.label}>Frequency <Text style={styles.required}>*</Text></Text>
                <View style={{ flex: 1 }}>
                  {(!newSurvey ? 
                    <View style={{ justifyContent: 'center', flex: 1 }}>
                      <Text>{frequencyOptions.map((option) => {
                        if(option.value == frequency)
                        {
                          return option.label
                        }
                      })}</Text>
                    </View>
                  :
                    <>
                      <DropDownPicker
                        placeholder='Please select a frequency'
                        dropDownDirection="BOTTOM"
                        open={frequencyIsOpen}
                        value={frequency}
                        items={frequencyOptions}
                        setOpen={setFrequencyIsOpen}
                        onOpen={() => onDropdownOpen("frequency")} 
                        setValue={setFrequency}
                        setItems={setFrequencyOptions}
                        style={globalStyles.dropdown}
                        dropDownContainerStyle={globalStyles.dropdownItem}
                      />
                      {frequencyError ? (
                        <Text style={globalStyles.error}>{frequencyError}</Text>
                      ) : null}
                    </>
                  )}
                </View>
              </View>
              <View style={styles.fieldContainer}>
                <Text style={styles.label}>Floorplan</Text>
                <ImageUpload name='floorplan' collection="images" files={floorplan} setFiles={setFloorplan} setArray={true} />
              </View>
              <View style={[styles.fieldContainer, { zIndex: 400 }]}>
                <Text style={styles.label}>Fail/Pass Type <Text style={styles.required}>*</Text></Text>
                <View style={{ flex: 1 }}>
                  {(!newSurvey ? 
                    <View style={{ justifyContent: 'center', flex: 1 }}>
                      <Text>{surveyPassFailTypeOptions.map((option) => {
                        if(option.value == surveyPassFailType)
                        {
                          return option.label
                        }
                      })}</Text>
                    </View>
                  :
                    <>
                      <DropDownPicker
                        placeholder='Please select a pass/fail type'
                        dropDownDirection="BOTTOM"
                        open={surveyPassFailTypeIsOpen}
                        value={surveyPassFailType}
                        items={surveyPassFailTypeOptions}
                        setOpen={setSurveyPassFailTypeIsOpen}
                        onOpen={() => onDropdownOpen("surveyPassFailType")} 
                        setValue={setSurveyPassFailType}
                        setItems={setSurveyPassFailTypeOptions}
                        style={globalStyles.dropdown}
                        dropDownContainerStyle={globalStyles.dropdownItem}
                      />
                    </>
                  )}
                </View>
              </View>
              <View style={styles.fieldContainer}>
                <Text style={styles.label}>Comments/Notes</Text>
                <TextInput
                  style={[globalStyles.field, { height: 200, paddingVertical: 8 }]}
                  onChangeText={handleChange('notes')}
                  onBlur={handleBlur('notes')}
                  value={values.notes}
                  multiline={true}
                />
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  onPress={handleSubmit}
                  title='Save'
                  variant='secondary'
                />
                <Button
                  onPress={handleBack}
                  title='Cancel'
                  variant='black'
                />
              </View>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  titleContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formContainer: {
    backgroundColor: colors.darkGrey,
    borderRadius: radius.sm,
    paddingVertical: 10,
    marginBottom: 30,
  },
  fieldContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  label: {
    fontSize: fonts.md,
    fontWeight: '600',
    minWidth: 180,
    paddingVertical: 10,
  },
  buttonContainer: {
    width: 120,
    marginLeft: 200,
    marginTop: 20,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
  },
  required: {
    color: colors.primary,
  },
});

export default FireStopSurveyFormScreen;
