import { useState, useEffect } from 'react';
import { ScrollView, StyleSheet, TextInput, View } from 'react-native';
import { useNavigation } from '@react-navigation/core';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import Layout from '../containers/Layout';
import { padding } from '../constants/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedOrganisation,
  setOrganisationName,
  saveOrganisation,
  setSelectedOrganisation,
} from '../features/organisations/organisationsSlice';
import AddressItem from '../features/organisations/AddressItem';
import AddressForm from '../features/organisations/AddressForm';
import { globalStyles } from '../styles/GlobalStyles';

const OrganisationDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const organisation = useSelector(getSelectedOrganisation);
  const { isLoading } = useSelector(state => state.organisations);

  const [name, setName] = useState(organisation?.name);
  const [showError, setShowError] = useState(false);

  const [showEditAddress, setShowEditAddress] = useState(null);

  const filteredAddresses = organisation.addresses
    .filter((address) => address.deleted === 0)
    .sort((a, b) => {
      const nameA = String(a.label).toUpperCase();
      const nameB = String(b.label).toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

  const handleSave = () => {
    if (name) {
      const data = {
        id: organisation.id,
        name,
        addresses: organisation.addresses,
        deleted: organisation.deleted,
      };
  
      dispatch(saveOrganisation(data));
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const handleNameChange = (value) => {
    setName(value);
    dispatch(setOrganisationName(value));
  }

  const handleBack = () => {
    navigation.navigate('Organisations');
  };

  useEffect(() => {
    if (organisation) {
      setName(organisation.name);
    }
  }, [organisation]);

  const renderedAddresses = filteredAddresses.map((address) => (
    <AddressItem
      key={address.id}
      address={address}
      setShowEditAddress={setShowEditAddress}
      showEditAddress={showEditAddress}
    />
  ));

  if (!organisation) {
    return (
      <View>
        <Text>Customer could not be found!</Text>
      </View>
    );
  }

  return (
    <Layout title='Customers'>
      <ScrollView style={styles.container}>
        <View style={styles.header}>
          <Text variant='heading' size='xl'>
            Customers
          </Text>
          <Button title='Back' variant='secondary' onPress={handleBack} />
        </View>
        <View>
          <View style={globalStyles.fieldContainer}>
            <Text style={{ ...globalStyles.label, marginRight: 16 }}>Name: </Text>
            <View style={{ width: '100%' }}>
              <TextInput
                style={[globalStyles.field, { minWidth: 120 }]}
                value={name}
                onChangeText={(value) => setName(value)}
              />
              {showError ? (
                <Text style={globalStyles.error}>Name is required</Text>
              ) : null}
            </View>
          </View>
        </View>
        {organisation.name !== '' ? (
          <>
            <View style={styles.addressHeader}>
              <Text variant='heading'>Locations</Text>
            </View>
            <View style={styles.subtitle}>
              {filteredAddresses.length < 1 ? <Text>You have no locations set up for this customer. Enter your location details below and click 'Add'.</Text> : null}
            </View>
            <AddressForm />
            <View style={styles.listContainer}>
              {filteredAddresses.length > 0 ? renderedAddresses : null}
            </View>
          </>
        ) : null}
        <View style={styles.buttonContainer}>
          <Button title='Save' variant='secondary' onPress={handleSave} isLoading={isLoading} />
        </View>
      </ScrollView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addressHeader: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subtitle: {
    marginTop: 10,
  },
  listContainer: {
    marginTop: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
  },
});

export default OrganisationDetailsScreen;
