import { StyleSheet, Text } from 'react-native';
import { colors } from '../constants/theme';

function AppText({ children, variant, size, color, style }) {
  return (
    <Text
      style={[
        styles.text,
        {
          fontSize: sizes[size],
          color,
          ...variants[variant],
          ...style,
        },
      ]}
    >
      {children}
    </Text>
  );
}

AppText.defaultProps = {
  variant: 'text',
};

const variants = {
  text: {},
  heading: {
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  column: {
    fontWeight: '600',
  },
  bold: {
    fontWeight: '600',
  },
};

const sizes = {
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  xxl: 24,
};

const styles = StyleSheet.create({
  text: {
    color: colors.black,
    fontFamily: 'Lato',
  },
});

export default AppText;
