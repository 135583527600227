import { useEffect, useState } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { colors, radius } from '../../constants/theme';
import { globalStyles } from '../../styles/GlobalStyles';
import { selectOrganisations } from '../organisations/organisationsSlice';
import { createOrganisation, selectNewOrganisation } from './firestopSlice';

const NewOrganisationForm = () => {
  const dispatch = useDispatch();
  const newOrganisation = useSelector(selectNewOrganisation);
  const organisations = useSelector(selectOrganisations);

  const [organisation, setOrganisation] = useState(newOrganisation?.name || '');

  const handleBlur = () => {
    dispatch(createOrganisation({ name: organisation, numberOfOrganisations: organisations.length }));
  };

  return (
    <View>
      <TextInput
        placeholder='New Customer'
        placeholderTextColor={colors.placeholder}
        style={[globalStyles.field, styles.field]}
        onChangeText={(value) => setOrganisation(value)}
        onBlur={handleBlur}
        value={organisation}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  field: {
    marginTop: 20,
    backgroundColor: 'white',
    flex: 1,
    borderRadius: radius.sm,
    paddingHorizontal: 10,
    paddingVertical: 15,
  },
});

export default NewOrganisationForm;
