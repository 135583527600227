import { useState, useEffect } from 'react';
import { TextInput, View, Image, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';

import Button from '../../components/Button';
import { colors, padding, radius } from '../../constants/theme';
import { default as Text } from '../../components/AppText';
import { postRepair } from './firestopSlice';
import { CurrentDateTime, DatePicker } from '../../functions/date';
import Preview from '../../components/Preview';
import Modal from '../../components/Modal';
import ElementPhotograph from './ElementPhotograph';
import ElementPhotographThumbnail, { getPhotoFromDB } from './ElementPhotographThumbnail';

const RepairForm = ({ repair, setShowForm, setSelectedSurvey, showBackButton = false }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  console.log(repair)

  const [repairDate, updateRepairDate] = useState(repair.date == "" ? CurrentDateTime("Y-m-d") : repair.date);
  const [photograph, setPhotograph] = useState(repair?.photograph);
  const [uri, setUri] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (photograph) {
      getPhotoFromDB('repair-photos', photograph.id, (data) => {
        setUri(data.photograph_uri);
      });
    }
  }, [photograph]);

  const handlePreview = () => {
    setShowPreview(true);
  }

  const handleBack = () => {
    navigation.goBack();
  }

  if (!repair) {
    return null;
  }

  return(
    <Formik initialValues={{...repair }} onSubmit={(values) => {
      dispatch(postRepair([{ ...values, photograph, status: 'repaired', date: repairDate }]))
        .unwrap()
        .then(() => { 
          setShowForm(0);
          setSelectedSurvey(0);
        })
        .catch(err => console.log(err));
    }}>
      {
        ({ handleChange, handleBlur, handleSubmit, values }) => (
        <View style={[styles.form, { zIndex: 1000 }]}>
          <View style={styles.fieldBlock}>
            <Text style={styles.fieldLabel}>Repair Notes</Text>
            <TextInput style={[styles.fieldInput, { height: 200 }]} onChangeText={handleChange('notes')} onBlur={handleBlur('notes')} value={values.notes} multiline={true} />
          </View>
          <View style={styles.fieldBlock}>
            <Text style={styles.fieldLabel}>Repair Photograph</Text>
            <View style={styles.uploadContainer}>
              <ElementPhotograph name='repair_photograph' collection="repair-photos" setPhotograph={setPhotograph} />
              <Image source={{ uri }} resizeMode='contain' style={{ width: 100, height: 100 }} />
            </View>
            {uri ? (
              <Button icon={<Preview />} variant="transparent" style={{ padding: 0 }} onPress={handlePreview} />
            ) : null}
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', zIndex: 1000 }}>
            <View style={{ ...styles.fieldBlock, width: '50%' }}>
              <Text style={styles.fieldLabel}>Repair Date</Text>
              { /*<TextInput style={styles.fieldInput} onChangeText={handleChange('date')} onBlur={handleBlur('date')} value={values.date} /> */ }
              <DatePicker name="date" style={styles.fieldInput} value={repairDate} setValue={updateRepairDate} />
            </View>
          </View>
          <View style={styles.buttonsContainer}>
            <Button style={{ marginRight: 16 }} onPress={handleSubmit} title='Save & Close' variant='orange' />
            {showBackButton && navigation.getState()?.routes.length>1 && 
              <Button title="Back" onPress={handleBack} />
            }
          </View>
          <Modal showModal={showPreview} setShowModal={() => setShowPreview(false)} containerStyle={{ maxWidth: 600 }}>
            <View>
              <Text variant='heading'>Repair Photograph</Text>
              <View style={{ marginTop: 20, width: 540 }}>
                <ElementPhotographThumbnail photo={photograph?.id} collection="repair-photos" size={{ width: '100%', height: 425 }} />
              </View>
            </View>
          </Modal>
        </View>
        )
      }
    </Formik>
  );
}


const styles = StyleSheet.create({
	form: {
		paddingHorizontal: padding.sm,
		paddingVertical: padding.sm,
	},
	fieldBlock: {
		paddingVertical: padding.sm,
		flexDirection: 'row',
		alignItems: 'flex-start'
	},
	fieldLabel: {
		width: '150px',
		marginTop: 8,
    fontWeight: 600
	},
	fieldInput: {
		paddingHorizontal: padding.sm,
		paddingVertical: padding.sm,
		flex: 1,
		backgroundColor: '#ffffff'
	},
	fieldContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  field: {
    backgroundColor: 'white',
    flex: 1,
    borderRadius: radius.sm,
    paddingHorizontal: 10,
    paddingVertical: 15,
  },
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
    marginTop: 16
	},
  uploadContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: colors.white,
    borderRadius: radius.sm,
    paddingHorizontal: padding.sm,
    paddingVertical: padding.sm,
    marginRight: 16
  },
});

export default RepairForm;