import { useContext, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';

import { default as Text } from '../../components/AppText';
import Trash from '../../components/Trash';
import Button from '../../components/Button';
import { optionRemoved } from './costsSlice';
import OptionsContext from './OptionsContext';
import EditOptionForm from './EditOptionForm';
import Modal from '../../components/Modal';

const OptionsItem = ({ option, selectedOption, setSelectedOption, selectedCost }) => {
	const dispatch = useDispatch();
	const { id, title, price } = option;
	const { setShowSortButton, canSort } = useContext(OptionsContext);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const showEditOptionPanel = selectedOption === id;

	const handleConfirmDelete = () => {
		dispatch(optionRemoved(id));
		setConfirmDelete(false);
	}

	const handleEdit = () => {
		setSelectedOption(id);
		setShowSortButton(false);
	};

	return (
		<>
			<View style={styles.row}>
				<Text style={styles.title}>{`${title + (selectedCost?.calculation === 3 ? 'ml' : '')}`}</Text>
				<Text style={styles.price}>£{Number(price).toFixed(2)}</Text>
				{!canSort && 
					<>
						<View style={styles.delete}>
							<Button
								icon={<Trash />}
								variant='transparent'
								onPress={() => setConfirmDelete(true)}
							/>
						</View>
						<View style={styles.edit}>
							<Button
								title='Edit'
								variant='secondary'
								size='sm'
								onPress={handleEdit}
								disabled={showEditOptionPanel}
								style={{ opacity: showEditOptionPanel ? 0.6 : 1 }}
							/>
						</View>
					</>
				}
			</View>
			{showEditOptionPanel ? (
				<EditOptionForm option={option} setSelectedOption={setSelectedOption} selectedCost={selectedCost} />
			) : null}
			<Modal type='confirmation' showModal={confirmDelete} onConfirmation={handleConfirmDelete} onCancel={() => setConfirmDelete(false)}>
				<Text>Are you sure you want to delete?</Text>
			</Modal>
		</>
	);
};

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	icon: {
		width: '5%',
	},
	title: {
		width: '40%',
	},
	price: {
		width: '25%',
	},
	delete: {
		width: '15%',
	},
	edit: {
		width: '15%',
		alignItems: 'center',
	},
});

export default OptionsItem;
