import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { FireStopAPI, APIError } from '../../services/api';
import { findValueInColumn } from '../../functions/array';
import { updateSync } from '../sync/syncSlice';

const initialState = {
  costs: [],
  selectedCost: null,
  isNew: false,
  status: 'idle', // idle, loading, succeeded, failed
  error: null,
};

export const fetchCosts = createAsyncThunk('costs/fetchCosts', async () => {
  try {
    const { data } = await FireStopAPI.get('/v1/index.php', {
		action: "costs"
	});

    return data.costs;
  } catch (error) {
    console.error(error);
  }
});

export const postCosts = createAsyncThunk('costs/postCost', async (Integer_Cost, Slice) =>
{
	const data = FireStopAPI.post('/v1/index.php',
	{
		data: Slice.getState().costs.costs[findValueInColumn(Integer_Cost, Slice.getState().costs.costs, "id")],
		action: "costs"
	}).catch(APIError);

  return data;
});

export const addNewCost = createAsyncThunk('costs/addNewCost', async (initialCost, Slice) =>
{
	if(initialCost.id === undefined)
	{
		initialCost = { ...initialCost, id: 'NEW_' + Slice.getState().costs.costs.length + 1 }
	}

	const response = await FireStopAPI.post('/v1/index.php',
	{
		data: initialCost,
		action: 'costs'
	}).catch((error) => {
    Slice.dispatch(updateSync({ type: 'costs', id: initialCost.id }));

    return APIError(error);
  });

	response.data.original = initialCost;

	return response;
});

export const updateCost = createAsyncThunk('costs/updateCost', async (initialCost, ThunkAPI) =>
{
	let response = await FireStopAPI.put('/v1/index.php',
	{
		data: initialCost,
		action: 'costs'
	}).catch((error) => {
    ThunkAPI.dispatch(updateSync({ type: 'costs', id: initialCost.id }));

    return APIError(error);
  });

	response.data.original = { ...initialCost, id: initialCost.id };

	return response;
});

export const deleteCost = createAsyncThunk(
  'costs/deleteCost',
  async (cost, ThunkAPI) => {
    await FireStopAPI.delete('/v1/index.php', {
			params: {
				action: 'costs',
        id: cost.id,
			}
		}).catch((error) => {
      ThunkAPI.dispatch(updateSync({ type: 'costs', id: cost.id }));

      return APIError(error);
    });
  
    return cost;
  }
);

export const costsSlice = createSlice({
  name: 'costs',
  initialState,
  reducers: {
    costAdded(state, action) {
      if (!action.payload?.id) {
        action.payload.id = `NEW_${state.costs.length + 1}`;
        action.payload.options = [];
      } else {
        action.payload.options = state.selectedCost.options;
      }

      action.payload.price = Number(action.payload.price).toFixed(2);
      action.payload.deleted = 0;
      state.selectedCost = action.payload;
      state.isNew = true;
    },
    costUpdated(state, action) {
      state.selectedCost = action.payload;
    },
    clearSelectedCost(state, action) {
      state.selectedCost = null;
    },
    clearSelectedCostOptions(state, action) {
      if (state.selectedCost) {
        state.selectedCost.options = [];
      }
    },
    optionAdded(state, action) {
      if (!action.payload?.id) {
        action.payload.id = `NEW_${state.selectedCost.options.length + 1}`;
        state.selectedCost.options.push({ ...action.payload, deleted: 0 });
      } else {
        const updatedOptions = state.selectedCost.options.map((option) => {
          if (option.id === action.payload.id) {
            return { ...option, ...action.payload };
          }
          return option;
        });
        state.selectedCost.options = updatedOptions;
      }
    },
    optionRemoved(state, action) {
      const newOptions = state.selectedCost.options.map((option) => {
        if (option.id === action.payload) {
          return { ...option, deleted: 1 };
        }
        return option;
      });

      state.selectedCost.options = newOptions;
    },
    shiftOption(state, action) {
      let { from, to } = action.payload;

      let sortedOptions = [...state.selectedCost.options];

      let shiftedOption = sortedOptions.splice(from, 1)[0];
      sortedOptions.splice(to, 0, shiftedOption);

      state.selectedCost.options = sortedOptions;
    },
    sortOptions(state, action) {
      console.log(action.payload);
      state.selectedCost.options = action.payload;
    },
    clearOptions(state, action) {},
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCosts.fulfilled, (state, action) => {
        state.costs = action.payload;
      })
      .addCase(postCosts.fulfilled, (state, action) => {
		try
		{
			if(action.payload.data.status == "ok" && action.payload.data.cost !== undefined)
			{
				let costUpdates = Object.entries(action.payload.data.cost)[0];
				let newCostArray = state.costs[findValueInColumn(costUpdates[0], state.costs, "id")];

				if(costUpdates[1].id !== undefined)
				{	
					// Remove from the local storage first if NEW_#
					delete state.cost[costUpdates[0]];
				}

				newCostArray.options.map((option) => 
				{
					if(costUpdates[1].options[option.id] !== undefined)
					{
						option.id = costUpdates[1].options[option.id];
					}
				});

				newCostArray.id = costUpdates[1].id;
				state.costs[costUpdates[1].id] = newCostArray;

				// update surveyEntries remedial_work_costs.
			}

			return state;
		}
		catch(e)
		{
			console.error(e.message);
		}
      })
      .addCase(addNewCost.fulfilled, (state, action) => {
        let newCostArray = { ...action.payload.data.original };

        const originalId = newCostArray.id;

        if(action.payload.data.status == "ok" && action.payload.data.cost !== undefined)
        {
          newCostArray.id = Object.entries(action.payload.data.cost)[0][1].id;

          if (newCostArray?.options.length > 0) {
            newCostArray.options = newCostArray.options.map((option) => {
              if (typeof option.id === 'string' && option.id.includes('NEW')) {
                if (action.payload.data.cost[originalId]) {
                  return {
                    ...option,
                    id: action.payload.data.cost[originalId].options[option.id]
                  };
                }
              }

              return option;
            });
          }
        }

        const costExists = state.costs.find((cost) => cost.id === originalId);

        if (costExists) {
          state.costs = state.costs.map((cost) => {
            if (cost.id === originalId) {
              return newCostArray
            }

            return cost;
          });
        } else {
          state.costs.push(newCostArray);
        }
        state.selectedCost = null;
        state.isNew = false;
      })
      .addCase(updateCost.fulfilled, (state, action) => {
        let newCostArray = action.payload?.data?.original;

        if (newCostArray?.calculation === 2) {
          newCostArray.options = [];
        }

        const originalId = newCostArray.id;

        if(action.payload.data.status == "ok" && action.payload.data.cost !== undefined)
        {
          newCostArray.id = Object.entries(action.payload.data.cost)[0][1].id;
          
          if (newCostArray?.options.length > 0) {
            newCostArray.options = newCostArray.options.map((option) => {
              if (typeof option.id === 'string' && option.id.includes('NEW')) {
                if (action.payload.data.cost[originalId]) {
                  return {
                    ...option,
                    id: action.payload.data.cost[originalId].options[option.id]
                  };
                }
              }

              return option;
            });
          }
        }
        
        state.costs = state.costs.map((cost) => {
          if (cost.id === originalId) {
            return { ...newCostArray, id: originalId };
          }

          return cost;
        });

        state.selectedCost = null;
      })
      .addCase(deleteCost.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          return;
        }

        state.costs = state.costs.map((cost) => cost.id === action.payload.id ? { ...cost, deleted: 1 } : cost);
      });
  },
});

export const selectCosts = (state) =>
  state.costs.costs.filter((cost) => cost.deleted === 0);
export const getSelectedCost = (state) => state.costs.selectedCost;
export const selectCostById = (state, costId) =>
  state.costs.costs.find((cost) => cost.id === costId);
export const selectIsNew = (state) => state.costs.isNew;

export const {
  costAdded,
  optionAdded,
  optionRemoved,
  shiftOption,
  costUpdated,
  clearSelectedCost,
  clearOptions,
  sortOptions,
  clearSelectedCostOptions,
} = costsSlice.actions;

export default costsSlice.reducer;
