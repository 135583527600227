import { current, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIError, FireStopAPI } from '../../services/api';
import { postSurvey } from '../firestop/firestopSlice';
import { checkIfNew } from '../../functions/array';
import { updateSync } from '../sync/syncSlice';

export const getOrganisations = createAsyncThunk(
  'surveys/getOrganisations',
  async () => {
    return FireStopAPI.get('/v1/index.php', {
      params: { action: 'organisations' },
    }).catch(APIError);
  }
);

export const deleteOrganisation = createAsyncThunk('surveys/deleteOrganisation', async (organisation, { dispatch }) => {
	if(!checkIfNew(organisation))
	{
		await FireStopAPI.delete('/v1/index.php', {
			params: {
				action: "organisations",
				id: organisation,
			}
		}).catch((err) => {
      dispatch(updateSync({ type: 'organisations', id: organisation }));
      return APIError(err);
    });
	}

	return organisation;
});

export const saveOrganisation = createAsyncThunk(
  'surveys/saveOrganisation',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await FireStopAPI.post('/v1/index.php', {
        action: 'organisations',
        data: { organisation: data },
      });

      return { data: response.data, organisation: data };
    } catch (error) {
      dispatch(updateSync({ type: 'organisations', id: data.id }));
      return rejectWithValue(data);
    }
  }
);

const initialState = {
  organisations: [],
  newOrganisationId: null,
  selectedOrganisation: null,
  isLoading: false,
};

const organisationsSlice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    setSelectedOrganisation(state, action) {
      const id = action.payload;
      const selectedOrganisation = state.organisations.find(
        (organisation) => organisation.id === id
      );

      if (selectedOrganisation) {
        state.selectedOrganisation = selectedOrganisation;
      }
    },
    setOrganisationName(state, action) {
      state.selectedOrganisation.name = action.payload;
    },
    addOrganisation(state) {
      const newId = `NEW_${Number(state.organisations.length) + 1}`;

      state.selectedOrganisation = {
        id: newId,
        name: '',
        addresses: [],
        deleted: 0,
      };
      state.newOrganisationId = newId;
    },
    updateOrganisation(state, action) {
      state.organisations = state.organisations.map((organisation) => {
        if (organisation.id === action.payload.id) {
          return state.selectedOrganisation;
        } else {
          return organisation;
        }
      });
    },
    addNewOrganisation(state, action) {
      const organisation = state.organisations.find((organisation) => organisation.id === action.payload.id);
      
      if (!organisation) {
        state.organisations.push(action.payload);
      }
    },
    resetNewOrganisationId(state) {
      state.newOrganisationId = null;
    },
    addAddress(state, action) {
      const { id, label } = action.payload;
      const newId = `NEW_${
        Number(state.selectedOrganisation.addresses.length) + 1
      }`;

      if (id) {
        let selectedAddress = state.selectedOrganisation.addresses.find(
          (address) => address.id === id
        );

        selectedAddress.label = label;

        state.selectedOrganisation.addresses.map((address) => {
          if (address.id === id) {
            return selectedAddress;
          } else {
            return address;
          }
        });
      } else {
        state.selectedOrganisation.addresses.push({
          id: newId,
          label,
          deleted: 0,
        });
      }
    },
    addAddressToOrganisation(state, action) {
      const { organisationId, address } = action.payload;

      const existingOrganisation = state.organisations.find((organisation) => organisation.id === organisationId);

      if (existingOrganisation) {
        state.organisations = state.organisations.map((organisation) => {
          if (organisation.id === organisationId) {
            return { ...organisation, addresses: [...organisation.addresses, {
              id: `NEW_${Number(organisation.addresses.length) + 1}`,
              label: address,
              deleted: 0
            }] }
          }

          return organisation;
        })
      }
    },
    removeAddress(state, action) {
      const { id } = action.payload;

      state.selectedOrganisation.addresses =
        state.selectedOrganisation.addresses.map((address) => {
          if (address.id === id) {
            return {
              ...address,
              deleted: 1,
            };
          }

          return address;
        });

      const organisationToUpdate = state.organisations.find(
        (organisation) => organisation.id === state.selectedOrganisation.id
      );

      if (organisationToUpdate) {
        const updatedAddresses = organisationToUpdate.addresses.map(
          (address) => {
            if (address.id === id) {
              return {
                ...address,
                deleted: 1,
              };
            }

            return address;
          }
        );

        state.organisations = state.organisations.map((organisation) => {
          if (organisation.id === organisationToUpdate.id) {
            return { ...organisationToUpdate, addresses: updatedAddresses };
          }

          return organisation;
        });
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getOrganisations.fulfilled, (state, action) => {
        state.organisations = action.payload.data?.organisations;
      })
      .addCase(saveOrganisation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveOrganisation.fulfilled, (state, action) => {
        // state.organisations = action.payload.data?.organisations;
        const updatedOrganisations =
          action.payload.data?.data?.update_organisations?.organisations;
        const updatedAddresses =
          action.payload.data?.data?.update_organisations?.addresses;

        let selectedOrganisation = { ...action.payload.organisation };

        if (selectedOrganisation) {
          const filteredOrganisations = state.organisations.filter(
            (organisation) => organisation.id !== selectedOrganisation.id
          );

          if (updatedOrganisations) {
            Object.keys(updatedOrganisations).forEach((organisation) => {
              selectedOrganisation.id = updatedOrganisations[organisation];
            });

            if (updatedAddresses) {
              selectedOrganisation.addresses =
                selectedOrganisation.addresses.map((address) => {
                  if (typeof address?.id === 'string') {
                    if (address?.id.includes('NEW_')) {
                      return {
                        ...address,
                        id: updatedAddresses[address?.id],
                      };
                    }
                  }

                  return address;
                });
            }

            state.organisations = [
              ...filteredOrganisations,
              selectedOrganisation,
            ];
          }

          state.selectedOrganisation = selectedOrganisation;
        } else {
          console.error('No selected organisation');
        }
        state.isLoading = false;
      })
      .addCase(saveOrganisation.rejected, (state, action) => {
        const organisationExists = state.organisations.find(
          (organisation) => organisation.id === action.payload.id
        );

        if (organisationExists) {
          state.organisations = state.organisations.map((organisation) => {
            if (organisation.id === state.selectedOrganisation.id) {
              return { ...organisation, ...action.payload };
            } else {
              return organisation;
            }
          });
        } else {
          state.organisations.push(action.payload);
          state.selectedOrganisation = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(deleteOrganisation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteOrganisation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organisations = state.organisations.map((organisation) => {
          if (organisation.id === action.payload) {
            return {
              ...organisation,
              deleted: 1,
            };
          } else {
            return organisation;
          }
        });
      })
      .addCase(deleteOrganisation.rejected, (state, action) => {
        state.isLoading = false;
        state.organisations = state.organisations.map((organisation) => {
          if (organisation.id === action.payload) {
            return {
              ...organisation,
              deleted: 1,
            };
          } else {
            return organisation;
          }
        });
      })
      .addCase(postSurvey.fulfilled, (state, action) => {
        const organisations = action.payload.data?.organisation?.organisations;
        const addresses = action.payload.data?.organisation?.addresses;

        if (organisations) {
          Object.keys(organisations).forEach((organisationKey) => {
            state.organisations = state.organisations.map((organisation) => {
              if (organisation.id === organisationKey) {
                return { ...organisation, id: organisations[organisationKey] };
              }
              return organisation;
            });
          });
        }

        if (addresses) {
          Object.keys(addresses).forEach((addressKey) => {
            state.organisations = state.organisations.map((organisation) => {
              const updatedAddresses = organisation.addresses.map((address) => {
                if (address.id === addressKey) {
                  return { ...address, id: addresses[addressKey] };
                }
                return address;
              });
              return { ...organisation, addresses: updatedAddresses };
            });
          });
        }
      });
  },
});

export const selectOrganisations = (state) => state.organisations.organisations;
export const getSelectedOrganisation = (state) =>
  state.organisations.selectedOrganisation;
export const selectNewOrganisationId = (state) =>
  state.organisations.newOrganisationId;
export const selectOrganisationById = (state, id) =>
  state.organisations.organisations.find(
    (organisation) => organisation.id === id
  );

export const {
  setSelectedOrganisation,
  addAddress,
  addAddressToOrganisation,
  removeAddress,
  addOrganisation,
  addNewOrganisation,
  setOrganisationName,
  resetNewOrganisationId,
} = organisationsSlice.actions;

export default organisationsSlice.reducer;
