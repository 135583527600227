import { useState, useEffect } from 'react';
import { Text, View, Image, StyleSheet, TextInput, TouchableOpacity, ScrollView, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import { FontAwesome5 } from '@expo/vector-icons';
// import NoFire from '../../assets/no-fire.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Layout from '../containers/Layout';
import { colors, fonts, padding, radius } from '../constants/theme';
import Button from '../components/Button';
import AppText from '../components/AppText';
import TotalSrc from '../assets/no-fire.svg';
import { differenceInDays } from 'date-fns';

import { resumeSurvey, selectSurveys, selectEntries, selectRepairs, clearSelectedSurvey, startNewSurveyEntry } from '../features/firestop/firestopSlice';
import { selectUser } from '../features/auth/authSlice';
import { calculateDeadline } from '../functions/calculations';

function FireStopDashboardScreen()
{
	const dispatch = useDispatch();
	const navigation = useNavigation();

	const user = useSelector(selectUser);
	const surveyList = useSelector(selectSurveys);
	const entriesList = useSelector(selectEntries);
	const repairsList = useSelector(selectRepairs);
	const [clear, refresh] = useState(false);

	const [searchText, setSearchText] = useState('');
	const [viewAll, setViewAll] = useState(false);

	// const test = calculateDeadline('2023-07-07 04:00:00', '2023-07-07 00:00:00', 'year');

	const handleStartSurvey = (surveyID) => {
		dispatch(startNewSurveyEntry({ surveyId: surveyID }));
		navigation.push('FireStopElements');
	}

	const handleResumeOrPDF = (entryID, surveySecretKey, completed) => {
		if (completed && surveySecretKey) {
			// send to PDF
			window.open(`https://firestop.webglu35.co.uk/report/index.php?esk=${surveySecretKey}&csk=${user.secret_key}`, '_blank').focus();
		} else {
			dispatch(resumeSurvey({ entryId: entryID }));
			
			navigation.navigate('FireStopElements');
		}		
	}

	const handleViewSummary = (surveyId, entryId) => {
		navigation.navigate('FireStopStack', {
			screen: 'FireStopStats',
			params: { surveyId, entryId },
		});
	};

	const handleListing = (surveyID) => {
		navigation.navigate('FireStopStack', { screen: 'FireStopSurveyDashboard', params: { surveyId: surveyID } });
	}

	const SurveyItem = (props) => 
	{
		let Object_Values = { "name": "Name", "type": "Type", "total": <Image source={TotalSrc} style={{ width: 18, height: 18 }} />, "failed": <FontAwesome5 name='times' size={18} color={colors.error} />, "passed": <FontAwesome5 name='check' size={18} color={colors.success} />, "due": "Due", "actions": <AppText></AppText> };

		if(props.survey != null)
		{
			Object_Values.name = props.survey.surveyName;
			Object_Values.type = props.survey.surveyType;
			Object_Values.total = Object.entries(props.survey.elements).filter((element) => element.deleted === 0).length;
			Object_Values.failed = 0;
			Object_Values.passed = 0;
			Object_Values.actions = <Button title="Start" variant="" size="sm" onPress={() => handleStartSurvey(props.survey.id)} />;

			Object_Values.due = "Overdue";

			if(props.entry != null)
			{
				Object_Values.total = Object.keys(props.entry.elements).length;
				
				Object.entries(props.entry.elements).map((element) => Object_Values[element[1].status]++);

				Object_Values.due = props.entry.status[0].toUpperCase() + props.entry.status.slice(1);

				Object_Values.actions = (
					<>
						{(props.entry.status === "Complete" && toString(props.entry.id).split("_")[0] === "NEW" ? <Button 
							title="Sync"
							size="sm"
							onPress={() => console.log("Syncing")}
							style={{ marginRight: 10 }}
						/> : <Button
							title={(props.entry.status === "Complete" ? 'PDF' : 'Resume')}
							variant={props.variant}
							size="sm"
							onPress={() => handleResumeOrPDF(props.entry.id, props.entry?.entry_secret_key, (props.entry.status === "Complete"))}
							style={{ marginRight: 10 }}
						/>)}
						<Button
							title="List"
							variant={props.variant}
							size="sm"
							onPress={() => handleListing(props.survey.id)}
							style={{ marginRight: 10 }}
						/>
						{props.entry.id !== undefined && Object_Values.due === 'Complete' ? (
							<Button
								title="View"
								variant={props.variant}
								size="sm"
								onPress={() => handleViewSummary(props.survey.id, props.entry.id)}
							/>
						) : null}
					</>
				);
			}
		}

		// Either header or a valid listing
		if(props.variant == "column" || props.survey != null)
		{
			return(
				<View style={props.style}>
					<View style={styles.columnName}>
						<AppText variant={props.variant} size='md'>{ Object_Values.name }</AppText>
					</View>
					<View style={styles.columnType}>
						<AppText variant={props.variant} size='md' style={{ textAlign: 'center' }}>{ Object_Values.type }</AppText>
					</View>
					<View style={styles.columnTotal}>
						<AppText variant={props.variant} size='md' style={{ textAlign: 'center' }}>{ Object_Values.total }</AppText>
					</View>
					<View style={styles.columnFailed}>
						<AppText variant={props.variant} size='md' style={{ textAlign: 'center' }}>{ Object_Values.failed }</AppText>
					</View>
					<View style={styles.columnPassed}>
						<AppText variant={props.variant} size='md' style={{ textAlign: 'center' }}>{ Object_Values.passed }</AppText>
					</View>
					<View style={styles.columnStatus}>
						<AppText variant={props.variant} size='md' style={{ textAlign: 'center' }}>{ Object_Values.due }</AppText>
					</View>
					<View style={styles.columnActions}>{Object_Values.actions}</View>
				</View>
			);
		}
	}

	// Entry is used to list recent ones.  Form is used to get the most recent per form.  Sort only has to happen once
	let Array_FormsOrdered = {"form": {}, "entry": []};

	// update to use the new date function.  remove 1 year
	const current_date = new Date();

	if(entriesList !== undefined)
	{
		for(let entry of Object.entries(entriesList).sort(function(a,b) { return new Date(b[1].created) - new Date(a[1].created); }))
		{
			if(searchText === '' || ((searchText !== '' && (surveyList[entry[1].survey] !== undefined && surveyList[entry[1].survey].surveyName.toLowerCase().includes(searchText.toLowerCase())))))
			{
				if(Array_FormsOrdered.form[entry[1].survey] === undefined)
				{
					Array_FormsOrdered.form[entry[1].survey] = [];
				}

				if(viewAll || entry[1].created.replaceAll("-", "").split(" ")[0] > (current_date.getFullYear() - 1) + "" + current_date.getMonth() + current_date.getDate())
				{
					Array_FormsOrdered.form[entry[1].survey].push(entry[0]);
					Array_FormsOrdered.entry.push(entry[0]);
				}
			}
		}
	}

	let dueSurveys = [];

	if(surveyList !== undefined)
	{
		for(let [id, survey] of Object.entries(surveyList))
		{
			let lastSurvey = null;

			if(Array_FormsOrdered.form[id] !== undefined && entriesList[Array_FormsOrdered.form[id][0]] != null)
			{
				// Get the latest entry that has been completed.
				lastSurvey = Array_FormsOrdered.form[id].map((entry) =>
				{
					if(entriesList[entry] != null && !!entriesList[entry].completed)
					{
						return entriesList[entry].completed;
					}
				})[0];
			}

			let surveyDeadline = calculateDeadline(survey.created, lastSurvey, survey.frequency, 1);

			// Survey due if last entry or survey created cannot be found.
			if(!surveyDeadline || differenceInDays(new Date(), surveyDeadline) > 0)
			{
				dueSurveys.push(id);
			}
		}
	}

	let Integer_Repairs = 0;

	if(repairsList !== undefined)
	{
		Object.entries(repairsList).map((repair) => (
			(repair[1].status == "outstanding" ? Integer_Repairs++ : 0)
		));
	}

	const handleSearch = () => {
		setSearchText(searchText);
	};

	return (
		<Layout title='Fire Stopping App'>

			<ScrollView showsVerticalScrollIndicator={false}>

				<View style={styles.buttonContainer}>
					<Button title='Create New Survey' variant='secondary' onPress={() => { dispatch(clearSelectedSurvey()); navigation.push('FireStopSurveyForm')}} />
					<Button title='View All Surveys' variant='black' onPress={() => setViewAll(!viewAll)/*navigation.push('FireStopAllSurveys')*/} />
				</View>

				{(Integer_Repairs > 0 ?
					<View style={styles.repairsContainer}>
						<Text style={styles.repairsMessage}>There are currently {Integer_Repairs} fire stopping issue{Integer_Repairs > 1 ? "s" : ""} with outstanding repairs</Text>
						<Button title='View Now' variant='outlined' onPress={() => {
								dispatch(clearSelectedSurvey());
								navigation.push('FireStopRepairs');
							}
						} />
					</View> : null
				)}

				<View style={styles.surveysDueContainer}>
					<AppText heading size='xl'>{(searchText != "" ? "Searching for '" + searchText + "'" : (viewAll ? 'All Surveys' : 'Surveys Due')) }</AppText>
					<View style={styles.searchContainer}>
						<TextInput style={styles.searchInput} onChangeText={(value) => setSearchText(value)} value={searchText} />
						<TouchableOpacity style={styles.searchButton} onPress={handleSearch}>
							<FontAwesome5 name='search' color='#ffffff' />
						</TouchableOpacity>
					</View>
				</View>
				{!viewAll && searchText == "" && <>
						{(dueSurveys.length > 0 ? 
							<View>
								<SurveyItem variant='column' style={styles.listHeader} />
								{
									dueSurveys.map((item) => {
										let latestEntry = null;
										if(Array_FormsOrdered.form[item] !== undefined && entriesList[Array_FormsOrdered.form[item][0]] != null)
										{
											latestEntry = entriesList[Array_FormsOrdered.form[item][0]];
										}
										return <SurveyItem variant="secondary" key={item} survey={surveyList[item]} entry={latestEntry} style={[ styles.listItem, { flexDirection: 'row', backgroundColor: colors.white }, ]} />
									})
								}
							</View>
						: 
							<Text>No Surveys Due</Text>
						)}
						<View style={{ marginTop: 30 }}>
							<AppText heading size='xl'>Recent Surveys</AppText>
						</View>
					</>
				}
				{(surveyList !== undefined && Array_FormsOrdered.entry.length > 0 ?
					<View>
						<SurveyItem variant='column' style={styles.listHeader} />
						{
							Array_FormsOrdered.entry.map((index) => (
								<SurveyItem variant="secondary" key={index} survey={surveyList[entriesList[index].survey]} entry={entriesList[index]} style={[ styles.listItem, { flexDirection: 'row', backgroundColor: colors.white }, ]} />
							))
						}
					</View>
					: 
					<Text>{viewAll || searchText != "" ? 'No Surveys Found' : 'No Recent Surveys' }</Text>
				)}

				<Button style={{ width: '200px', marginTop: '50px'}} title='CLEAR STORAGE' variant='secondary' onPress={async () => { AsyncStorage.clear(); refresh(!clear); } } />

			</ScrollView>

		</Layout>
	);
}

const styles = StyleSheet.create({
  buttonContainer: {
	flexDirection: 'row',
	justifyContent: 'space-around',
	paddingVertical: padding.xxl,
  },
  repairsContainer: {
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: colors.primary,
	paddingVertical: padding.md,
	paddingHorizontal: padding.lg,
	borderRadius: radius.sm,
  },
  repairsMessage: {
	color: colors.white,
	marginRight: 40,
	fontWeight: '600',
	fontSize: fonts.md,
  },
  surveysDueContainer: {
	flexDirection: 'row',
	justifyContent: 'space-between',
	marginTop: 40,
  },
  searchContainer: {
	flexDirection: 'row',
	alignItems: 'center',
  },
  searchInput: {
	backgroundColor: colors.white,
	paddingVertical: padding.sm,
	paddingHorizontal: padding.sm,
	borderRadius: radius.sm,
	maxWidth: 150,
	marginRight: 20,
  },
  searchButton: {
	backgroundColor: colors.secondary,
	paddingVertical: padding.sm,
	paddingHorizontal: padding.sm,
	borderRadius: radius.md,
  },
  listHeader: {
	flexDirection: 'row',
	marginTop: 10,
	paddingVertical: 15,
  },
  listItem: {
	alignItems: 'center',
	paddingVertical: padding.sm,
	paddingHorizontal: padding.sm,
	marginTop: 8,
  },
  columnName: {
	width: '30%',
  },
  columnType: {
	width: '10%',
  },
  columnTotal: {
	width: '7.5%',
  },
  columnFailed: {
	width: '7.5%',
  },
  columnPassed: {
	width: '7.5%',
  },
  columnDue: {
	width: '10%',
  },
  columnStatus: {
	width: '12.5%',
  },
  columnActions: {
		width: '15%',
		flexDirection: 'row'
  },
});

export default FireStopDashboardScreen;