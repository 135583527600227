import { useContext, useState, useEffect, useCallback } from 'react';
import { TextInput, View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import DropDownPicker from 'react-native-dropdown-picker';

import Button from '../../components/Button';
import { default as Text } from '../../components/AppText';
import { colors, radius } from '../../constants/theme';
import {
    addNewCost,
    clearSelectedCost,
    costAdded,
    clearSelectedCostOptions,
} from './costsSlice';
import OptionsContext from './OptionsContext';

import { globalStyles } from '../../styles/GlobalStyles';
import { usePrevious } from '../../hooks/usePrevious';

function AddCostForm({ selectedCost, selectedOptions }) {
    const navigation = useNavigation();
    const dispatch = useDispatch();

    const [title, setTitle] = useState(selectedCost?.title || '');
    const [description, setDescription] = useState(
        selectedCost?.description || ''
    );
    const [calculation, setCalculation] = useState(
        selectedCost?.calculation || ''
    );
    const [calculationItems, setCalculationItems] = useState([
        { label: 'Flat Rate', value: 1 },
        { label: 'Price per Square Meter', value: 2 },
        { label: 'Mastic Price per Millilitre', value: 3 },
    ]);
    const [calculationIsOpen, setCalculationIsOpen] = useState(false);
    const [price, setPrice] = useState(selectedCost?.price || '');
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [titleError, setTitleError] = useState(false);
    const [calculationError, setCalculationError] = useState(false);
    const [priceError, setPriceError] = useState(false);

    const { setShowAddCostsPanel } = useContext(OptionsContext);

    const previousCalculation = usePrevious(calculation);

    const canSave =
        [title, calculation].every(Boolean) &&
        ((selectedCost?.options.filter((option) => option.deleted === 0)
            .length > 0 &&
            Number(price) === 0) ||
            Number(price) > 0) &&
        addRequestStatus === 'idle';

    const isMastic = calculation === 3 ? true : false;

    const handleOptions = () => {
        if (!selectedCost) {
            dispatch(costAdded({ title, description, calculation, price }));
        }

        navigation.navigate('CostsOptions');
    };

    const handlePriceChange = (value) => {
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(value) || value === '') {
            setPrice(value);
        }
    };

    const handleClear = () => {
        setTitle('');
        setDescription('');
        setCalculation('');
        setPrice('');
        setCalculationError('');
        setPriceError('');
        dispatch(clearSelectedCost());
    };

    const handleSave = () => {
        if (canSave) {
            try {
                setAddRequestStatus('loading');
                if (selectedCost) {
                    const optionsToAdd = selectedCost.options.filter(
                        (option) => option.deleted === 0
                    );

                    dispatch(
                        addNewCost({
                            id: selectedCost.id,
                            title,
                            description,
                            calculation,
                            price: optionsToAdd.length > 0 ? 0 : price,
                            options: optionsToAdd,
                            deleted: 0,
                        })
                    );
                } else {
                    dispatch(
                        addNewCost({
                            title,
                            description,
                            calculation,
                            price,
                            options: [],
                            deleted: 0,
                        })
                    );
                }

                setTitle('');
                setDescription('');
                setCalculation('');
                setPrice('');
                setTitleError(false);
                setCalculationError(false);
                setPriceError(false);
            } catch (err) {
                console.error('Could not save costs ', err);
            } finally {
                setShowAddCostsPanel(false);
            }
        } else {
            if (!title) {
                setTitleError(true);
            }

            if (!calculation) {
                setCalculationError(true);
            }

            switch (calculation) {
                case 1:
                case 3:
                    if (Number(price) === 0 && !selectedCost) {
                        setPriceError(true);
                    } else if (
                        Number(price) === 0 &&
                        selectedCost.options.filter(
                            (option) => option.deleted === 0
                        ).length < 1
                    ) {
                        setPriceError(true);
                    }

                    break;

                case 2:
                default:
                    if (price === 0) {
                        setPriceError(true);
                    }

                    break;
            }
        }
    };

    useEffect(() => {
        // Clear options
        if (
            previousCalculation !== undefined &&
            calculation !== previousCalculation
        ) {
            dispatch(clearSelectedCostOptions());
        }

        if (calculation === 3) {
            setPrice('');
        }
    }, [calculation]);

    useFocusEffect(
        useCallback(() => {
            // Reload validation
            setTitleError(false);
            setCalculationError(false);
            setPriceError(false);

            if (selectedCost) {
                if (!title) {
                    setTitleError(true);
                }

                if (!calculation) {
                    setCalculationError(true);
                }

                switch (calculation) {
                    case 1:
                    case 3:
                        if (Number(price) === 0 && !selectedCost) {
                            setPriceError(true);
                        } else if (
                            Number(price) === 0 &&
                            selectedCost?.options.length < 1
                        ) {
                            setPriceError(true);
                        }

                        break;

                    case 2:
                    default:
                        if (price === 0) {
                            setPriceError(true);
                        }

                        break;
                }
            }
        }, [])
    );

    return (
        <View style={styles.addContainer}>
            <View style={styles.fieldContainer}>
                <Text style={styles.label}>
                    Cost Title <Text style={styles.required}>*</Text>
                </Text>
                <TextInput
                    style={globalStyles.field}
                    value={title}
                    onChangeText={(value) => setTitle(value)}
                />
                {titleError ? (
                    <Text style={globalStyles.error}>A title is required</Text>
                ) : null}
            </View>
            <View style={styles.fieldContainer}>
                <Text style={styles.label}>Description</Text>
                <TextInput
                    style={globalStyles.field}
                    value={description}
                    onChangeText={(value) => setDescription(value)}
                />
            </View>
            <View style={[styles.fieldContainer, { zIndex: 1000 }]}>
                <Text style={styles.label}>
                    Calculation <Text style={styles.required}>*</Text>
                </Text>
                <View style={{ marginRight: 16, flex: 1, maxWidth: 400 }}>
                    <DropDownPicker
                        placeholder='Please select a calculation type'
                        dropDownDirection='BOTTOM'
                        open={calculationIsOpen}
                        value={calculation}
                        items={calculationItems}
                        setOpen={setCalculationIsOpen}
                        setValue={setCalculation}
                        setItems={setCalculationItems}
                        style={globalStyles.dropdown}
                        dropDownContainerStyle={globalStyles.dropdownItem}
                    />
                </View>
                {calculationError ? (
                    <Text style={globalStyles.error}>
                        A calculation is required
                    </Text>
                ) : null}
            </View>
            <View style={styles.fieldContainer}>
                <Text style={styles.label}>
                    Price (£) <Text style={styles.required}>*</Text>
                </Text>
                {selectedOptions ? (
                    <TextInput
                        style={[globalStyles.field, globalStyles.disabledInput]}
                        value={'Various Sizes'}
                        editable={false}
                    />
                ) : (
                    <TextInput
                        style={[
                            globalStyles.field,
                            isMastic && globalStyles.disabledInput,
                            { width: 100, marginRight: 20 },
                        ]}
                        value={price}
                        onChangeText={handlePriceChange}
                        keyboardType='numeric'
                        editable={!isMastic}
                        selectTextOnFocus={!isMastic}
                    />
                )}
                {priceError ? (
                    <Text style={{ ...globalStyles.error, marginRight: 8 }}>
                        A price is required
                    </Text>
                ) : null}
                {calculation !== 2 ? (
                    <View
                        style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                        <Button title='Options' onPress={handleOptions} />
                        {selectedOptions ? (
                            <Text style={{ marginLeft: 8 }}>
                                {selectedOptions} Options added
                            </Text>
                        ) : null}
                    </View>
                ) : null}
            </View>
            <View style={styles.buttonContainer}>
                <Button
                    title='Save'
                    variant='secondary'
                    onPress={handleSave}
                    style={{ marginRight: 20 }}
                />
                <Button title='Clear' onPress={handleClear} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    addContainer: {
        paddingHorizontal: 40,
        paddingVertical: 16,
        marginTop: 20,
        backgroundColor: colors.darkGrey,
        borderRadius: radius.md,
    },
    label: {
        width: 100,
        paddingTop: 10,
    },
    fieldContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 40,
    },
    required: {
        color: colors.primary,
    },
});

export default AddCostForm;
