import { useState, useEffect } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import format from 'date-fns/format';

import { default as Text } from '../components/AppText';
import CollapsedItem from '../features/firestop/CollapsibleItem';
import Layout from '../containers/Layout';
import { colors, padding } from '../constants/theme';
import {
    selectEntryById,
    selectSurveyById,
    selectEntryTotalCost,
} from '../features/firestop/firestopSlice';
import Button from '../components/Button';
import ElementPhotographThumbnail, {
    getPhotoFromDB,
} from '../features/firestop/ElementPhotographThumbnail';
import PinMe from '../components/PinMe';
import { calculateCost } from '../functions/calculations';
import { moneyFormatter } from '../functions/utils';

const FireStopStatsScreen = () => {
    const route = useRoute();
    const navigation = useNavigation();
    const [showFloorplan, setShowFloorplan] = useState(null);

    const surveyId = route.params.surveyId;
    const survey = useSelector((state) => selectSurveyById(state, surveyId));

    const entryId = route.params.entryId;
    /* Remove completed when the variable has been added */
    // const entry = {...useSelector((state) => selectEntryById(state, entryId)), 'completed': '1970-01-01T00:00:00'};
    const entry = useSelector((state) => selectEntryById(state, entryId));

    const [floorplans, setFloorplans] = useState({});

    useEffect(() => {
        Object.entries(survey.floorplan).map(([id, plan]) => {
            getPhotoFromDB('images', plan.db_id, (data) => {
                setFloorplans((state) => {
                    let updatedArray = { ...state };
                    updatedArray[id] = data;
                    return updatedArray;
                });
            });
        });
    }, []);

    const costs = useSelector((state) => state.costs.costs);

    const entryElements = entry.elements.map((element) => {
        if (element) {
            const surveyElement = survey.elements.find(
                (el) => el.id === element.id
            );

            if (surveyElement) {
                return { ...element, ...surveyElement };
            }
        }

        return;
    });

    const filteredElements = entryElements.filter((element) => element);

    let element_statuses = { passed: 0, failed: 0 };
    Object.entries(filteredElements).map(
        (element) => element_statuses[element[1].status]++
    );

    const cost = useSelector((state) => selectEntryTotalCost(state, entryId));

    const total = (
        parseFloat(cost) *
        ((100 + parseFloat(entry.adjustment || 0)) / 100)
    ).toFixed(2);

    const handleBack = () => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate('FireStopStack', {
                screen: 'FireStopSurveyDashboard',
                params: { surveyId: surveyId },
            });
        }
    };

    return (
        <Layout>
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.container}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Text variant='heading' size='xl'>
                        Survey{' '}
                        {entry.status === 'Complete' && entry?.completed
                            ? 'completed on ' +
                              format(new Date(entry?.completed), 'dd/MM/yyyy')
                            : 'In Progress since ' +
                              format(new Date(entry?.created), 'dd/MM/yyyy')}
                    </Text>
                    <Button title='Back' onPress={handleBack} />
                </View>
                {/* Summary */}
                <CollapsedItem title='Summary'>
                    <View style={styles.row}>
                        <View style={[styles.row, { width: '100%' }]}>
                            <Text style={styles.label}>Survey Name: </Text>
                            <Text>{survey.surveyName}</Text>
                        </View>
                    </View>

                    <View style={[styles.outerRow, { marginTop: 16 }]}>
                        <View style={styles.row}>
                            <Text style={styles.label}>Survey Type: </Text>
                            <Text>{survey.surveyType}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Frequency: </Text>
                            <Text>
                                {survey.frequency === 'one off'
                                    ? 'One Off'
                                    : survey.frequency === 'month'
                                    ? 'Monthly'
                                    : 'Annual'}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Created: </Text>
                            <Text>
                                {format(new Date(entry.created), 'dd/MM/yyyy')}
                            </Text>
                        </View>
                    </View>

                    <View style={[styles.outerRow, { marginTop: 16 }]}>
                        <View style={styles.row}>
                            <Text style={styles.label}>Status: </Text>
                            <Text>{entry.status}</Text>
                        </View>
                        {!!entry.completed && (
                            <View style={styles.row}>
                                <Text style={styles.label}>Completed on: </Text>
                                <Text>
                                    {format(
                                        new Date(entry.completed),
                                        'dd/MM/yyyy'
                                    )}
                                </Text>
                            </View>
                        )}
                    </View>

                    <View
                        style={[
                            styles.outerRow,
                            {
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: 24,
                            },
                        ]}
                    >
                        <View style={styles.elementsContainer}>
                            <View>
                                <Text style={styles.label}>Breaches:</Text>
                            </View>
                            <View style={[styles.outerRow, { marginTop: 16 }]}>
                                <View
                                    style={[
                                        styles.elementContainer,
                                        { backgroundColor: colors.black },
                                    ]}
                                >
                                    <Text style={styles.elementLabel}>
                                        Total: {filteredElements.length}
                                    </Text>
                                </View>
                                {survey?.surveyPassFailType !== 0 ? (
                                    <View
                                        style={[
                                            styles.elementContainer,
                                            { backgroundColor: colors.success },
                                        ]}
                                    >
                                        <Text style={styles.elementLabel}>
                                            Passed: {element_statuses.passed}
                                        </Text>
                                    </View>
                                ) : null}
                                <View
                                    style={[
                                        styles.elementContainer,
                                        { backgroundColor: colors.error },
                                    ]}
                                >
                                    <Text style={styles.elementLabel}>
                                        Failed: {element_statuses.failed}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.greyContainer}>
                            <View
                                style={[
                                    styles.outerRow,
                                    { justifyContent: 'space-between' },
                                ]}
                            >
                                <Text style={styles.label}>Cost: </Text>
                                <Text style={styles.label}>
                                    {moneyFormatter.format(cost)}
                                </Text>
                            </View>
                            <View
                                style={[
                                    styles.outerRow,
                                    {
                                        justifyContent: 'space-between',
                                        marginTop: 8,
                                    },
                                ]}
                            >
                                <Text style={styles.label}>Adjustment: </Text>
                                <Text style={styles.label}>
                                    {entry.adjustment}%
                                </Text>
                            </View>
                            <View
                                style={[
                                    styles.outerRow,
                                    {
                                        justifyContent: 'space-between',
                                        marginTop: 8,
                                    },
                                ]}
                            >
                                <Text style={styles.label}>Total: </Text>
                                <Text style={styles.label}>
                                    {moneyFormatter.format(total)}
                                </Text>
                            </View>
                        </View>
                    </View>
                </CollapsedItem>

                {/* Elements */}
                {filteredElements.map((element) => (
                    <CollapsedItem key={element.id} title={element.name}>
                        <View style={styles.outerRow}>
                            <View style={[styles.row, { width: '100%' }]}>
                                <Text style={styles.label}>
                                    Name: {element.name}
                                </Text>
                            </View>
                        </View>

                        <View style={[styles.outerRow, { marginTop: 16 }]}>
                            <View style={styles.row}>
                                <Text style={styles.label}>Location: </Text>
                                <Text style={styles.value}>
                                    {element.location_other
                                        ? element.location_other
                                        : element.location}
                                </Text>
                            </View>
                            {/* <View style={styles.row}>
                <Text style={styles.label}>Material: </Text>
                <Text style={styles.value}>
                  {element.material_other
                    ? element.material_other
                    : element.material}
                </Text>
              </View> */}
                            <View style={styles.row}>
                                <Text style={styles.label}>Status: </Text>
                                <Text style={styles.value}>
                                    {element.status}
                                </Text>
                            </View>
                        </View>
                        {element.status === 'failed' ? (
                            <>
                                <View
                                    style={[styles.outerRow, { marginTop: 16 }]}
                                >
                                    <View style={styles.row}>
                                        <Text style={styles.label}>
                                            Fail Type:{' '}
                                        </Text>
                                        <Text style={styles.value}>
                                            {element.fail_type}
                                        </Text>
                                    </View>
                                    {element.fail_type === 'hole' ? (
                                        <>
                                            <View style={styles.row}>
                                                <Text style={styles.label}>
                                                    Hole Size:{' '}
                                                </Text>
                                                <Text style={styles.value}>
                                                    {element.hole_size ===
                                                    'other'
                                                        ? `${element.hole_size_other_width}mm x ${element.hole_size_other_length}mm`
                                                        : `${element.hole_size}`}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.label}>
                                                    Services via Hole:{' '}
                                                </Text>
                                                <Text style={styles.value}>
                                                    {element.services_via_hole}
                                                </Text>
                                            </View>
                                        </>
                                    ) : (
                                        <>
                                            <View style={styles.row}>
                                                <Text style={styles.label}>
                                                    Crack Size:{' '}
                                                </Text>
                                                <Text style={styles.lowerValue}>
                                                    {element.crack_width}mm x{' '}
                                                    {element.crack_length}m x{' '}
                                                    {element.crack_depth}mm
                                                </Text>
                                            </View>
                                        </>
                                    )}
                                </View>

                                <View
                                    style={[
                                        styles.outerRow,
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginTop: 24,
                                        },
                                    ]}
                                >
                                    <View>
                                        {element.photograph_1 ? (
                                            <ElementPhotographThumbnail
                                                photo={element.photograph_1.id}
                                                collection='element-photos'
                                                size={{
                                                    width: 100,
                                                    height: 100,
                                                }}
                                            />
                                        ) : null}
                                        {element.photograph_2 ? (
                                            <ElementPhotographThumbnail
                                                photo={element.photograph_2.id}
                                                collection='element-photos'
                                                size={{
                                                    width: 100,
                                                    height: 100,
                                                }}
                                            />
                                        ) : null}
                                    </View>

                                    <View style={styles.greyContainer}>
                                        <View
                                            style={[
                                                styles.row,
                                                { width: '100%' },
                                            ]}
                                        >
                                            <Text style={styles.label}>
                                                Remedial Work Costs:
                                            </Text>
                                        </View>
                                        {element.remedial_work_costs
                                            .filter(
                                                (item) => item.deleted === 0
                                            )
                                            .map((item) => {
                                                let cost = costs.find(
                                                    (c) => c.id === item.cost_id
                                                );
                                                const calculationSymbol =
                                                    cost?.calculation === 2
                                                        ? '@'
                                                        : 'x';
                                                const calculationSuffix =
                                                    cost?.calculation === 2
                                                        ? ' per m²'
                                                        : null;

                                                const calculationTotal = Number(
                                                    calculateCost(
                                                        item.quantity,
                                                        item.cost_price,
                                                        item.option_price,
                                                        false
                                                    )
                                                ).toFixed(2);
                                                return (
                                                    <View
                                                        style={
                                                            styles.remedialWork
                                                        }
                                                        key={item.id}
                                                    >
                                                        <View>
                                                            <View
                                                                style={{
                                                                    marginRight: 4,
                                                                }}
                                                            >
                                                                <Text
                                                                    style={
                                                                        styles.label
                                                                    }
                                                                >
                                                                    {
                                                                        item.cost_title
                                                                    }
                                                                </Text>
                                                            </View>
                                                            <View>
                                                                {item.option_title ? (
                                                                    <View
                                                                        style={{
                                                                            flexDirection:
                                                                                'row',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.option_title
                                                                            }
                                                                        </Text>
                                                                        {item.calculation ===
                                                                        3 ? (
                                                                            <Text
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                }}
                                                                            >
                                                                                ml
                                                                            </Text>
                                                                        ) : null}
                                                                    </View>
                                                                ) : null}
                                                            </View>
                                                        </View>
                                                        <View>
                                                            <Text>
                                                                {item.quantity}{' '}
                                                                {
                                                                    calculationSymbol
                                                                }{' '}
                                                                {moneyFormatter.format(
                                                                    Number(
                                                                        item.cost_price +
                                                                            item.option_price
                                                                    )
                                                                )}
                                                                {
                                                                    calculationSuffix
                                                                }
                                                            </Text>
                                                        </View>
                                                        <View>
                                                            <Text
                                                                style={
                                                                    styles.label
                                                                }
                                                            >
                                                                {moneyFormatter.format(
                                                                    calculationTotal
                                                                )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                );
                                            })}
                                    </View>
                                </View>
                            </>
                        ) : null}
                        {!!element.coordinates && (
                            <View>
                                {!!floorplans[element.coordinates[3]] ? (
                                    <>
                                        <View style={styles.buttonContainer}>
                                            <Button
                                                title='View Floorplan'
                                                variant={
                                                    showFloorplan == element.id
                                                        ? 'secondary'
                                                        : 'black'
                                                }
                                                onPress={() =>
                                                    setShowFloorplan((state) =>
                                                        state == element.id
                                                            ? null
                                                            : element.id
                                                    )
                                                }
                                            />
                                        </View>
                                        {showFloorplan == element.id && (
                                            <PinMe
                                                image={
                                                    floorplans[
                                                        element.coordinates[3]
                                                    ].photograph_uri
                                                }
                                                imageDimensions={{
                                                    width: floorplans[
                                                        element.coordinates[3]
                                                    ].width,
                                                    height: floorplans[
                                                        element.coordinates[3]
                                                    ].height,
                                                }}
                                                pin={element.coordinates}
                                                centerOnPin={true}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <Text>
                                        Unable to find corresponding floorplan
                                    </Text>
                                )}
                            </View>
                        )}
                    </CollapsedItem>
                ))}
                <View style={{ flexDirection: 'row', marginTop: 20 }}>
                    <Button title='Back' onPress={handleBack} />
                </View>
            </ScrollView>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingTop: padding.xxl,
        paddingBottom: padding.xl,
    },
    outerRow: {
        flexDirection: 'row',
    },
    row: {
        flexDirection: 'row',
        width: '33.3%',
    },
    label: {
        fontWeight: 'bold',
    },
    value: {
        textTransform: 'capitalize',
    },
    lowerValue: {
        textTransform: 'none',
    },
    remedialWork: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    greyContainer: {
        backgroundColor: colors.darkGrey,
        paddingHorizontal: padding.lg,
        paddingVertical: padding.md,
        width: '50%',
    },
    elementsContainer: {
        width: '50%',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 16,
    },
    elementContainer: {
        paddingHorizontal: padding.sm,
        paddingVertical: padding.sm,
        marginRight: 16,
    },
    elementLabel: {
        color: colors.white,
    },
});

export default FireStopStatsScreen;
