import { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, ScrollView, TextInput, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import { colors, fonts, padding } from '../constants/theme';
import Layout from '../containers/Layout';
import { signIn } from '../features/auth/authSlice';

import PasswordReset from '../components/PasswordReset';
import { globalStyles } from '../styles/GlobalStyles';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const { status, error } = useSelector((state) => state.auth);

  const handleSignIn = () => {
    if (email && password) {
      setEmailError(null);
      setPasswordError(null);

      dispatch(signIn({ email, password }));
    } else {
      if (!email) {
        setEmailError('You must provide an email');
      }

      if (!password) {
        setPasswordError('You must provide a password');
      }
    }
  };

  const handlePasswordReset = () => {
		navigation.navigate('ResetPassword');
  }

  return (
    <Layout>
      <ScrollView style={styles.container}>
        <View style={styles.headingContainer}>
          <Text variant='heading' size='xl'>
            Fire Stop App
          </Text>
        </View>
        <View style={styles.formContainer}>
          <View style={globalStyles.fieldContainer}>
            <Text style={styles.label}>Email</Text>
            <TextInput
              style={[globalStyles.field, { maxWidth: 'none' }]}
              onChangeText={(value) => setEmail(value)}
              value={email}
            />
          </View>
          {emailError ? (
            <View style={styles.errorFieldContainer}>
              <Text style={globalStyles.error}>{emailError}</Text>
            </View>
          ) : null}
          <View style={globalStyles.fieldContainer}>
            <Text style={styles.label}>Password</Text>
            <TextInput
              style={[globalStyles.field, { maxWidth: 'none' }]}
              textContentType="password"
              onChangeText={(value) => setPassword(value)}
              value={password}
              secureTextEntry
            />
          </View>
          {passwordError ? (
            <View style={styles.errorFieldContainer}>
              <Text style={globalStyles.error}>{passwordError}</Text>
            </View>
          ) : null}
          <View style={globalStyles.fieldContainer}>
            <Text style={styles.label}></Text>
            <View>
              <Button title='Reset your password' variant='transparent' icon={<PasswordReset style={{ marginRight: 8 }} color={colors.primary} />} style={{ padding: '0px' }} textStyles={{ color: colors.primary, fontSize: 14, textTransform: 'normal' }} onPress={handlePasswordReset} />
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <Button
              title={status === 'loading' ? <ActivityIndicator color="#000000" /> : 'Sign In'}
              variant='secondary'
              onPress={handleSignIn}
            />
          </View>
          {error ? (
            <View style={styles.errorContainer}>
              <Text style={globalStyles.error}>{error}</Text>
            </View>
          ) : null}
        </View>
      </ScrollView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  label: {
    fontSize: fonts.md,
    fontWeight: '600',
    minWidth: 180,
    paddingVertical: 10,
  },
  formContainer: {
    marginTop: 16
  },
  buttonContainer: {
    marginTop: 8,
  },
  errorContainer: {
    marginTop: 4,
    textAlign: 'center'
  },
  errorFieldContainer: {
    marginLeft: 200
  }
});

export default LoginScreen;
