import { useState, useEffect } from 'react';
import { ActivityIndicator, View, ScrollView, StyleSheet, TextInput } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome5 } from '@expo/vector-icons';

import Layout from '../containers/Layout';
import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import { colors, padding } from '../constants/theme';
import { globalStyles } from '../styles/GlobalStyles';
import { processSurvey, postSurvey, postSurveyEntry, getSelectedSurvey, getSelectedSurveyEntry, clearSelectedSurvey, selectSurveysEntriesElements, organisationSurveyHeading, postRepair, selectEntryTotalCost, getCurrentSurvey } from '../features/firestop/firestopSlice';
import Exclamation from '../components/Exclamation';

const FireStopSummaryScreen = () => {
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const elements = useSelector(selectSurveysEntriesElements);
	const currentSurvey = useSelector(getSelectedSurvey);
	const survey = useSelector(getCurrentSurvey);
	const currentEntry = useSelector(getSelectedSurveyEntry);
	const pageTitle = useSelector(organisationSurveyHeading);

	const [processText, setProcessText] = useState("Process Survey");

	// gets total from the element
	const costTotal = useSelector((state) => selectEntryTotalCost(state, currentEntry));

	const [adjustment, setAdjustment] = useState(0);
	const [total, setTotal] = useState(0.0);

	const totalElements = elements.length;
	const passedElements = elements.reduce(
		(acc, current) => (current.status === 'passed' ? acc + 1 : acc),
		0
	);
	const failedElements = elements.reduce(
		(acc, current) => (current.status === 'failed' ? acc + 1 : acc),
		0
	);

	function finishProcess()
	{
		dispatch(clearSelectedSurvey());
		// This should remove all other screens
		navigation.reset({
			index: 0,
			routes: [{ name: 'FireStopDashboard' }],
		});
	}

	const handleProcess = () => {
		setProcessText(<ActivityIndicator color="#000000" />);
		dispatch(processSurvey({adjustment}));
		dispatch(postSurvey(currentSurvey)).then((state) => {
			if(!state.payload || state.payload.data.status != "ok")
			{
				setProcessText("Process Survey");

				finishProcess();
			}
			else
			{
				dispatch(postSurveyEntry(currentEntry)).then((state) => {
					setProcessText("Process Survey");
					if(!state.payload || state.payload.data.status != "ok")
					{
						finishProcess();
					}
					else
					{
						dispatch(postRepair());
						finishProcess();
					}
				});
			}
		});
	};

	useEffect(() => {
		setTotal((parseFloat(costTotal) * ((100 + parseFloat(adjustment || 0)) /100)).toFixed(2));
	}, [adjustment]);

	if (!elements) {
		return (
			<View>
				<Text>No breaches</Text>
			</View>
		);
	}

	return (
		<Layout title={pageTitle}>
			<ScrollView style={styles.container}>
				<View style={{ flexDirection: 'row', justifyContent: 'center' }}>{
					(failedElements > 0 ? 
						<Exclamation />
					:
						<FontAwesome5 name='check' size={160} color={colors.success} />
				)}
				</View>
				{failedElements > 0 && 
					<View style={styles.intro}>
						<Text>There are breaches that require attention. A summary can be found below.</Text>
					</View>
				}
				{survey?.surveyPassFailType === 0 && totalElements === 0 ? (
					<View style={styles.intro}>
						<Text>This survey has been completed with no failed breaches</Text>
					</View>
				) : null}
				<View style={styles.elementRow}>
					<Text style={{marginRight: 30}} variant='heading' size='xl'>Breaches</Text>
					<Text style={{...styles.elementTotal, backgroundColor: '#333333'}}>Total: {totalElements}</Text>
					{survey?.surveyPassFailType === 1 ? (
						<>
							<Text style={{...styles.elementTotal, backgroundColor: colors.success}}>Passed: {passedElements}</Text>
							<Text style={{...styles.elementTotal, backgroundColor: colors.error}}>Failed: {failedElements}</Text>
						</>
					) : null}
				</View>
				<View>
					<View style={styles.row}>
						<Text>Cost:</Text>
						<Text>£{costTotal}</Text>
					</View>
					<View style={[styles.row, styles.adjustment]}>
						<Text>Adjustment:</Text>
						<Text>
							<TextInput style={globalStyles.field} onChangeText={(value) => setAdjustment(value)} value={adjustment} />%
						</Text>
					</View>
					<View style={styles.row}>
						<Text>Total Cost:</Text>
						<Text>£{total}</Text>
					</View>
				</View>
				<View style={styles.buttonContainer}>
					<Button title={processText} variant='secondary' disabled={(typeof processText == "string" ? false : true)} onPress={handleProcess} />
					<Button onPress={() => navigation.goBack()} title='Cancel' variant='black' />
				</View>
			</ScrollView>
		</Layout>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingTop: padding.xxl,
		paddingBottom: padding.xl,
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: 50,
		gap: 10
	},
	intro: {
		textAlign: 'center',
		marginTop: 20,
	},
	row: {
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		marginTop: 20,
	},
	adjustment: {
		borderBottomWidth: 1,
		borderBottomColor: colors.black,
		paddingBottom: 20,
	},
	elementRow: {
		flex: 1,
		flexDirection: 'row',
		marginTop: 10,
	},
	elementTotal: {
		marginLeft: 20,
		paddingHorizontal: 15,
		paddingVertical: 10,
		color: '#fff'
	}
});

export default FireStopSummaryScreen;
