import { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, TextInput, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import { fonts, padding } from '../constants/theme';
import Layout from '../containers/Layout';
import { resetPassword, reset } from '../features/auth/authSlice';

import { globalStyles } from '../styles/GlobalStyles';

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const [email, setEmail] = useState('');

  const { status, error, success } = useSelector((state) => state.auth);

  const handleResetPassword = () => {
    dispatch(resetPassword({ email }));
  };

  useEffect(() => {
    if (!isFocused) {
      dispatch(reset());
      setEmail('');
    }
  }, [isFocused]);

  const renderedContent = success ? (
    <>
      <View style={{ marginTop: 16, textAlign: 'center' }}>
        <Text>Your password has been reset. You will shortly receive an email containing a new password.</Text>
      </View>
      <View style={{ marginTop: 8, textAlign: 'center' }}>
        <Text>Once you've received this you may update your password by logging into your account at www.napfis.co.uk</Text>
      </View>
    </>
  ) : (
    <>
      <View style={styles.formContainer}>
        <View style={globalStyles.fieldContainer}>
          <Text style={styles.label}>Email</Text>
          <TextInput
            style={[globalStyles.field, { maxWidth: 'none' }]}
            onChangeText={(value) => setEmail(value)}
            value={email}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            title={status === 'loading' ? <ActivityIndicator color="#000000" /> : 'Reset Password'}
            variant='secondary'
            onPress={handleResetPassword}
          />
        </View>
        {error ? (
          <View style={styles.errorContainer}>
            <Text style={globalStyles.error}>{error}</Text>
          </View>
        ) : null}
      </View>
    </>
  );

  return (
    <Layout title="Reset Password">
      <ScrollView style={styles.container}>
        <View style={styles.headingContainer}>
          <Text variant='heading' size='xl'>
            Reset Password
          </Text>
        </View>
        {renderedContent}
      </ScrollView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  label: {
    fontSize: fonts.md,
    fontWeight: '600',
    minWidth: 180,
    paddingVertical: 10,
  },
  formContainer: {
    marginTop: 16
  },
  buttonContainer: {
    marginTop: 24,
  },
  errorContainer: {
    marginTop: 4
  }
});

export default ResetPasswordScreen;
