import { useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { FontAwesome } from '@expo/vector-icons';

import { default as Text } from '../../components/AppText';
import { colors, padding } from '../../constants/theme';

const CollapsibleItem = ({ title, children }) => {
  const [isCollapsible, setIsCollapsible] = useState(true);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => setIsCollapsible((prev) => !prev)}>
        <View style={styles.header}>
          <Text style={styles.title}>{title}</Text>
          {isCollapsible ? (
            <FontAwesome name='chevron-down' size={24} color='black' />
          ) : (
            <FontAwesome name='chevron-up' size={24} color='black' />
          )}
        </View>
      </TouchableOpacity>
      <Collapsible collapsed={isCollapsible} style={styles.body}>
        <View style={styles.innerBody}>{children}</View>
      </Collapsible>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingHorizontal: padding.md,
    paddingVertical: padding.sm,
  },
  title: {
    color: colors.black,
    fontWeight: 'bold'
  },
  body: {
    backgroundColor: colors.white,
    paddingHorizontal: padding.md,
  },
  innerBody: {
    maxWidth: 800,
    borderTopColor: colors.darkGrey,
    borderTopWidth: 2,
    paddingVertical: padding.md,
  }
});

export default CollapsibleItem;
