import Svg, { Path } from 'react-native-svg';

const Camera = (props) => (
  <Svg
    height={35}
    width={35}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1200 1200'
    fill='#333333'
    {...props}
  >
    <Path d="M600 387.8c-46.645 0-91.789 13.523-130.57 39.121-11.062 7.297-14.113 22.188-6.805 33.254 7.285 11.074 22.211 14.113 33.254 6.816 30.91-20.398 66.91-31.188 104.14-31.188 104.4 0 189.34 84.938 189.34 189.34 0 35.977-10.117 70.97-29.258 101.18-7.093 11.195-3.757 26.027 7.43 33.121a23.918 23.918 0 0 0 12.816 3.73c7.945 0 15.73-3.948 20.293-11.16 24.023-37.921 36.719-81.78 36.719-126.86-.016-130.89-106.48-237.35-237.35-237.35z"/><path d="M716.48 774.43c-33.539 26.207-73.812 40.055-116.48 40.055-104.4 0-189.34-84.938-189.34-189.34 0-39.422 11.988-77.207 34.691-109.26 7.656-10.812 5.09-25.789-5.734-33.457-10.812-7.668-25.801-5.09-33.457 5.723-28.453 40.21-43.5 87.574-43.5 136.99 0 130.87 106.46 237.34 237.34 237.34 53.484 0 103.98-17.375 146.04-50.242 10.44-8.16 12.289-23.242 4.14-33.684-8.16-10.45-23.28-12.285-33.695-4.125z"/><path d="M1002.9 301.18H854.31c-9.371 0-18.07-4.234-23.855-11.617l-39.973-51.047c-18.07-23.074-45.254-36.312-74.578-36.312h-231.77c-29.316 0-56.508 13.234-74.578 36.312l-39.984 51.035c-5.773 7.379-14.473 11.617-23.844 11.617h-148.6c-75.602.012-137.11 61.523-137.11 137.12v422.54c0 75.516 61.43 136.96 136.95 136.96h805.95c75.602 0 137.11-61.441 137.11-136.96l-.004-422.54c0-75.602-61.512-137.11-137.11-137.11zm89.113 559.67c0 49.043-39.973 88.957-89.113 88.957H196.96c-49.047-.012-88.945-39.914-88.945-88.957V438.3c0-49.141 39.973-89.113 89.113-89.113h148.6c24.254 0 46.715-10.945 61.645-30.023l39.973-51.035c8.914-11.387 22.32-17.914 36.793-17.914h231.77c14.473 0 27.875 6.527 36.793 17.902l39.961 51.035c14.941 19.078 37.402 30.023 61.656 30.023h148.6c49.141 0 89.113 39.984 89.113 89.113z"/>
  </Svg>
);
export default Camera;

