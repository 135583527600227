import { useState, useContext } from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import SortList from '../components/SortList';
import { Information } from '../components/Icons';
import Layout from '../containers/Layout';
import AddOptionForm from '../features/costs/AddOptionForm';
import { getSelectedCost, shiftOption } from '../features/costs/costsSlice';
import { colors, padding } from '../constants/theme';

import OptionsContext from '../features/costs/OptionsContext';
import OptionsItem from '../features/costs/OptionsItem';

const OptionsList = ({ options }) => {
	const dispatch = useDispatch();
	const selectedCost = useSelector(getSelectedCost);

	const { canSort } = useContext(OptionsContext);

	if(options === undefined)
	{
		return <View><Text>Cannot find Options</Text></View>
	}

	if(options?.length <= 0)
	{
		return <View><Text>No Options found</Text></View>
	}
	const filteredOptions = options.filter((option) => option.deleted !== 1);

	return (
		<View>
			{(filteredOptions !== undefined && Object.keys(filteredOptions).length > 0 ?
				(canSort ? <SortList defaultText={<><Information colour='#2196F3' /> Please select an item to sort</>} highlight={colors.darkGrey} selectedHighlight={colors.darkGrey} onSort={(from, to) => dispatch(shiftOption({from, to}))}>
					{
						filteredOptions.map((option) => <Option key={option.id} option={option} selectedCost={selectedCost} />)
					}</SortList>
				:
					<View>{
						filteredOptions.map((option) => <Option key={option.id} option={option} selectedCost={selectedCost} />)
					}</View>
				)
			: 
				<Text>No Options</Text>
			)}
		</View>
	);
};

const Option = ({ option, selectedCost, ...props }) => {
	const [selectedOption, setSelectedOption] = useState(null);

	return (
		<View style={[ styles.optionContainer, { ...props.style }]}>
			<OptionsItem key={option.id} option={option} selectedOption={selectedOption} setSelectedOption={setSelectedOption} selectedCost={selectedCost} />
		</View>
	);
};


function CostsOptionsScreen() {
	const navigation = useNavigation();

	const [showAddOptionsPanel, setShowAddOptionsPanel] = useState(false);
	const selectedCost = useSelector(getSelectedCost);

	const { showSortButton, setShowSortButton, canSort, setCanSort } = useContext(OptionsContext);

	const handleAddNewOption = () => {
		// This will toggle the sort button based on the add option form rather than switching the state.
		setCanSort(false); 
		setShowSortButton(showAddOptionsPanel);
		setShowAddOptionsPanel(!showAddOptionsPanel);
	};

	return (
		<Layout title="Fire Stopping Costs">
			<ScrollView showsVerticalScrollIndicator={false}>
				<View style={styles.container}>
					<View style={styles.row}>
						<Text size='xl' variant='heading'>Please Specify Fire Stopping Costs</Text>
						<Button title='Add Option' variant='secondary' onPress={handleAddNewOption} />
					</View>
					<View style={[styles.row, { marginTop: 10 }]}>
						<Text style={{ fontSize: 18, fontWeight: 'bold' }}>{selectedCost?.title}</Text>
						{showSortButton &&
							<Button title='Sort' variant={(canSort ? 'orange' : 'black')} onPress={() => setCanSort((prevState) => !prevState)} />
						}
					</View>
					{showAddOptionsPanel &&
						<AddOptionForm setShowAddOptionsPanel={setShowAddOptionsPanel} selectedCost={selectedCost} />
					}
					<OptionsList options={selectedCost?.options} />
					<View style={[styles.row, { justifyContent: 'flex-start', marginTop: 40 }]}>
						<Button title='Finish' variant='secondary' onPress={() => { setCanSort(false); navigation.navigate('Costs')}}/>
					</View>
				</View>
			</ScrollView>
		</Layout>
	);
}

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
	optionContainer: {
		backgroundColor: colors.white,
		paddingHorizontal: 20,
    paddingVertical: 10,
    marginVertical: 10,
	}
});

export default CostsOptionsScreen;
