import Svg, { Path } from 'react-native-svg';

import { colors } from '../constants/theme';

const Preview = (props) => (
  <Svg
    height={30}
    width={30}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 30 30'
    fill={colors.black}
    {...props}
  >
    <Path d="M1.875 8.125c.164062 0 .324219-.066406.441406-.183594C2.433594 7.824219 2.5 7.664062 2.5 7.5V4.375h3.125c.222656 0 .429688-.121094.542969-.3125.109375-.195312.109375-.433594 0-.625-.113281-.195312-.320313-.3125-.542969-.3125h-3.75c-.164062 0-.324219.066406-.441406.183594C1.316406 3.425781 1.25 3.585938 1.25 3.75V7.5c0 .164062.066406.324219.183594.441406.117187.117188.277344.183594.441406.183594ZM28.125 3.125h-3.75c-.222656 0-.429688.117188-.542969.3125-.109375.195312-.109375.433594 0 .625.113281.195312.320313.3125.542969.3125H27.5V7.5c0 .222656.117188.429688.3125.542969.195312.109375.433594.109375.625 0 .195312-.113281.3125-.320313.3125-.542969V3.75c0-.164062-.066406-.324219-.183594-.441406-.117187-.117188-.277344-.183594-.441406-.183594ZM5.625 25.625H2.5V22.5c0-.222656-.117188-.429688-.3125-.542969-.195312-.109375-.433594-.109375-.625 0-.195312.113281-.3125.320313-.3125.542969v3.75c0 .164062.066406.324219.183594.441406.117187.117188.277344.183594.441406.183594h3.75c.222656 0 .429688-.117188.542969-.3125.109375-.191406.109375-.433594 0-.625-.113281-.191406-.320313-.3125-.542969-.3125ZM28.125 21.875c-.164062 0-.324219.066406-.441406.183594-.117188.117187-.183594.277344-.183594.441406v3.125h-3.125c-.222656 0-.429688.121094-.542969.3125-.109375.195312-.109375.433594 0 .625.113281.195312.320313.3125.542969.3125h3.75c.164062 0 .324219-.066406.441406-.183594.117188-.117187.183594-.277344.183594-.441406V22.5c0-.164062-.066406-.324219-.183594-.441406-.117187-.117188-.277344-.183594-.441406-.183594ZM19.375 15c0 2.417969-1.957031 4.375-4.375 4.375-2.417969 0-4.375-1.957031-4.375-4.375 0-2.417969 1.957031-4.375 4.375-4.375 2.417969 0 4.375 1.957031 4.375 4.375"/>
    <Path d="M27.445312 15.261719c.078126-.164063.078126-.359375 0-.523438C27.277344 14.375 23.21875 5.894531 15 5.894531S2.726562 14.375 2.558594 14.738281c-.078125.164063-.078125.359375 0 .523438C2.726562 15.625 6.78125 24.105469 15 24.105469s12.277344-8.480469 12.445312-8.84375ZM15 20.625c-1.492188 0-2.921875-.59375-3.976562-1.648438C9.96875 17.921875 9.375 16.492188 9.375 15s.59375-2.921875 1.648438-3.976562C12.078125 9.96875 13.507812 9.375 15 9.375s2.921875.59375 3.980469 1.648438C20.035156 12.078125 20.625 13.507812 20.625 15s-.589844 2.921875-1.644531 3.976562C17.925781 20.03125 16.492188 20.625 15 20.625Zm0 0"/>
  </Svg>
);
export default Preview;
