export function findValueInColumn(needle, haystack, key)
{
	// Null is an object
	if(typeof haystack === "object" && haystack != null)
	{
		if(Array.isArray(haystack))
		{
			for(let i = 0; i<haystack.length; i++)
			{
				if(haystack[i][key] == needle)
				{
					return i;
				}
			}
		}
		else
		{
			for(let array of Object.entries(haystack))
			{
				if(array[key] == needle)
				{
					return array[0];
				}
			}
		}

		return -1;
	}

	return false;
}

export function checkIfNew(id)
{
    return (/NEW_/i.test(id));
}