import { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import Button from '../../components/Button';
import { colors } from '../../constants/theme';
import { costUpdated, deleteCost } from './costsSlice';
import EditCostForm from './EditCostForm';
import Trash from '../../components/Trash';
import OptionsContext from './OptionsContext';
import Modal from '../../components/Modal';

const CostItem = ({ item }) => {
  const { title, price, options, calculation } = item;
  const label =
    options.filter((option) => option.deleted === 0).length > 0 ? 'Various Sizes' : `£${Number(price).toFixed(2)}`;

  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { selectedCostItem, setSelectedCostItem, setShowAddCostsPanel } =
    useContext(OptionsContext);

  const showEdit = selectedCostItem === item.id;

  const suffix =
    calculation !== 1
      ? calculation === 2
        ? 'per m²'
        : 'per ml'
    : null;

  const handleEdit = () => {
    setSelectedCostItem(item.id);
    setShowAddCostsPanel(false);
    dispatch(costUpdated(item));
  };

  const handleDeleteItem = () => {
    setConfirmDelete(true);
  }

  const handleConfirmDelete = () => {
    dispatch(deleteCost(item));
    setConfirmDelete(false);
  }

  const handleCancelDelete = () => {
    setConfirmDelete(false);
  }

  return (
    <>
      <View style={styles.row}>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>
        <View style={styles.price}>
          <Text>{label} {label.includes('£') ? suffix : null}</Text>
        </View>
        <View style={styles.delete}>
          <Button
            icon={<Trash />}
            variant='transparent'
            size='sm'
            onPress={handleDeleteItem}
          />
        </View>
        <View style={styles.edit}>
          <Button
            title='Edit'
            variant='secondary'
            size='sm'
            onPress={handleEdit}
            disabled={showEdit}
            style={{ opacity: showEdit ? 0.6 : 1 }}
          />
        </View>
      </View>
      {showEdit ? (
        <EditCostForm item={item} setSelectedCostItem={setSelectedCostItem} />
      ) : null}
      <Modal type='confirmation' showModal={confirmDelete} onConfirmation={handleConfirmDelete} onCancel={handleCancelDelete}>
        <Text>Are you sure you want to delete?</Text>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginVertical: 5,
  },
  title: {
    width: '50%',
  },
  price: {
    width: '25%',
  },
  delete: {
    width: '15%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  edit: {
    width: '10%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default CostItem;
