import axios from 'axios';

export const FireStopAPI = axios.create({
	baseURL: 'https://firestop.webglu35.co.uk/api/',
});

export function APIError(error)
{
	let Array_Response = { "data": { "status": "error", "message": error.message }, "status": 500, statusText: error.message, headers: {}, "config": error.config, "request": error.request };

	if(error.response)
	{
		Array_Response = error.response;
	}

	return Array_Response;
};

export const setHeaderToken = (token) =>
{
	let userToken = '';

	if (token) {
		userToken = `Bearer ${token}`;
	}

	FireStopAPI.interceptors.request.use(function (config) {
		config.headers.Authorization = userToken;

		return config;
	})
};