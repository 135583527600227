export const isNumeric = (input) => {
  const numericPattern = /^[0-9]+(\.[0-9]+)?$/;

  return numericPattern.test(input) ? true : false;
}

export const sanitizeNumericString = (input) => {
  // Remove symbols and letters using regex
  var sanitizedString = input.replace(/[^0-9.]+/g, '');

  // Remove multiple decimals
  sanitizedString = sanitizedString.replace(/(\.[0-9]*\.)+/g, '.');

  return sanitizedString;
}

export const moneyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});