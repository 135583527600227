import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './features/auth/authSlice';
import costsReducer from './features/costs/costsSlice';
import firestopReducer from './features/firestop/firestopSlice';
import organisationsReducer from './features/organisations/organisationsSlice';
import syncReducer from './features/sync/syncSlice';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['costs', 'firestop', 'organisations', 'sync'],
  // whitelist: [],
};

const rootReducer = combineReducers({
  auth: authReducer,
  costs: costsReducer,
  firestop: firestopReducer,
  organisations: organisationsReducer,
  sync: syncReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      /*serializableCheck: {
		ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},*/
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
