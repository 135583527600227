import { useState } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import Button from './Button';

import { default as Text } from './AppText';
import { colors } from '../constants/theme';
import { selectUser } from '../features/auth/authSlice';
import { selectSyncItems, selectTotalItemsToSync } from '../features/sync/syncSlice';
import SyncPopup from '../features/sync/SyncPopup';

function Header({ title }) {
  const user = useSelector(selectUser);
  const hasItemsToSync = useSelector(selectSyncItems);
  const totalItemsToSync = useSelector(selectTotalItemsToSync);
  const [showSyncPopup, setShowSyncPopup] = useState();

  let message;
  if (user) {
    message = `Hello ${user.first_name} ${user.last_name}`;
  }

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          style={styles.image}
          resizeMode='contain'
          source={require('../assets/napfis-logo.png')}
        />
      </View>
      <View style={styles.top}>
        {hasItemsToSync ? (
          <>
            <Button 
              title="Sync"
              size="sm"
              onPress={() => setShowSyncPopup((prev) => !prev)}
            />
            <View style={styles.badge}>
              <Text style={styles.badgeText}>{totalItemsToSync}</Text>
            </View>
          </>
        ) : null}
      </View>
      <View style={styles.bottom}>
        <Text style={styles.title}>{title ? title : message}</Text>
      </View>
      {showSyncPopup ? (
        <SyncPopup showSyncPopup={showSyncPopup} setShowSyncPopup={setShowSyncPopup} />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  imageContainer: {
    position: 'absolute',
    left: 16,
    top: 4,
    zIndex: 100,
  },
  image: {
    height: 100,
    width: 100,
  },
  badge: {
    backgroundColor: colors.secondary,
    minWidth: 18,
    height: 18,
    borderRadius: 100,
    position: 'relative',
    top: -12,
    left: -8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 4,
    paddingVertical: 4
  },
  badgeText: {
    color: colors.white,
    fontSize: 12,
    fontWeight: 600
  },
  top: {
    backgroundColor: colors.primary,
    height: 54,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 32,
  },
  bottom: {
    backgroundColor: colors.secondary,
    height: 50,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 32,
  },
  title: {
    color: colors.white,
    fontWeight: '600',
    fontSize: 18,
    letterSpacing: 1
  }
});

export default Header;
