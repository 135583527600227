import { useState } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { FireStopAPI, APIError } from '../services/api';
import * as DocumentPicker from 'expo-document-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import Localbase from 'localbase';

import Button from './Button';

import { default as Text } from './AppText';
import FloorplanPreview from '../features/firestop/FloorplanPreview';

const db = new Localbase('firestop');

const ImageUpload = ({ name, collection, files, setFiles, setArray }) => {
	const [status, setStatus] = useState('idle');
	const [error, setError] = useState(null);

	const handleUpload = async () => {
		try
		{
			setError(null);
			const result = await DocumentPicker.getDocumentAsync({
				type: 'image/*,application/pdf'
			});

			if (result.canceled)
			{
				throw new Error("Cancelled");
			}

			let String_Type = "";

			if(result.mimeType == 'application/pdf')
			{
				String_Type = "document";
			}
			else if(result.mimeType.split("/")[0] =='image')
			{
				String_Type = "image";
			}

			if(String_Type == "")
			{
				throw new Error("Incompatible File Type");
			}

			if (status === 'idle')
			{
				setStatus('loading');

				let images = [];

				if(String_Type == "document")
				{
					try {
						const response = await FireStopAPI.post('/v1/document-upload.php', {
							data: result.uri,
							action: "pdf_upload"
						});

						images = response.data?.images;
					} catch (error) {
						setStatus('idle');
						return APIError(error);
					}
				}
				else
				{
					images = [{'uri': result.uri}];

					let img = new Image();
					img.onload = function()
					{
						images[0]['width'] = this.width;
						images[0]['height'] = this.height;
					}

					img.src = result.uri;
				}

				if (images.length > 0)
				{
					let updatedFiles = {...files};

					for(let i = 0; i < images.length; i++)
					{
						let resize = {};

						if (images[i].width >= images[i].height)
						{
							resize = { width: 1000 };
						}
						else
						{
							resize = { height: 1000 };
						}

						const compressedPhoto = await manipulateAsync(images[i].uri, [{ resize }], {
							compress: 0.8,
							format: SaveFormat.JPEG,
						});

						await db.collection(collection)
						.add({
							photograph_type: name,
							photograph_uri: compressedPhoto.uri,
							width: compressedPhoto.width,
							height: compressedPhoto.height
						})
						.then((res) => {
							const newImage = { id: "NEW", db_id: res.data.key, photograph_type: name, deleted: 0 };

							if(setArray)
							{
								let newID = "NEW_" + (Object.values(updatedFiles).length + 1);
								updatedFiles[newID] = {...newImage, id: newID, file_name: "Image " + (Object.values(updatedFiles).length + 1) };
							}
							else
							{
								updatedFiles = newImage;
							}
						});

						setFiles(updatedFiles);
					}
				}

				setStatus('idle');
			}
		}
		catch(e)
		{
			setStatus('idle');
			setError(e.message);
		}
	};

	const activeFiles = Object.values(files).filter((image) => !image.deleted);

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<Button title={status === 'idle' ? 'Choose file' : <ActivityIndicator color='#FFFFFF' />} variant='secondary' size='sm' onPress={handleUpload} disabled={status !== 'idle'} />
				{(error && <Text style={{ marginTop: 10, color: '#CD2121' }}>{error}</Text>)}
				<Text>{ activeFiles.length > 0 ? activeFiles.length : "No" } File(s) Uploaded</Text>
			</View>
			{(activeFiles.length > 0 ?
				<View>
					{activeFiles.map((image) => (
						<FloorplanPreview key={image.id} photographId={image.id} image={image} files={files} setFiles={setFiles} setArray={setArray} />
					))}
				</View>
			: null)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	header: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		maxWidth: 400,
	},
});

export default ImageUpload;